import React from "react";
import { Card, CardBody, Collapse, Input, FormGroup, Label, Row, Col } from "reactstrap";

const FilterSection = ({ isOpenFilter, setIsOpenFilter, filters, Columns, handleFilterChange }) => {
  return (
    <Card>
      <CardBody>
        <div className="accordion accordion-flush" id="accordionFlushExample">
          <div className="accordion-item">
            <Collapse isOpen={isOpenFilter} className="accordion-collapse">
              <div className="accordion-body text-muted">
                <Row>
                  {Object.keys(filters).map((key, index) => (
                    <Col md={4} key={index}>
                      <FormGroup>
                        <Label for={key}>{Columns.find(col => col.field === key)?.name}</Label>
                        <Input
                          type="text"
                          name={key}
                          id={key}
                          value={filters[key]}
                          onChange={handleFilterChange}
                        />
                      </FormGroup>
                    </Col>
                  ))}
                </Row>
              </div>
            </Collapse>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default FilterSection;