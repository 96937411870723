import React, { useState,useEffect } from "react";
import { Row, Col, Label, Table, FormFeedback, Form, Input, FormGroup, Button } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { AddUpdateOrganization } from "store/actions";
import { useParams, useNavigate,useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axios from 'axios';
const apiAppUrl = process.env.REACT_APP_API_URL;

const Notes = ({ previous, next, activeTab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [entries, setEntries] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const organization = useSelector((state) => state.CompnayAPI?.data) || [];
  const location = useLocation();
  const isViewMode = location.pathname.includes("/View"); 
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      NoteId:'',
      NoteTitle: '',
      Note: '',
    },
    validationSchema: Yup.object({
      NoteTitle: Yup.string().required("Please enter Note Title"),
      Note: Yup.string().required("Please enter Note"),
    }),
    onSubmit: (values) => {
      if (editIndex !== null) {
        const updatedEntries = [...entries];
        updatedEntries[editIndex] = { ...values, CreatedDate: new Date(), CreatedBy: 'Current User' };
        setEntries(updatedEntries);
        setEditIndex(null);
      } else {
        setEntries([...entries, { ...values, CreatedDate: new Date(), CreatedBy: 'Current User' }]);
      }
      validation.resetForm();
    },
  });

  const { Id } = useParams();
  var company = [];

  useEffect(() => {
    if (activeTab == 6) {
      const payloadTab = {
        "TabName": "Notes",
        "OrganizationId": Id
      }

      axios.post(`${apiAppUrl}/api/master/GetOrgnaizationById`, payloadTab)
        .then(response => {
          if (response.status === 200) {
            console.log('Data Retrieved Successfully:', response.data);
            company = []
            company = response.data.data
            if (company) {
              console.log("comppppIdentifier", company)
              setEntries(company)
            }
          }
        })
        .catch(error => {
          console.error('Error Setting Up Request:', error);
        });
    }
  }, [Id, activeTab])

  const handleEdit = (index) => {
    const entryToEdit = entries[index];
    setEditIndex(index);
    validation.setValues(entryToEdit);
  };

  const handleDelete = (index) => {
    setEntries(entries.filter((_, i) => i !== index));
  };

  const handleFinalSubmit = () => {
    const OrganizationId = Id ? Id : organization?.data[0]?.OrganizationId
    const finalPayload = {
      "NotesInfo":{
      Notes: entries.map((item) => ({
        NoteId: item.NoteId,
        CreatedDate: item.CreatedDate,
        CreatedBy: item.CreatedBy,
        NoteTitle: item.NoteTitle,
        Note: item.Note,
        OrganizationId: OrganizationId
      })),
    }
    };

    dispatch(AddUpdateOrganization(finalPayload));

    if (OrganizationId) {
      toast.success('Organization updated successfully!');
    } else {
      toast.success('Organization added successfully!');
    }
    
    setTimeout(() => {
      navigate(`/Company`)
    }, 500);
  };

  return (
    <React.Fragment>
       {!isViewMode && ( 
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <Row className="justify-content-center">
          <Col lg={6} className="mb-3">
            <FormGroup>
              <Label htmlFor="NoteTitle" className="col-form-label">
                Note Title<span className="text-danger">*</span>
              </Label>
              <Input
                id="NoteTitle"
                name="NoteTitle"
                type="text"
                placeholder="Enter Note Title"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.NoteTitle}
                invalid={validation.touched.NoteTitle && !!validation.errors.NoteTitle}
              />
              {validation.touched.NoteTitle && validation.errors.NoteTitle && (
                <FormFeedback>{validation.errors.NoteTitle}</FormFeedback>
              )}
            </FormGroup>
          </Col>

          <Col lg={6} className="mb-3">
            <FormGroup>
              <Label htmlFor="Note" className="col-form-label">
                Note<span className="text-danger">*</span>
              </Label>
              <Input
                id="Note"
                name="Note"
                type="textarea"
                placeholder="Enter Note"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.Note}
                invalid={validation.touched.Note && !!validation.errors.Note}
              />
              {validation.touched.Note && validation.errors.Note && (
                <FormFeedback>{validation.errors.Note}</FormFeedback>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg={12} className="d-flex justify-content-end mb-2">
            <button type="submit" className="btn btn-primary">
              {editIndex !== null ? "Update Note" : "Add Note"}
            </button>
          </Col>
        </Row>
      </Form>
       )}
      {entries.length > 0 && (
        <Row className="justify-content-center">
          <Col>
            <Label className="col-form-label">Notes Details</Label>
            <Table bordered className="mt-3">
              <thead>
                <tr>
                  {/* <th>Created Date</th>
                  <th>Created By</th> */}
                  <th>Note Title</th>
                  <th>Note</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {entries.map((entry, index) => (
                  <tr key={index}>
                    {/* <td>{entry.CreatedDate.toLocaleDateString()}</td>
                    <td>{entry.CreatedBy}</td> */}
                    <td>{entry.NoteTitle}</td>
                    <td>{entry.Note}</td>
                    <td className="d-flex justify-content-between">
                      <Button className="mr-2" onClick={() => handleEdit(index)}>
                        Edit
                      </Button>
                      <Button color="danger" onClick={() => handleDelete(index)}>
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
 {!isViewMode && ( 
      <Row className="justify-content-between mt-4">
        <Col className="d-flex justify-content-start">
          <button type="button" className="btn btn-secondary large-button" onClick={previous}>
            Previous
          </button>
        </Col>
        <Col className="d-flex justify-content-end">
          <button type="button" className="btn btn-primary large-button" onClick={handleFinalSubmit}>
            Submit
          </button>
        </Col>
      </Row>
 )}
    </React.Fragment>
  );
};

export default Notes;
