import React, { useEffect,  useState,useMemo  } from "react";
import { Row, Col, Label, FormFeedback, Form, Input } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import { connect, useDispatch, useSelector } from "react-redux";
import { GetCompanyById } from "../../store/Company/actions";
import { useParams, useNavigate } from "react-router-dom";
import { setBreadcrumbItems } from "../../store/actions";
import { AddUpdateCompany } from "store/Company/actions";
import { fetchStates,fetchCities } from "store/Common/actions";

const EditCompany = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Id } = useParams();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  
  const stateList = useSelector((state) => state.CommonAPI?.states) || [];
  const citiesList = useSelector((state) => state.CommonAPI?.cities) || [];
  const companyData = useSelector((state) => state.CompnayAPI?.companyDetail) || {};

  // Memoize breadcrumbItems
  const breadcrumbItems = useMemo(() => [
    { title: "Comfort Zone" },
    { title: "Company" },
    { title: "Edit Company" },
  ], []);

  useEffect(() => {
    props.setBreadcrumbItems('Edit Company', breadcrumbItems);
    if (Id) {
      dispatch(GetCompanyById(Id));
      dispatch(fetchStates('IN'));
    }
  }, [Id, dispatch, breadcrumbItems, props]);

  useEffect(() => {
    if (selectedState) {
      dispatch(fetchCities(selectedState.value));
    }
  }, [selectedState]);


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      CompanyName: companyData.CompanyName || "",
      LandlinePhone: companyData.LandlinePhone || "",
      MobilePhone: companyData.PhoneNumber || "",
      Email: companyData.Email || "",
      Website: companyData.Website || "",
      PAN: companyData.PAN || "",
      GST: companyData.GST || "",
      TAN: companyData.TAN || "",
      AddrLine1: companyData.AddrLine1 || "",
      AddrLine2: companyData.AddrLine2 || "",
      City: companyData.City || "",
      State: companyData.State || "",
      PostalCode: companyData.PostalCode || "",
    },
    validationSchema: Yup.object({
      CompanyName: Yup.string().required("Please Enter Company Name"),
      // Other validation rules can be added here as needed
    }),
    onSubmit: async (values) => {
      debugger;
      const payload = {
        CompanyId: Id,
        // PersonId:companyData.PersonId,
        PhoneId:companyData.PhoneId,
        EmailId:companyData.EmailId,
        AddressId:companyData.AddressId,
        EntityId:companyData.EntityId,
        PersonId:companyData.PersonId,
        CountryCode:'+91',
        PhoneType:'Regular',
        IsPreferred:true,
        EmailType:'Regular',
        AddressType:'Regular',
        Country:'India',
        ...values,
      };
      dispatch(AddUpdateCompany(payload));
      navigate("/Company");
    },
  });
  useEffect(() => {
    const formDirty = JSON.stringify(validation.initialValues) !== JSON.stringify(validation.values);
    setIsFormDirty(formDirty);
  }, [validation.values, validation.initialValues]);

  return (
    <React.Fragment>
      <Form onSubmit={(e) => { e.preventDefault(); validation.handleSubmit(); return false; }}>
        <Row>
          <Col lg={6} className="mb-6">
            <div className="form-group">
              <Label htmlFor="CompanyName" className="col-form-label">
                Company Name<span className="text-danger">*</span>
              </Label>
              <Input
                id="CompanyName"
                name="CompanyName"
                type="text"
                className="form-control validate"
                placeholder="Enter Company Name..."
                onChange={validation.handleChange}
                value={validation.values.CompanyName}
              />
              {validation.touched.CompanyName && validation.errors.CompanyName ? (
                <FormFeedback type="invalid">
                  {validation.errors.CompanyName}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6} className="mb-6">
            <div className="form-group">
              <Label htmlFor="LandlinePhone" className="col-form-label">
                Landline Phone
              </Label>
              <Input
                id="LandlinePhone"
                name="LandlinePhone"
                type="text"
                className="form-control"
                placeholder="Enter Landline Phone"
                onChange={validation.handleChange}
                value={validation.values.LandlinePhone}
              />
              {validation.touched.LandlinePhone && validation.errors.LandlinePhone ? (
                <FormFeedback type="invalid">
                  {validation.errors.LandlinePhone}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} className="mb-6">
            <div className="form-group">
              <Label htmlFor="MobilePhone" className="col-form-label">
                Mobile Phone<span className="text-danger">*</span>
              </Label>
              <Input
                id="MobilePhone"
                name="MobilePhone"
                type="text"
                className="form-control"
                placeholder="Enter Mobile Phone..."
                onChange={validation.handleChange}
                value={validation.values.MobilePhone}
              />
              {validation.touched.MobilePhone && validation.errors.MobilePhone ? (
                <FormFeedback type="invalid">
                  {validation.errors.MobilePhone}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6} className="mb-6">
            <div className="form-group">
              <Label htmlFor="Email" className="col-form-label">
                Email<span className="text-danger">*</span>
              </Label>
              <Input
                id="Email"
                name="Email"
                type="email"
                className="form-control"
                placeholder="Enter Email"
                onChange={validation.handleChange}
                value={validation.values.Email}
              />
              {validation.touched.Email && validation.errors.Email ? (
                <FormFeedback type="invalid">
                  {validation.errors.Email}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} className="mb-6">
            <div className="form-group">
              <Label htmlFor="PAN" className="col-form-label">
                PAN<span className="text-danger">*</span>
              </Label>
              <Input
                id="PAN"
                name="PAN"
                type="text"
                className="form-control"
                placeholder="Enter PAN"
                maxLength={10}
                minLength={10}
                length={10}
                onChange={validation.handleChange}
                value={validation.values.PAN}
              />
              {validation.touched.PAN && validation.errors.PAN ? (
                <FormFeedback type="invalid">
                  {validation.errors.PAN}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6} className="mb-6">
            <div className="form-group">
              <Label htmlFor="GST" className="col-form-label">
                GST
              </Label>
              <Input
                id="GST"
                name="GST"
                type="text"
                className="form-control"
                placeholder="Enter GST"
                onChange={validation.handleChange}
                value={validation.values.GST}
              />
              {validation.touched.GST && validation.errors.GST ? (
                <FormFeedback type="invalid">
                  {validation.errors.GST}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} className="mb-6">
            <div className="form-group">
              <Label htmlFor="TAN" className="col-form-label">
                TAN
              </Label>
              <Input
                id="TAN"
                name="TAN"
                type="text"
                className="form-control"
                placeholder="Enter TAN"
                onChange={validation.handleChange}
                value={validation.values.TAN}
              />
              {validation.touched.TAN && validation.errors.TAN ? (
                <FormFeedback type="invalid">
                  {validation.errors.TAN}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6} className="mb-6">
            <div className="form-group">
              <Label htmlFor="AddrLine1" className="col-form-label">
                Address Line 1<span className="text-danger">*</span>
              </Label>
              <Input
                id="AddrLine1"
                name="AddrLine1"
                type="text"
                className="form-control"
                placeholder="Enter Address Line 1"
                onChange={validation.handleChange}
                value={validation.values.AddrLine1}
              />
              {validation.touched.AddrLine1 && validation.errors.AddrLine1 ? (
                <FormFeedback type="invalid">
                  {validation.errors.AddrLine1}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} className="mb-6">
            <div className="form-group">
              <Label htmlFor="AddrLine2" className="col-form-label">
                Address Line 2
              </Label>
              <Input
                id="AddrLine2"
                name="AddrLine2"
                type="text"
                className="form-control"
                placeholder="Enter Address Line 2"
                onChange={validation.handleChange}
                value={validation.values.AddrLine2}
              />
            </div>
          </Col>
  
          <Col lg={6} className="mb-6">
          <div className="form-group">
              <Label htmlFor="State" className="col-form-label">
                State<span className="text-danger">*</span>
              </Label>
              <Select
                id="State"
                name="State"
                options={stateList}
                onChange={(option) => {
                  setSelectedState(option);
                  validation.setFieldValue("State", option ? option.label : '');
                  setSelectedCity(""); 
                  validation.setFieldValue("City", '');
                }}
                onBlur={validation.handleBlur}
                value={selectedState || stateList.find(state => state.label === validation.values.State)}
                placeholder="Select State"
              />
              {validation.touched.State && validation.errors.State ? (
                <FormFeedback type="invalid">{validation.errors.State}</FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>

        <Row>
        <Col lg={6} className="mb-6">
            <div className="form-group">
              <Label htmlFor="City" className="col-form-label">
                City<span className="text-danger">*</span>
              </Label>
              <Select
                id="City"
                name="City"
                options={citiesList}
                onChange={(option) => {
                  setSelectedCity(option);
                  validation.setFieldValue("City", option ? option.label : '');
                }}
                onBlur={validation.handleBlur}
                value={selectedCity || citiesList.find(city => city.label === validation.values.City)}
                placeholder="Select City"
              />
              {validation.touched.City && validation.errors.City ? (
                <FormFeedback type="invalid">{validation.errors.City}</FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6} className="mb-6">
            <div className="form-group">
              <Label htmlFor="PostalCode" className="col-form-label">
                PostalCode<span className="text-danger">*</span>
              </Label>
              <Input
                id="PostalCode"
                name="PostalCode"
                type="text"
                className="form-control"
                placeholder="Enter PostalCode"
                onChange={validation.handleChange}
                value={validation.values.PostalCode}
              />
              {validation.touched.PostalCode && validation.errors.PostalCode ? (
                <FormFeedback type="invalid">
                  {validation.errors.PostalCode}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg={6} className="d-flex justify-content-start">
            <button
              type="button" // Change to "button" for navigation
              className="btn btn-secondary"
              id="Back"
              onClick={() => navigate("/Company")}
            >
              Back to List
            </button>
          </Col>
          <Col lg={6} className="d-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-warning"
              id="updatecompany"
              disabled={!isFormDirty} // Disable button if form is not dirty
            >
              Update Company
            </button>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default connect(null, { setBreadcrumbItems })(EditCompany);
