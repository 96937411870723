// Add/Update Item Category Action
export const AddUpdateItemCategory = (itempayload) => {
  return {
    type: "ADD_UPDATE_ITEM_CATEGORY",
    payload: { itempayload },
  };
};

// Success for Add/Update Item Category
export const AddUpdateItemCategorySuccess = (itempayload) => {
  return {
    type: "ADD_UPDATE_ITEM_CATEGORY_SUCCESS",
    payload: itempayload,
  };
};

export const setShowItemGetCategoryList = (showItemGetCategoryList) => ({
  type: "SET_SHOW_ITEM_CATERGORY_LIST",
  payload: showItemGetCategoryList,
});


// Get Item Categories Action
export const GetItemCategories = () => {
  return {
    type: "GET_ALL_ITEM_CATEGORIES",
  };
};

// Success for Get Item Categories
export const GetItemCategoriesSuccess = (itempayload) => {
  return {
    type: "GET_ALL_ITEM_CATEGORIES_SUCCESS",
    payload: itempayload,
  };
};


export const GetItemCategorySuccess = (itempayload) => {
  return {
    type: "GET_ITEM_CATEGORY_SUCCESS",
    payload: itempayload,
  };
};

// Action for API error
export const ItemCategoryAPIError = (error) => {
  return {
    type: "ITEM_CATEGORY_API_ERROR",
    payload: error,
  };
};

export const GetItemCategoryById = (id ) => {
  debugger;
  return {
    type: "GET_ITEM_CATEGORY",
    payload: { id },
  };
};