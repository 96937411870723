import { call, put, takeEvery, select } from "redux-saga/effects";
import { AddUpdateItemSuccess, GetItemsSuccess, GetItemSuccess, ItemAPIError } from "./actions";
import axios from "axios";
import * as url from "../../../helpers/url_helper";
import { toast } from "react-toastify";

function* AddUpdateItem({ payload: { itemPayload } }) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select((state) => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(axios.post, url.POST_ADD_UPDATE_ITEM, itemPayload, config);
    if (response.status >= 200 && response.status < 300) {
      yield put(AddUpdateItemSuccess(response.data));
      yield put({ type: "SET_SHOW_ITEM_LIST", payload: true }); 
      toast.success("Item " + (itemPayload.ItemId ? "Updated" : "Added") + " Successfully");
    } else {
      throw new Error(response.data.errorMessage || "Failed to add/update item");
    }
  } catch (err) {
    const errorMessage = err.response?.data?.error || err.response?.statusText || "An error occurred";
    yield put(ItemAPIError(errorMessage));
    toast.error(errorMessage);
  }
}

function* GetItems() {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select((state) => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(axios.get, url.GET_ITEMS, config);
    if (response.status >= 200 && response.status < 300) {
      yield put(GetItemsSuccess(response.data.items || response.data));
    } else {
      throw new Error("Failed to fetch items");
    }
  } catch (err) {
    const errorMessage = err.response?.data?.error || err.response?.statusText || "An error occurred";
    yield put(ItemAPIError(errorMessage));
    toast.error(errorMessage);
  }
}

function* GetItemById({ payload: { id } }) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select((state) => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(axios.get, `${url.GET_ITEM}/${id}`, config);
    if (response.status >= 200 && response.status < 300) {
      yield put(GetItemSuccess(response.data));
    } else {
      throw new Error("Failed to fetch item");
    }
  } catch (err) {
    const errorMessage = err.response?.data?.error || err.response?.statusText || "An error occurred";
    yield put(ItemAPIError(errorMessage));
    toast.error(errorMessage);
  }
}

function* ItemSaga() {
  yield takeEvery("ADD_UPDATE_ITEM", AddUpdateItem);
  yield takeEvery("GET_ALL_ITEMS", GetItems);
  yield takeEvery("GET_ITEM", GetItemById);
}

export default ItemSaga;
