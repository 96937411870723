import React, { useState, useEffect } from "react";
import { Button, Row, Col, Input, Collapse } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { GetItems,setShowItemGetList } from "../../store/Master/Item/actions";
import DataTable from "../../components/Common/CustomDataTable";
import FilterSection from "../../components/Common/FilterSection";

const AllQuotation = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const itemsFromStore = useSelector((state) => state.ItemMaster?.items?.data) || [];
  const [globalSearch, setGlobalSearch] = useState("");
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [filters, setFilters] = useState({
    Category: "",
    SubCategory: "",
    SystemItemName: "",
    ItemName: "",
    ItemCode: "",
    HSNCode: "",
    Rate:"",
    Status: "",
  });

  useEffect(() => {
    dispatch(GetItems());
  }, [dispatch]);

  useEffect(() => {
    console.log("Items from Redux:", itemsFromStore);
    if (!Array.isArray(itemsFromStore)) {
      console.error("Items is not an array:", itemsFromStore);
    }
  }, [itemsFromStore]);

  const actionBodyTemplate = (rowData) => (
    <div className="actions-column">
      <Button color="info" className="me-2" onClick={() => navigate(`View/${rowData.ItemId}`)}>
        <i className="mdi mdi-eye"></i>
      </Button>
      <Button color="primary" onClick={() => navigate(`Edit/${rowData.ItemId}`)}>
        <i className="mdi mdi-pencil"></i>
      </Button>
    </div>
  );

  const itemColumns = [
    { name: "Category", field: "Category", sortable: true,  width: "100px" },
    { name: "Sub Category", field: "SubCategory", sortable: true,  width: "170px" },
    { name: "System Item Name", field: "SystemItemName", sortable: true,  width: "220px" },
    { name: "Item Name", field: "ItemName", sortable: true,  width: "150px" },
    { name: "Item Code", field: "ItemCode", sortable: true,  width: "150px" },
    { name: "Dimentions", field: "Dimentions", sortable: true,  width: "100px" },
    { name: "HSN Code", field: "HSNCode", sortable: true,  width: "150px" },
    { name: "Rate", field: "Rate", sortable: true,  width: "100px" },
    { name: "Status", field: "Status", sortable: true,  width: "100px" },
    { name: "Action", field: "",   body: (rowData) => actionBodyTemplate(rowData),  ignoreRowClick: true,  allowOverflow: true,  button: true,  width: "150px" },
  ];

  const filteredData = itemsFromStore.filter((data) =>
    Object.keys(filters).every(
      (key) => !filters[key] || (data[key] && data[key].toString().toLowerCase().includes(filters[key].toLowerCase()))
    )
  );

  const csvReport = {
    filename: "Item_Report.csv",
    headers: itemColumns.map((col) => ({ label: col.name, key: col.field })),
    data: filteredData,
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleGlobalSearchChange = (e) => {
    setGlobalSearch(e.target.value);
  };

  return (
    <>
      <Row className="mb-3">
        <Col md={12} className="d-flex justify-content-end align-items-center">
          <div className="input-container me-2">
            <i className="mdi mdi-magnify search-icon"></i>
            <Input
              type="text"
              value={globalSearch}
              onChange={handleGlobalSearchChange}
              className="search-input"
            />
          </div>
          <Button color="primary" onClick={() => setIsOpenFilter(!isOpenFilter)} className="me-2">
            <i className="mdi mdi-filter"></i>
          </Button>
          <Button color="success" onClick={() => props.setShowItemGetList(false)} className="me-2">
            <i className="mdi mdi-plus-thick"></i> ADD
          </Button>
        </Col>
      </Row>
      <Collapse isOpen={isOpenFilter}>
    <FilterSection
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          filters={filters}
          Columns={itemColumns}
          handleFilterChange={handleFilterChange}
        />
      </Collapse>

      <DataTable
        filteredData={filteredData}
        Columns={itemColumns}
        csvReport={csvReport}
        List={GetItems}
        globalSearch={globalSearch}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  showItemGetList: state.ItemMaster?.showItemGetList,
});

export default connect(mapStateToProps, { setShowItemGetList })(AllQuotation);
