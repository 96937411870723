import React, { useEffect, useState, useMemo } from "react";
import { Row, Col, Label, FormFeedback, Form, Input,Card,
  CardBody,
  CardHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import { AddUpdateItemCategory, GetItemCategoryById } from "../../../store/Master/ItemCategory/actions";
import { useParams, useNavigate } from "react-router-dom";
import { setBreadcrumbItems, setShowItemGetCategoryList, GetExistingFields } from "../../../store/actions";
import moment from "moment-timezone"

const EditItemCategory = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Id } = useParams();
  const itemCategoryData = useSelector((state) => state.ItemCategoryMaster?.category?.data) || {};
  const existingFields = useSelector((state) => state.CommonAPI?.itemExistingFields?.data) || [];

  const breadcrumbItems = useMemo(() => [
    { title: "Comfort Zone" },
    { title: "Itemcategory" },
    { title: "Edit Item Category" },
  ], []);

  const [isFormDirty, setIsFormDirty] = useState(false);

  useEffect(() => {
    props.setBreadcrumbItems('Edit Item Category', breadcrumbItems);
    if (Id) {
      dispatch(GetItemCategoryById(Id));
      dispatch(GetExistingFields({ "pColumns": "ItemCategoryName,ItemCategoryId", "pTableName": "ItemCategory" }));
    }
  }, [Id, dispatch, breadcrumbItems, props]);

  useEffect(() => {
    console.log("item category fields", existingFields);
  }, [existingFields]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      ItemCategoryName: itemCategoryData[0]?.ItemCategoryName || "",
      Description: itemCategoryData[0]?.Description || "",
      AddedBy: itemCategoryData[0]?.AddedBy || "",
      CreatedOn: itemCategoryData[0]?.CreatedOn ? moment.tz(itemCategoryData[0]?.CreatedOn, "America/New_York").tz("Asia/Kolkata").format("DD MMMM YYYY, hh:mm A") : "",
      UpdatedByName: itemCategoryData[0]?.UpdatedByName || "",
      UpdatedOn: itemCategoryData[0]?.UpdatedOn ? moment.tz(itemCategoryData[0]?.UpdatedOn, "America/New_York").tz("Asia/Kolkata").format("DD MMMM YYYY, hh:mm A") : "",
    },
    validationSchema: Yup.object({
      ItemCategoryName: Yup.string()
      .required("Please Enter Item Category Name")
      .test(
        "unique-ItemCategoryName",
        "This Item Category Name already taken",
        function (value) {
            const currentId = Id;
            const existingItemNames = existingFields
                .filter(item => item.ItemCategoryId !== currentId)
                .map(item => item.ItemCategoryName);
            return !existingItemNames.includes(value);
        }
    ),
      Description: Yup.string(),
    }),
    onSubmit: async (values) => {
      const { AddedBy, CreatedOn, UpdatedByName, UpdatedOn, ...payload } = values;

      payload.ItemCategoryId = Id;
      
      dispatch(AddUpdateItemCategory(payload));
      setTimeout(() => {
        navigate('/Master/ItemCategory');
      }, 2000);
    },
  });

  useEffect(() => {
    const formDirty = JSON.stringify(validation.initialValues) !== JSON.stringify(validation.values);
    setIsFormDirty(formDirty);
  }, [validation.values, validation.initialValues]);

  return (
    <React.Fragment>
      <Row className="justify-content-center">
        <Col lg={8}>
          <Card>
            <CardHeader>
              <h3 className="text-center"> Item Category</h3>
            </CardHeader>
            <CardBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row className="justify-content-center">
                  <Col lg={6} className="mb-6">
                    <div className="form-group">
                      <Label htmlFor="ItemCategoryName" className="col-form-label">
                        Item Category Name<span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="ItemCategoryName"
                        name="ItemCategoryName"
                        type="text"
                        className="form-control validate"
                        placeholder="Enter Item Category Name..."
                        validate={{ required: { value: true } }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.ItemCategoryName} // Bind input value to formik
                        invalid={validation.touched.ItemCategoryName && validation.errors.ItemCategoryName}
                      />
                      {validation.touched.ItemCategoryName && validation.errors.ItemCategoryName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.ItemCategoryName}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Col lg={6} className="mb-6">
                    <div className="form-group">
                      <Label htmlFor="Description" className="col-form-label">
                        Description
                      </Label>
                      <Input
                        id="Description"
                        name="Description"
                        type="textarea"
                        className="form-control"
                        placeholder="Enter Description"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.Description} // Bind input value to formik
                      />
                      {validation.touched.Description && validation.errors.Description ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.Description}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <Row className="mt-4 justify-content-center">
                  <Col lg={3} className="d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => {
                        props.setShowItemGetCategoryList(true);
                        navigate("/Master/ItemCategory");
                      }}
                    >
                      Cancel
                    </button>
                  </Col>
                  <Col lg={3} className="d-flex justify-content-center">
                    <button type="submit" className="btn btn-success" disabled={!isFormDirty} >
                      Update
                    </button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
            <Row className="d-flex justify-content-between flex-column flex-lg-row mt-4">
              <Col lg={6} className="mb-4">
                <p className="mb-1 ms-3"><strong>Created By:</strong> {validation.values.AddedBy}</p>
                <p className="mb-1 ms-3"><strong>Created On:</strong> {validation.values.CreatedOn}</p>
              </Col>
              <Col lg={6} className="mb-4">
                {(validation.values.UpdatedByName || validation.values.UpdatedOn) && (
                  <>
                    <p className="mb-1 ms-3"><strong>Last Updated By:</strong> {validation.values.UpdatedByName}</p>
                    <p className="mb-1 ms-3"><strong>Last Updated On:</strong> {validation.values.UpdatedOn}</p>
                  </>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </React.Fragment >
  );
};

const mapStateToProps = (state) => {
  return {
    showItemGetCategoryList: state.ItemCategoryMaster?.showItemGetCategoryList,
  };
};
export default connect(mapStateToProps, {setBreadcrumbItems,setShowItemGetCategoryList })(EditItemCategory);

