import PropTypes from 'prop-types';
import React from "react";
import { Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer } from 'react-toastify'; 
import { PersistGate } from 'redux-persist/integration/react'; // Import PersistGate
import { persistor } from './store/index';
import 'react-toastify/dist/ReactToastify.css'; 
import './styles/global.css';

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

const App = (props) => {
  const getLayout = () => VerticalLayout; // Directly returning the layout

  const Layout = getLayout();

  return (
    <PersistGate loading={null} persistor={persistor}>
      <React.Fragment>
        <Routes>
          {/* Non-authenticated routes */}
          {authRoutes.map((route) => (
            <Route
              key={route.path} // Changed to use route.path as the key
              path={route.path}
              element={
                <NonAuthLayout>
                  {route.component}
                </NonAuthLayout>
              }
            />
          ))}

          {/* Authenticated routes */}
          {userRoutes.map((route) => (
            <Route
              key={route.path} // Changed to use route.path as the key
              path={route.path}
              element={
                <Authmiddleware>
                  <Layout>{route.component}</Layout>
                </Authmiddleware>
              }
            />
          ))}
        </Routes>
        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          closeOnClick
          pauseOnHover
          draggable
          theme="colored"
        />
      </React.Fragment>
    </PersistGate>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => ({
  layout: state.Layout, // Using shorthand property name
});

export default connect(mapStateToProps)(App);
