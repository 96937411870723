import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

import Breadcrumb from "./Breadcrumb/reducer";  

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

// Item Reducer
import ItemCategoryMaster from "./Master/ItemCategory/reducer";
import ItemReducer from "./Master/Item/reducer";
import ItemMetaDataMaster from "./Master/ItemMetaData/reducer";
//Calendar
import calendar from "./calendar/reducer"

//tasks
import tasks from "./tasks/reducer";

import CommonAPI from "./Common/reducer";

import CompnayAPI from "./Company/reducer";

import Organization from "./Company/reducer";

const rootReducer = combineReducers({
  Layout,
   Breadcrumb,
  tasks, 
  Login,
  Account,
  ForgetPassword,
  Profile,
  calendar,
  ItemCategoryMaster,
  CommonAPI,
  CompnayAPI,
  ItemMaster: ItemReducer,
  ItemMetaDataMaster,
  Organization
})

export default rootReducer
