import React, { useState, useEffect } from "react";
import { Row, Col, Label, FormFeedback, Form, Card, CardBody, CardTitle, Input, Button } from "reactstrap";
import Select from 'react-select';
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import { AddUpdateItem, GetItemCategoryDropdown, setShowItemGetList } from "../../store/actions";

const AddQuotaion = (props) => {
    const dispatch = useDispatch();
    const Organizations = useSelector((state) => state.CommonAPI?.OrganizationList?.data) || [];
    const Parties = useSelector((state) => state.CommonAPI?.PartyList?.data) || [];
    const AddressTypes = useSelector((state) => state.CommonAPI?.addressTypeList?.data) || [];
    const [showDefaultAddress, setShowDefaultAddress] = useState(false);

    const [selectedOptions, setSelectedOption] = useState({
        QuotationFromCompany: null,
        Party: null,
        ShipToAddress: null,
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        PostalCode: ''
    });
    const selectedPartyDetails = Parties.find(party => party.StringMapId === selectedOptions.Party?.value);
    console.log("selectedPartyDetails", selectedPartyDetails);
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            QuotationFromCompany: null,
            Party: null,
            ShipToAddress: '',
            addressLine1: selectedPartyDetails?.AddrLine1 || '',
            addressLine2: selectedPartyDetails?.AddrLine2 || '',
            city: selectedPartyDetails?.City || '',
            state: selectedPartyDetails?.State || '',
            PostalCode: selectedPartyDetails?.PostalCode || ''
        },
        validationSchema: Yup.object({
            QuotationFromCompany: Yup.string().required("Please Select Quotation From Company"),
            Party: Yup.string().required("Please Select Party"),
            ShipToAddress: Yup.string().required("Please Select Shipping Address Type"),
            addressLine1: Yup.string().required("Please Enter Address Line 1"),
            addressLine2: Yup.string().required("Please Enter Address Line 2"),
            city: Yup.string().required("Please Enter City"),
            state: Yup.string().required("Please Enter State"),
            PostalCode: Yup.string().required("Please Enter Postal Code")
        }),
        onSubmit: async (values) => {
            console.log("values", values);
            // dispatch(AddUpdateItemCategory(values));
        },
        validateOnChange: true, // Validate on field change
    });

    const handleDropdownChange = (selectedOption, name) => {
        setSelectedOption((prevState) => {
            const newState = {
                ...prevState,
                [name]: selectedOption,
            };

            // Check if the selected option is "ShipToAddress" and has label "Same as Bill To"
            if (name === 'ShipToAddress' && selectedOption.label === "Same as Bill To") {
                setShowDefaultAddress(true);
                validation.setValues({
                    ...validation.values,
                    addressLine1: selectedPartyDetails?.AddrLine1 || '',
                    addressLine2: selectedPartyDetails?.AddrLine2 || '',
                    city: selectedPartyDetails?.City || '',
                    state: selectedPartyDetails?.State || '',
                    PostalCode: selectedPartyDetails?.PostalCode || ''
                });
            } else if (name === 'ShipToAddress') {
                setShowDefaultAddress(false);
                validation.setValues({
                    ...validation.values,
                    addressLine1: '',
                    addressLine2: '',
                    city: '',
                    state: '',
                    PostalCode: ''
                });
            }

            return newState;
        });
    };

    useEffect(() => {
        dispatch(GetItemCategoryDropdown({ "StringMapType": "OrganizationList", "OrderBy": "StringMapName" }));
        dispatch(GetItemCategoryDropdown({ "StringMapType": "PartyList", "OrderBy": "StringMapName" }));
        dispatch(GetItemCategoryDropdown({ "StringMapType": "SHIPPINGADDRESSTYPE", "OrderBy": "StringMapName" }));
        validation.setFieldValue('QuotationFromCompany', selectedOptions.QuotationFromCompany?.value || "");
        validation.setFieldValue('Party', selectedOptions.Party?.value || "");
        validation.setFieldValue('ShipToAddress', selectedOptions.ShipToAddress?.value || "");

    }, [dispatch, selectedOptions]);

    const selectStyles = (hasError) => ({
        control: (provided, state) => ({
            ...provided,
            borderColor: hasError ? 'red' : provided.borderColor,
            boxShadow: 'none',
            '&:hover': {
                borderColor: hasError ? 'red' : provided['&:hover'].borderColor,
            },
        }),
        outline: "none",
    });

    return (
        <React.Fragment>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <Row>
                    <Col lg={6} className="mb-2">
                        <div className="form-group">
                            <Label htmlFor="Category" className="col-form-label">
                                Item Category<span className="text-danger">*</span>
                            </Label>
                            <Select
                                id="QuotationFromCompany"
                                name="QuotationFromCompany"
                                options={Organizations.map((value) => ({
                                    label: value.StringMapName,
                                    value: value.StringMapId,
                                }))}
                                onChange={(option) => handleDropdownChange(option, 'QuotationFromCompany')}
                                onBlur={validation.handleBlur}
                                value={selectedOptions.QuotationFromCompany}
                                placeholder="Select Quotation From Company"
                                isSearchable={true}
                                styles={selectStyles(validation.touched.QuotationFromCompany && validation.errors.QuotationFromCompany)}
                                classNamePrefix="react-select"
                            />
                            {validation.touched.QuotationFromCompany && validation.errors.QuotationFromCompany ? (
                                <FormFeedback className="d-block">{validation.errors.QuotationFromCompany}</FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                    <Col lg={6} className="mb-2">
                        <div className="form-group">
                            <Label htmlFor="SubCategory" className="col-form-label">
                                Item Sub Category<span className="text-danger">*</span>
                            </Label>
                            <Select
                                id="Party"
                                name="Party"
                                options={Parties.map((value) => ({
                                    label: value.StringMapName,
                                    value: value.StringMapId,
                                }))}
                                onChange={(option) => handleDropdownChange(option, 'Party')}
                                onBlur={validation.handleBlur}
                                value={selectedOptions.Party}
                                placeholder="Select Party"
                                isSearchable={true}
                                styles={selectStyles(validation.touched.Party && validation.errors.Party)}
                                classNamePrefix="react-select"
                            />
                            {validation.touched.Party && validation.errors.Party ? (
                                <FormFeedback className="d-block">{validation.errors.Party}</FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                </Row>
                {selectedPartyDetails ? (
                <Card className="mt-2">
                    <CardBody>
                        <CardTitle tag="h5">Bill To</CardTitle>
                        <p><strong>Party Name:</strong> {selectedPartyDetails?.OrganizationName}</p>
                        <p><strong>Address:</strong> {selectedPartyDetails?.FullAddress || 'N/A'}</p>
                        <p><strong>GST No.:</strong> {selectedPartyDetails?.GST || 'N/A'}</p>
                    </CardBody>
                </Card>
                    ) : null}
                <Row>
                    <Col lg={6} className="mb-2">
                        <Label htmlFor="ShipToAddress" className="col-form-label">Specify Ship To Address<span className="text-danger">*</span></Label>
                        <Select
                            id="ShipToAddress"
                            name="ShipToAddress"
                            options={AddressTypes.map(value => ({ label: value.StringMapName, value: value.StringMapId }))}
                            onChange={(option) => handleDropdownChange(option, 'ShipToAddress')}
                            value={selectedOptions.ShipToAddress}
                            placeholder="Select Ship To Address"
                            isSearchable={true}
                            styles={selectStyles(validation.touched.ShipToAddress && validation.errors.ShipToAddress)}
                            classNamePrefix="react-select"
                        />
                        {validation.touched.ShipToAddress && validation.errors.ShipToAddress ? (
                            <FormFeedback className="d-block">{validation.errors.ShipToAddress}</FormFeedback>
                        ) : null}
                    </Col>
                </Row>

                <Row>
                    <Col lg={6} className="mb-2">
                        <Label htmlFor="addressLine1" className="col-form-label">Address Line 1<span className="text-danger">*</span></Label>
                        <Input
                            id="addressLine1"
                            name="addressLine1"
                            type="text"
                            className="form-control validate"
                            maxLength={50}
                            placeholder="Enter Item Category Name..."
                            validate={{ required: { value: true } }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.addressLine1}
                            invalid={validation.touched.addressLine1 && validation.errors.addressLine1 ? true : false}
                            disabled={showDefaultAddress}
                        />
                        {validation.touched.addressLine1 && validation.errors.addressLine1 ? (
                            <FormFeedback type="invalid">
                                {validation.errors.addressLine1}
                            </FormFeedback>
                        ) : null}
                    </Col>
                    <Col lg={6} className="mb-2">
                        <Label htmlFor="addressLine2" className="col-form-label">Address Line 2<span className="text-danger">*</span></Label>
                        <Input
                            id="addressLine2"
                            name="addressLine2"
                            type="text"
                            className="form-control validate"
                            maxLength={50}
                            placeholder="Enter Item Category Name..."
                            validate={{ required: { value: true } }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.addressLine2}
                            invalid={validation.touched.addressLine2 && validation.errors.addressLine2 ? true : false}
                            disabled={showDefaultAddress}
                        />
                        {validation.touched.addressLine2 && validation.errors.addressLine2 ? (
                            <FormFeedback type="invalid">
                                {validation.errors.addressLine2}
                            </FormFeedback>
                        ) : null}
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} className="mb-2">
                        <Label htmlFor="city" className="col-form-label">City<span className="text-danger">*</span></Label>
                        <Input
                            id="city"
                            name="city"
                            type="text"
                            className="form-control validate"
                            maxLength={50}
                            placeholder="Enter Item Category Name..."
                            validate={{ required: { value: true } }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.city}
                            invalid={validation.touched.city && validation.errors.city ? true : false}
                            disabled={showDefaultAddress}
                        />
                        {validation.touched.city && validation.errors.city ? (
                            <FormFeedback type="invalid">
                                {validation.errors.city}
                            </FormFeedback>
                        ) : null}
                    </Col>
                    <Col lg={6} className="mb-2">
                        <Label htmlFor="state" className="col-form-label">State<span className="text-danger">*</span></Label>
                        <Input
                            id="state"
                            name="state"
                            type="text"
                            className="form-control validate"
                            maxLength={50}
                            placeholder="Enter Item Category Name..."
                            validate={{ required: { value: true } }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.state}
                            invalid={validation.touched.state && validation.errors.state ? true : false}
                            disabled={showDefaultAddress}
                        />
                        {validation.touched.state && validation.errors.state ? (
                            <FormFeedback type="invalid">
                                {validation.errors.state}
                            </FormFeedback>
                        ) : null}
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} className="mb-2">
                        <Label htmlFor="PostalCode" className="col-form-label">Postal Code<span className="text-danger">*</span></Label>
                        <Input
                            id="PostalCode"
                            name="PostalCode"
                            type="text"
                            className="form-control validate"
                            maxLength={50}
                            placeholder="Enter Item Category Name..."
                            validate={{ required: { value: true } }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.PostalCode}
                            invalid={validation.touched.PostalCode && validation.errors.PostalCode ? true : false}
                            disabled={showDefaultAddress}
                        />
                        {validation.touched.PostalCode && validation.errors.PostalCode ? (
                            <FormFeedback type="invalid">
                                {validation.errors.PostalCode}
                            </FormFeedback>
                        ) : null}
                    </Col>
                </Row>

                <Row className="mt-4 justify-content-end">
                    <Col lg={6} className="d-flex justify-content-end mb-3">
                        <button
                            type="button"
                            className="btn btn-danger large-button me-2"
                            onClick={() => {
                                // props.setShowItemGetList(true);
                            }}
                        >
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-success large-button">
                            Submit
                        </button>
                    </Col>
                </Row>

            </Form>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        showItemGetCategoryList: state.ItemCategoryMaster?.showItemGetCategoryList,
    };
};

export default connect(mapStateToProps)(AddQuotaion);