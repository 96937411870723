export const fetchCountries = (countries) => ({
  type: "FETCH_COUNTRIES",
  payload: countries,
});

export const fetchCountriesSuccess = (countries) => ({
  type: "FETCH_COUNTRIES_SUCCESS",
  payload: countries,
});

export const fetchStates =  (states) => ({
  type: "FETCH_STATES",
  payload: states,

});

export const fetchStatesSuccess = (states) => ({
  type: "FETCH_STATES_SUCCESS",
  payload: states,
});

export const fetchCities =  (cities)  => ({
  type: "FETCH_CITIES",
  payload: cities,
});

export const fetchCitiesSuccess = (cities) => ({
  type: "FETCH_CITIES_SUCCESS",
  payload: cities,
});

export const CommonApiError = (error) => ({
  type: "COMMON_API_ERROR",
  payload: error,
});

// Get category dropdown data
export const GetItemCategoryDropdown = (itempayload) => {
  return {
    type: "GET_CATEGORY_DROPDOWN",
    payload: itempayload,
  };
};

// Get category dropdown data
export const GetItemCategoryDropdownSuccess = (itempayload) => {
  return {
    type: "GET_CATEGORY_DROPDOWN_SUCCESS",
    payload: itempayload,
  };
};

// Get existing fields data
export const GetExistingFields = (fieldPayload) => {
  return {
    type: "GET_EXISTING_FIELDS",
    payload: fieldPayload,
  };
};

// Get existing fields data
export const GetExistingFieldsSuccess = (fieldPayload) => {
  return {
    type: "GET_EXISTING_FIELDS_SUCCESS",
    payload: fieldPayload,
  };
};

// Upload File Action
export const UploadFile = (filePayload) => {
  return {
    type: "UPLOAD_FILE",
    payload: { filePayload },
  };
};

// Success for Upload File
export const UploadFileSuccess = (fileData) => {
  return {
    type: "UPLOAD_FILE_SUCCESS",
    payload: fileData,
  };
};

// Clear Upload File Path
export const ClearFilePath = () => {
  return {
    type: "CLEAR_FILE_PATH",
  };
};

// Remove Uploaded File Action
export const RemoveUpload = (filename) => {
  return {
    type: "REMOVE_UPLOAD",
    payload: filename,
  };
};

// Success for Remove Uploaded File
export const RemoveUploadSuccess = (filename) => {
  return {
    type: "REMOVE_UPLOAD_SUCCESS",
    payload: filename,
  };
};
