const initialState = {
  error: "",
  loading: false,
  items: [],
  data: null,
  showItemGetList: false,
};

const AddUpdateItem = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_UPDATE_ITEM":
    case "GET_ALL_ITEMS":
      return {
        ...state,
        loading: true,
      };

    case "ADD_UPDATE_ITEM_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };

    case "GET_ALL_ITEMS_SUCCESS":
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: "",
      };

    case "GET_ITEM_SUCCESS":
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };

    case "ITEM_API_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "SET_SHOW_ITEM_LIST":
      console.log("Setting showItemGetList to:", action.payload);
      return {
        ...state,
        showItemGetList: action.payload,
      };

    default:
      return state;
  }
};

export default AddUpdateItem;
