const initialState = {
  countries: [],
  states: [],
  cities: [],
  dropdownData: [],
  itemCategories: [],
  itemSubCategories: [],
  itemType1: [],
  itemType2: [],
  itemType3: [],
  unitList: [],
  organiztionType: [],
  businessType: [],
  addressType: [],
  phoneType: [],
  emailType: [],
  identifierType: [],
  title: [],
  personType: [],
  designation: [],
  department: [],
  itemExistingFields: [],
  uploadedFiles: [],
  fileData: [],
  OrganizationList:[],
  ParityList:[] ,
  addressTypeList:[],
  loading: false,
  error: '',
};

const CommonAPIReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_COUNTRIES":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_STATES":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_CITIES":
      return {
        ...state,
        loading: true,
      };

    case "FETCH_COUNTRIES_SUCCESS":
      return {
        ...state,
        loading: false,
        countries: action.payload,
        error: '',
      };

    case "FETCH_STATES_SUCCESS":
      return {
        ...state,
        loading: false,
        states: action.payload,
        error: '',
      };

    case "FETCH_CITIES_SUCCESS":
      return {
        ...state,
        loading: false,
        cities: action.payload,
        error: '',
      };

    case "COMMON_API_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "GET_CATEGORY_DROPDOWN":
      return {
        ...state,
        loading: true,
      };

    case "GET_CATEGORY_DROPDOWN_SUCCESS":
      if (action.payload.type === "ItemCategory") {
        return {
          ...state,
          loading: false,
          itemCategories: action.payload.data,
          error: "",
        };
      } else if (action.payload.type === "ItemSubCategory") {
        return {
          ...state,
          loading: false,
          itemSubCategories: action.payload.data,
          error: "",
        };
      } else if (action.payload.type === "ItemType1") {
        return {
          ...state,
          loading: false,
          itemType1: action.payload.data,
          error: "",
        };
      } else if (action.payload.type === "ItemType2") {
        return {
          ...state,
          loading: false,
          itemType2: action.payload.data,
          error: "",
        };
      } else if (action.payload.type === "ItemType3") {
        return {
          ...state,
          loading: false,
          itemType3: action.payload.data,
          error: "",
        };
      } else if (action.payload.type === "UnitofMesurement") {
        return {
          ...state,
          loading: false,
          unitList: action.payload.data,
          error: "",
        };
      } else if (action.payload.type === "ORGANIZATIONTYPE") {
        return {
          ...state,
          loading: false,
          organiztionType: action.payload.data,
          error: "",
        };
      } else if (action.payload.type === "BUSINESSTYPE") {
        return {
          ...state,
          loading: false,
          businessType: action.payload.data,
          error: "",
        };
      } else if (action.payload.type === "ADDRESSTYPE") {
        return {
          ...state,
          loading: false,
          addressType: action.payload.data,
          error: "",
        };
      } else if (action.payload.type === "PHONETYPE") {
        return {
          ...state,
          loading: false,
          phoneType: action.payload.data,
          error: "",
        };
      } else if (action.payload.type === "EMAILTYPE") {
        return {
          ...state,
          loading: false,
          emailType: action.payload.data,
          error: "",
        };
      } else if (action.payload.type === "IDENTIFIERTYPE") {
        return {
          ...state,
          loading: false,
          identifierType: action.payload.data,
          error: "",
        };
      } else if (action.payload.type === "Title") {
        return {
          ...state,
          loading: false,
          title: action.payload.data,
          error: "",
        };
      } else if (action.payload.type === "PERSONTYPE") {
        return {
          ...state,
          loading: false,
          personType: action.payload.data,
          error: "",
        };
      } else if (action.payload.type === "DESIGNATION") {
        return {
          ...state,
          loading: false,
          designation: action.payload.data,
          error: "",
        };
      } else if (action.payload.type === "DEPARTMENT") {
        return {
          ...state,
          loading: false,
          department: action.payload.data,
      };
      }else if (action.payload.type === "OrganizationList") {
        return {
          ...state,
          loading: false,
          OrganizationList: action.payload.data,
          error: "",
        }
      }else if (action.payload.type === "PartyList") {
        return {
          ...state,
          loading: false,
          PartyList: action.payload.data,
          error: "",
        }
      }else if (action.payload.type === "SHIPPINGADDRESSTYPE") {
        return {
          ...state,
          loading: false,
          addressTypeList: action.payload.data,
          error: "",
        };
      }
    case "GET_EXISTING_FIELDS":
      return {
        ...state,
        loading: true,
      };

    case "GET_EXISTING_FIELDS_SUCCESS":
      return {
        ...state,
        loading: false,
        itemExistingFields: action.payload.data,
        error: "",
      };

    case "UPLOAD_FILE":
    case "REMOVE_UPLOAD":
      return {
        ...state,
        loading: true,
      };

    case "UPLOAD_FILE_SUCCESS":
      return {
        ...state,
        loading: false,
        fileData: action.payload,
        error: "",
      };

    case "CLEAR_FILE_PATH":
      return {
        ...state,
        loading: false,
        fileData: [],
        error: "",
      };

    case "REMOVE_UPLOAD_SUCCESS":
      return {
        ...state,
        loading: false,
        error: "",
      };

    default:
      return state;
  }
};

export default CommonAPIReducer;
