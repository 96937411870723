import { call, put, takeEvery, select, delay } from "redux-saga/effects";
import { AddUpdateItemCategorySuccess, GetItemCategoriesSuccess, ItemCategoryAPIError,GetItemCategorySuccess } from "./actions";
import axios from "axios";
import * as url from "../../../helpers/url_helper";
import { toast } from "react-toastify";

function* AddUpdateItemCategory({ payload: { itempayload } }) {
  debugger;
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select((state) => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(axios.post, url.POST_ADD_UPDATE_ITEMS, itempayload, config);
    if (response.status >= 200 && response.status < 300) {
      yield put(AddUpdateItemCategorySuccess(response.data));
      toast.success("Item Category " + (itempayload.ItemCategoryId ? "Updated" : "Added") + " Successfully");
      yield delay(2000);
      yield put({ type: "SET_SHOW_ITEM_CATERGORY_LIST", payload: true }); 
    } else {
      throw new Error(response.data.errorMessage || "Failed to add/update item category");
    }
  } catch (err) {
    const errorMessage = err.response?.data?.error || err.response?.statusText || "An error occurred";
    yield put(ItemCategoryAPIError(errorMessage));
    toast.error(errorMessage);
  }
}

// Saga for fetching item categories
function* GetItemCategories() {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select((state) => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(axios.get, url.GET_ITEMS_CATEGORIES, config);
    if (response.status >= 200 && response.status < 300) {
      yield put(GetItemCategoriesSuccess(response.data));
    } else {
      throw new Error("Failed to fetch item categories");
    }
  } catch (err) {
    const errorMessage = err.response?.data?.error || err.response?.statusText || "An error occurred";
    yield put(ItemCategoryAPIError(errorMessage));
    toast.error(errorMessage);
  }
}

function* GetItemCategoryById({ payload: { id } }) {
  debugger;
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select((state) => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    };

    // Use the GET_ITEM_CATEGORY function to get the URL with the actual ID
    const response = yield call(axios.get, `${url.GET_ITEM_CATEGORY}${id}`, config);
    
    if (response.status >= 200 && response.status < 300) {
      console.log("response.data", response.data);
      yield put(GetItemCategorySuccess(response.data)); // Dispatch success action
    } else {
      throw new Error("Failed to fetch item category");
    }
  } catch (err) {
    const errorMessage = err.response?.data?.error || err.response?.statusText || "An error occurred";
    yield put(ItemCategoryAPIError(errorMessage));
    toast.error(errorMessage);
  }
}


// Watcher saga
function* ItemCategorySaga() {
  yield takeEvery("ADD_UPDATE_ITEM_CATEGORY", AddUpdateItemCategory);
  yield takeEvery("GET_ALL_ITEM_CATEGORIES", GetItemCategories);
  yield takeEvery("GET_ITEM_CATEGORY", GetItemCategoryById);

}

export default ItemCategorySaga;
