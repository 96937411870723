import React, {  useEffect } from "react";
import { Row, Col, Label, FormFeedback, Form, Input, Card, CardBody, CardHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import { AddUpdateItemCategory } from "../../../store/Master/ItemCategory/actions";
import { setShowItemGetCategoryList, GetExistingFields } from "../../../store/actions";

const AddItemCategory = (props) => {
  const dispatch = useDispatch();
  const existingFields = useSelector((state) => state.CommonAPI?.itemExistingFields?.data) || [];

  useEffect(() => {
    dispatch(GetExistingFields({ "pColumns": "ItemCategoryName", "pTableName": "ItemCategory" }));
  }, [dispatch])

  useEffect(() => {
    console.log("item category fields", existingFields);
  }, [existingFields]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      ItemCategoryName: '',
      Description: '',
    },
    validationSchema: Yup.object({
      ItemCategoryName: Yup.string()
      .required("Please Enter Item Category Name")
      .test(
        "unique-ItemCategoryName",
        "This Item Category Name already taken",
        function (value) {
          const existingItemNames = existingFields.map((item) => item.ItemCategoryName);
          return !existingItemNames.includes(value);
        }
      ),
      Description: Yup.string(), // Make Description optional
    }),
    onSubmit: async (values) => {
      dispatch(AddUpdateItemCategory(values));
    }
  });

  return (
    <React.Fragment>
      <Row className="justify-content-center">
        <Col lg={8}>
          <Card>
            <CardHeader className="mt-3">
              <h3 className="text-center"> Item Category</h3>
            </CardHeader>
            <CardBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row className="justify-content-center">
                  <Col lg={6} className="mb-4">
                    <div className="form-group">
                      <Label htmlFor="ItemCategoryName" className="col-form-label">
                        Item Category Name<span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="ItemCategoryName"
                        name="ItemCategoryName"
                        type="text"
                        className="form-control validate"
                        maxLength={50}
                        placeholder="Enter Item Category Name..."
                        validate={{ required: { value: true } }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={validation.touched.ItemCategoryName && validation.errors.ItemCategoryName ? true : false}
                      />
                      {validation.touched.ItemCategoryName && validation.errors.ItemCategoryName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.ItemCategoryName}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Col lg={6} className="mb-4">
                    <div className="form-group">
                      <Label htmlFor="Description" className="col-form-label">
                        Description
                      </Label>
                      <Input
                        id="Description"
                        name="Description"
                        type="textarea"
                        className="form-control"
                        placeholder="Enter Description"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                      />
                      {validation.touched.Description && validation.errors.Description ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.Description}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-4 justify-content-center">
                  <Col lg={6} className="d-flex justify-content-between mb-3">
                    <button
                      type="button"
                      className="btn btn-danger large-button"
                      onClick={() => {
                        props.setShowItemGetCategoryList(true); // Set to show item categories
                      }}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-success large-button">
                      Submit
                    </button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    showItemGetCategoryList: state.ItemCategoryMaster?.showItemGetCategoryList,
  };
};

export default connect(mapStateToProps, {setShowItemGetCategoryList })(AddItemCategory);
