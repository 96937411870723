import React, { useState, useEffect } from "react";
import { Button, UncontrolledTooltip, Row, Col, Input, Collapse } from "reactstrap"; // Added Collapse import
import { useNavigate } from "react-router-dom";
import 'primereact/resources/themes/mira/theme.css';
import 'primereact/resources/primereact.min.css';
import { connect, useDispatch, useSelector } from "react-redux";
import { GetItemCategories } from "../../../store/Master/ItemCategory/actions";
import { setShowItemGetCategoryList } from "../../../store/actions";
import DataTable from "../../../components/Common/CustomDataTable";
import FilterSection from "../../../components/Common/FilterSection";

const AllItemCategories = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const itemCategories = useSelector((state) => state.ItemCategoryMaster?.categories?.data) || [];

  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [filters, setFilters] = useState({
    ItemCategoryName: "",
    Description: "",
    Status: "",
  });
  const [globalSearch, setGlobalSearch] = useState(""); // Added globalSearch state

  useEffect(() => {
    dispatch(GetItemCategories());
  }, [dispatch]);

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions-column">
        <Button color="info" id={`view-${rowData.ItemCategoryId}`} className="me-2" onClick={() => navigate(`View/${rowData.ItemCategoryId}`)}>
          <i className="mdi mdi-eye"></i>
        </Button>
        <UncontrolledTooltip target={`view-${rowData.ItemCategoryId}`} placement="bottom">
          View Item Category
        </UncontrolledTooltip>

        <Button color="primary" id={`edit-${rowData.ItemCategoryId}`} className="me-2" onClick={() => navigate(`Edit/${rowData.ItemCategoryId}`)}>
          <i className="mdi mdi-pencil"></i>
        </Button>
        <UncontrolledTooltip target={`edit-${rowData.ItemCategoryId}`} placement="bottom">
          Edit Item Category
        </UncontrolledTooltip>

        <Button color="warning" id={`metadata-${rowData.ItemCategoryId}`} onClick={() => {
          navigate(`/Master/ItemMetaData/AddEdit/${rowData.ItemCategoryId}`);
        }}>
          <i className="mdi mdi-database"></i>
        </Button>
        <UncontrolledTooltip target={`metadata-${rowData.ItemCategoryId}`} placement="bottom">
          Add/Edit Item Meta Data
        </UncontrolledTooltip>
      </div>
    );
  };

  const itemCategoryColumns = [
    { name: "Item Category Name", field: "ItemCategoryName", sortable: true, width: "100px" },
    { name: "Description", field: "Description", sortable: true, width: "100px" },
    { name: "Status", field: "Status", sortable: true, width: "150px" },
    { name: "Action", field: "", body: actionBodyTemplate, ignoreRowClick: true, allowOverflow: true, button: true, width: "100px" },
  ];

  const filteredData = itemCategories.filter((data) =>
    Object.keys(filters).every(
      (key) => !filters[key] || (data[key] && data[key].toString().toLowerCase().includes(filters[key].toLowerCase()))
    )
  );

  const csvReport = {
    filename: "ItemCategories_Report.csv",
    headers: itemCategoryColumns.map(col => ({ label: col.name, key: col.field })),
    data: filteredData,
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleGlobalSearchChange = (e) => { // Added handleGlobalSearchChange function
    setGlobalSearch(e.target.value);
  };

  return (
    <>
      <Row className="mb-3">
        <Col md={12} className="d-flex justify-content-end align-items-center">
          <div className="input-container me-2">
            <i className="mdi mdi-magnify search-icon"></i>
            <Input
              type="text"
              value={globalSearch}
              onChange={handleGlobalSearchChange}
              className="search-input"
            />
          </div>
          <Button color="primary" id="filter" onClick={() => setIsOpenFilter(!isOpenFilter)} className="me-2">
            <i className="mdi mdi-filter"></i>
          </Button>
          <UncontrolledTooltip target="filter" placement="bottom">
            Filter
          </UncontrolledTooltip>
          <Button color="success" id="add-new" onClick={() => props.setShowItemGetCategoryList(false)} className="me-2">
            <i className="mdi mdi-plus-thick"></i> ADD
          </Button>
          <UncontrolledTooltip target="add-new" placement="bottom">
            Add New Category
          </UncontrolledTooltip>
        </Col>
      </Row>
      <Collapse isOpen={isOpenFilter}>
        <FilterSection
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          filters={filters}
          Columns={itemCategoryColumns}
          handleFilterChange={handleFilterChange}
        />
      </Collapse>

      <DataTable
        filteredData={filteredData}
        Columns={itemCategoryColumns}
        csvReport={csvReport}
        List={GetItemCategories}
        globalSearch={globalSearch}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  showItemGetCategoryList: state.ItemCategoryMaster?.showItemGetCategoryList,
});

export default connect(mapStateToProps, { setShowItemGetCategoryList })(AllItemCategories);