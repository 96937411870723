import { call, put, takeEvery, select } from "redux-saga/effects";
import { fetchStatesSuccess, fetchCitiesSuccess, CommonApiError, GetItemCategoryDropdownSuccess, GetExistingFieldsSuccess, UploadFileSuccess, RemoveUploadSuccess } from "./actions";
import axios from "axios";
import * as url from "../../helpers/url_helper";
import { toast } from "react-toastify";

// Fetch countries
function* fetchCountries({ payload: { itempayload } }) {
  try {
    const response = yield call(fetch, "https://api.countrystatecity.in/v1/countries", {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-CSCAPI-KEY': 'ajJrR1hBR1d5UHJkOHFTSkRoSTN2QWc0eUcyN2F5SGJUZWxqZXFMYw=='
      },
    });
    
    // Await response.json() using call instead
    const data = yield call([response, 'json']); // Call the json method on the response
    yield put({ type: "FETCH_COUNTRIES_SUCCESS", payload: data });
  } catch (err) {
    yield put(CommonApiError(err.message));
  }
}
// Fetch states
function* fetchStates(action) {
  debugger;
  try {
    const response = yield call(fetch, `https://api.countrystatecity.in/v1/countries/${action.payload}/states`, {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-CSCAPI-KEY': 'ajJrR1hBR1d5UHJkOHFTSkRoSTN2QWc0eUcyN2F5SGJUZWxqZXFMYw=='
      },
    });
    
    const data = yield call([response, 'json']); // Await response.json() using call
    const stateOptions = data.map(state => ({
      value: state.iso2,
      label: state.name,
    }));
    
    yield put(fetchStatesSuccess(stateOptions));
  } catch (err) {
    yield put(CommonApiError(err.message));
  }
}

// Fetch cities
function* fetchCities(action) {
  try {
    const response = yield call(fetch, `https://api.countrystatecity.in/v1/countries/IN/states/${action.payload}/cities`, {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-CSCAPI-KEY': 'ajJrR1hBR1d5UHJkOHFTSkRoSTN2QWc0eUcyN2F5SGJUZWxqZXFMYw=='
      },
    });
    
    const data = yield call([response, 'json']); // Await response.json() using call
    const cityOptions = data.map(city => ({
      value: city.iso2,
      label: city.name,
    }));
    
    yield put(fetchCitiesSuccess(cityOptions));
  } catch (err) {
    yield put(CommonApiError(err.message));
  }
}

function* GetItemCategoryDropdown(itempayload) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select((state) => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(axios.post, url.GET_CATEGORY_DROPDOWN, itempayload.payload, config);
    if (response.status >= 200 && response.status < 300) {
      // yield put(GetItemCategoryDropdownSuccess(response.data));
      yield put(GetItemCategoryDropdownSuccess({
        type: itempayload.payload.StringMapType,
        data: response.data,
      }));
    } else {
      throw new Error("Failed to fetch item categories");
    }
  } catch (err) {
    const errorMessage = err.response?.data?.message || err.response?.statusText || "An error occurred";
    yield put(CommonApiError(errorMessage));
    toast.error(errorMessage);
  }
}

function* GetExistingFields(fieldPayload) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select((state) => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(axios.post, url.GET_EXISTING_FIELDS, fieldPayload.payload, config);
    if (response.status >= 200 && response.status < 300) {
      yield put(GetExistingFieldsSuccess({
        data: response.data,
      }));
    } else {
      throw new Error("Failed to fetch item categories");
    }
  } catch (err) {
    const errorMessage = err.response?.data?.message || err.response?.statusText || "An error occurred";
    yield put(CommonApiError(errorMessage));
    toast.error(errorMessage);
  }
}

function* UploadFile({ payload: { filePayload } }) {
  debugger
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select((state) => state.Login.token)}`,
        "Content-Type": "multipart/form-data",
      },
    };
    const response = yield call(axios.post, `${url.API_URL}/upload`, filePayload, config);

    if (response.status >= 200 && response.status < 300) {
      const fileData = response.data;
      yield put(UploadFileSuccess(fileData));
    } else {
      throw new Error("Failed to upload file");
    }
  } catch (err) {
    const errorMessage = err.response?.data?.error || err.message || "An error occurred";
    yield put(CommonApiError(errorMessage));
    toast.error(errorMessage);
  }
}

function* RemoveUpload(filename) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${yield select((state) => state.Login.token)}`,
        "Content-Type": "application/json",
      },
    };
    const response = yield call(axios.delete, `${url.API_URL}/removeupload/${filename.payload}`, config);

    if (response.status >= 200 && response.status < 300) {
      yield put(RemoveUploadSuccess(filename.payload));
    } else {
      throw new Error("Failed to remove file");
    }
  } catch (err) {
    const errorMessage = err.response?.data?.error || err.message || "An error occurred";
    yield put(CommonApiError(errorMessage));
    toast.error(errorMessage);
  }
}

// Watcher saga
function* CommonAPISaga() {
  yield takeEvery("FETCH_COUNTRIES", fetchCountries);
  yield takeEvery("FETCH_STATES", fetchStates);
  yield takeEvery("FETCH_CITIES", fetchCities);
  yield takeEvery("GET_CATEGORY_DROPDOWN", GetItemCategoryDropdown);
  yield takeEvery("GET_EXISTING_FIELDS", GetExistingFields);
  yield takeEvery("UPLOAD_FILE", UploadFile);
  yield takeEvery("REMOVE_UPLOAD", RemoveUpload);
}

export default CommonAPISaga;
