import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button, Input, } from "reactstrap";
import { CSVLink } from "react-csv";

const CommonDataTable = ({ filteredData, Columns, csvReport, List, globalSearch }) => {
  // State to handle individual column filters
  const [columnFilters, setColumnFilters] = useState({});

  // Handle column-specific filter changes
  const handleFilterChange = (field, value) => {
    setColumnFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  // Apply column filters and global search to the data
  const filteredGlobalData = filteredData
    .filter((data) =>
      Columns.some((col) =>
        data[col.field]?.toString().toLowerCase().includes(globalSearch.toLowerCase())
      )
    )
    .filter((data) =>
      Object.keys(columnFilters).every(
        (field) =>
          !columnFilters[field] ||
          (data[field] && data[field].toString().toLowerCase().includes(columnFilters[field].toLowerCase()))
      )
    );

  // Generate column templates with filters
  const getColumns = () =>
    Columns.map((col, index) => (
      <Column
        key={index}
        field={col.field}
        header={col.name}
        sortable={col.sortable}
        style={{ minWidth: col.width || "100px" }}
        body={col.body}
        filter={col.filter}
        filterElement={
          col.filter && (
            <Input
              type="text"
              value={columnFilters[col.field] || ""}
              onChange={(e) => handleFilterChange(col.field, e.target.value)}
              placeholder={`Filter by ${col.name}`}
            />
          )
        }
      />
    ));


  return (
    <>
      <DataTable
        scrollable
        value={filteredGlobalData}
        scrollHeight="350px"
        scrollWidth="100%"
        paginator
        rows={10}
        totalRecords={filteredGlobalData.length}
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        rowsPerPageOptions={[10, 20, 50]}
        emptyMessage="No records found"
        paginatorLeft={<Button color="primary" className="ion ion-md-refresh" onClick={() => List()} />}
        paginatorRight={
          <CSVLink {...csvReport}>
            <Button color="primary" className="ion ion-md-download" />
          </CSVLink>
        }
      >
        {getColumns()}
      </DataTable>
    </>
  );
};

export default CommonDataTable;
