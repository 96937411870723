import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import calendarSaga from "./calendar/saga"
import tasksSaga from "./tasks/saga"
import ItemCategorySaga from "./Master/ItemCategory/saga"
import CommonAPISaga from "./Common/saga"
import CompnaySaga from "./Company/saga"
import ItemSaga from "./Master/Item/saga";
import ItemMetaDataSaga from "./Master/ItemMetaData/saga"

export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    LayoutSaga(),
    fork(calendarSaga),
    fork(tasksSaga),
    fork(tasksSaga),
    fork(ItemCategorySaga),
    fork(CommonAPISaga),
    fork(CompnaySaga),
    fork(ItemSaga),
    fork(ItemMetaDataSaga),
  ])
}
