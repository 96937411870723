import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import React, { useState, useEffect,useMemo, useRef } from 'react';
import { Button, FormGroup, Label, Input, FormFeedback, Row, Col } from 'reactstrap';
import {connect, useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Switch from 'react-switch';
import { GetItemCategoryDropdown, AddUpdateOrganization, UploadFile,GetCompanyById,ClearCompany } from 'store/actions';
import { setBreadcrumbItems } from "../../../store/actions";
import { toast } from 'react-toastify';
import { useParams, useNavigate,useLocation  } from "react-router-dom";
import axios from 'axios';
const apiUrl = process.env.REACT_APP_URL;
const apiAppUrl = process.env.REACT_APP_API_URL;

const validationSchema = Yup.object({
  Name: Yup.string().required("Please enter a name"),
  Code: Yup.string()
    .required("Please enter a Code")
    .matches(/^\d+$/, "Code must be a number"),
  // Logo: Yup.mixed().required("Please upload a logo"),
  OrganizationType: Yup.string().required("Please select Organization Type"),
  BusinessType: Yup.string().required("Please select Business Type"),
  WebsiteName: Yup.string().required("Please enter Website Name"),
});

const BasicInfo = ({ next, formData, isEditMode }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isViewMode = location.pathname.includes("/View");
  const [isOkToContact, setIsOkToContact] = useState(formData?.isOkToContact || false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const fileInputRef = useRef(null);
  const { Id } = useParams();

  const breadcrumbItems = useMemo(() => [
    { title: "Comfort" },
    { title: "Company" },
    { title: "View Company" },
  ], []);

  const organiztionType = useSelector((state) => state.CommonAPI?.organiztionType?.data?.map((org) => ({
    label: org.StringMapName,
    value: org.StringMapId,
  }))) || [];

  const businessType = useSelector((state) => state.CommonAPI?.businessType?.data?.map((business) => ({
    label: business.StringMapName,
    value: business.StringMapId,
  }))) || [];

  const [fetchedData, setFetchedData] = useState({
    Name: '',
    Code: '',
    Logo: '',
    OrganizationType: '',
    BusinessType: '',
    WebsiteName: '',
    isOkToContact: false,
    OrganizationId: ''
  });
  const [selectOrganizationType, setSelectOrganizationType] = useState(isEditMode ? formData?.OrganizationType : '');
  const [selectBusinessType, setSelectBusinessType] = useState(isEditMode ? formData?.BusinessType : '');

  const initialValues = {
    Name: '',
    Code: '',
    Logo: '',
    OrganizationType: '',
    BusinessType: '',
    WebsiteName: '',
    isOkToContact: false,
    OrganizationId: '',
  };

  var company = [];

  useEffect(() => {
    const payloadTab = {
      "TabName": "BasicInfo",
      "OrganizationId": Id
    }

    axios.post(`${apiAppUrl}/api/master/GetOrgnaizationById`, payloadTab)
      .then(response => {
        if (response.status === 200) {
          console.log('Data Retrieved Successfully:', response.data);
          company = []
          company = response.data.data
          if (company.length > 0) {
            console.log("compppp111", company)
            formData = company
            setFetchedData({
              Name: company[0].OrganizationName || '',
              Code: company[0].OrganizationCode || '',
              Logo: company[0].Logo || '',
              OrganizationType: company[0].OrganizationType || '',
              BusinessType: company[0].BusinessType || '',
              WebsiteName: company[0].Website || '',
              isOkToContact: company[0].IsOkToContact,
              OrganizationId: company[0].OrganizationId || ''
            });
            setIsOkToContact(company[0].IsOkToContact === 1 ? true : false)
            setSelectOrganizationType(company[0].OrganizationType || "")
            setSelectBusinessType(company[0].BusinessType || "")
            setFileName(company[0].Logo);
          }
        }
      })
      .catch(error => {
        console.error('Error Setting Up Request:', error);
      });

  }, [Id])

  useEffect(() => {
    dispatch(GetItemCategoryDropdown({ StringMapType: "BUSINESSTYPE", OrderBy: "StringMapName" }));
    dispatch(GetItemCategoryDropdown({ StringMapType: "ORGANIZATIONTYPE", OrderBy: "StringMapName" }));
    dispatch(GetItemCategoryDropdown({ StringMapType: "ADDRESSTYPE", OrderBy: "StringMapName" }));
    dispatch(GetItemCategoryDropdown({ StringMapType: "PHONETYPE", OrderBy: "StringMapName" }));
    dispatch(GetItemCategoryDropdown({ StringMapType: "EMAILTYPE", OrderBy: "StringMapName" }));
    dispatch(GetItemCategoryDropdown({ StringMapType: "IDENTIFIERTYPE", OrderBy: "StringMapName" }));
    dispatch(GetItemCategoryDropdown({ StringMapType: "Title", OrderBy: "StringMapName" }));
    dispatch(GetItemCategoryDropdown({ StringMapType: "PERSONTYPE", OrderBy: "StringMapName" }));
    dispatch(GetItemCategoryDropdown({ StringMapType: "DESIGNATION", OrderBy: "StringMapName" }));
    dispatch(GetItemCategoryDropdown({ StringMapType: "DEPARTMENT", OrderBy: "StringMapName" }));
    dispatch(GetItemCategoryDropdown({ StringMapType: "OrganizationList", OrderBy: "StringMapName" }));
  }, [dispatch]);

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const uniqueFile = new File([file], getUniqueFileName(file.name), { type: file.type });
      setUploadedFile(uniqueFile);
      setFileName(file.name);
    }
  };

  const handleUpload = () => {
    if (uploadedFile) {
      const filePayload = new FormData();
      filePayload.append("file", uploadedFile);
      dispatch(UploadFile(filePayload));
      // setFileName("");
      fileInputRef.current.value = "";
      formData.Logo = uploadedFile.name
      setFileName(uploadedFile.name);
    } else {
      toast.error("Please select a file to upload");
    }
  };

  const getUniqueFileName = (originalName) => {
    const timestamp = Date.now();
    const fileExtension = originalName.split('.').pop();
    const baseName = originalName.replace(/\.[^/.]+$/, "");
    return `orgLogo_${timestamp}.${fileExtension}`;
  };

  const handleSelectChange = (option, setFieldValue, fieldName) => {
    setFieldValue(fieldName, option.value);
    if (fieldName === "OrganizationType") {
      setSelectOrganizationType(option.value);
    } else if (fieldName === 'BusinessType') {
      setSelectBusinessType(option.value);
    }
  };

  const selectStyles = (hasError) => ({
    control: (provided) => ({
      ...provided,
      borderColor: hasError ? 'red' : provided.borderColor,
      boxShadow: 'none',
    }),
  });

  return (
    <Formik
      initialValues={fetchedData}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values, { setSubmitting }) => {

        console.log("values", values);

        const payload = {
          organization: {
            OrganizationId: values.OrganizationId || "",
            OrganizationName: values.Name,
            OrganizationCode: parseInt(values.Code, 10),
            Logo: fileName,
            OrganizationType: values.OrganizationType,
            BusinessType: values.BusinessType,
            IsOkToContact: values.isOkToContact ? 1 : 0,
            Website: values.WebsiteName,
          },
        };
        dispatch(AddUpdateOrganization(JSON.stringify(payload)));
        next(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, errors, touched, setFieldValue }) => (
        <Form>
          <Row>
            <Col lg={6}>
              <FormGroup>
                <Label for="name">Name<span className="text-danger">*</span></Label>
                <Field name="Name" as={Input} disabled={isViewMode} invalid={touched.Name && !!errors.Name} />
                <ErrorMessage name="Name" component={FormFeedback} />
              </FormGroup>
            </Col>
            <Col lg={6}>
              <FormGroup>
                <Label for="Code">Code<span className="text-danger">*</span></Label>
                <Field name="Code" as={Input} disabled={isViewMode} invalid={touched.Code && !!errors.Code} />
                <ErrorMessage name="Code" component={FormFeedback} />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <FormGroup>
                <Label for="Logo">Logo<span className="text-danger"></span></Label>
                <div className="d-flex align-items-center">
                  <Input name="Logo" disabled={isViewMode} type="file" onChange={handleFileChange} ref={fileInputRef} invalid={touched.Logo && !!errors.Logo} />
                  {!isViewMode && (
                    <Button color="primary" disabled={isViewMode} onClick={handleUpload} className="ms-2">Upload</Button>
                  )}
                </div>
                <ErrorMessage name="Logo" component={FormFeedback} />
                {fileName && (
                  <div className="mt-2">
                    <Label className="text-success">Uploaded File: <a style={{ color: "green" }} href={`${apiUrl}/images/${fileName}`} target="_blank" rel="noopener noreferrer">{fileName}</a></Label>
                  </div>
                )}
              </FormGroup>
            </Col>

            <Col lg={6}>
              <FormGroup>
                <Label htmlFor="OrganizationType" className="col-form-label pt-0">
                  Organization Type<span className="text-danger">*</span>
                </Label>
                <Select
                  id="OrganizationType"
                  name="OrganizationType"
                  options={organiztionType}
                  isDisabled={isViewMode}
                  value={organiztionType.find(option => option.value === selectOrganizationType)}
                  onChange={(option) => handleSelectChange(option, setFieldValue, "OrganizationType")}
                  placeholder="Select Organization Type"
                  classNamePrefix="react-select"
                  styles={selectStyles(touched.OrganizationType && errors.OrganizationType)}
                  className={touched.OrganizationType && errors.OrganizationType ? 'is-invalid' : ''}
                />
                {touched.OrganizationType && errors.OrganizationType && (
                  <FormFeedback className="d-block">{errors.OrganizationType}</FormFeedback>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <FormGroup>
                <Label htmlFor="BusinessType" className="col-form-label pt-0">
                  Business Type<span className="text-danger">*</span>
                </Label>
                <Select
                  id="BusinessType"
                  name="BusinessType"
                  isDisabled={isViewMode}
                  options={businessType}
                  value={businessType.find(option => option.value === selectBusinessType)}
                  onChange={(option) => handleSelectChange(option, setFieldValue, "BusinessType")}
                  placeholder="Select Business Type"
                  classNamePrefix="react-select"
                  styles={selectStyles(touched.BusinessType && errors.BusinessType)}
                  className={touched.BusinessType && errors.BusinessType ? 'is-invalid' : ''}
                />
                {touched.BusinessType && errors.BusinessType && (
                  <FormFeedback className="d-block">{errors.BusinessType}</FormFeedback>
                )}
              </FormGroup>
            </Col>

            <Col lg={6}>
              <FormGroup>
                <Label for="WebsiteName">Website Name<span className="text-danger">*</span></Label>
                <Field name="WebsiteName" as={Input} disabled={isViewMode} invalid={touched.WebsiteName && !!errors.WebsiteName} />
                <ErrorMessage name="WebsiteName" component={FormFeedback} />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <FormGroup className="d-flex align-items-center gap-3">
                <Label className="col-form-label mr-3">Is Ok To Contact?</Label>
                <Switch
                  checked={isOkToContact}
                  onChange={(checked) => {
                    setIsOkToContact(checked);
                    setFieldValue("isOkToContact", checked);
                  }}
                  disabled={isViewMode}
                  onColor="#4CAF50"
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </FormGroup>
            </Col>
          </Row>
          {!isViewMode && (
            <Row className="justify-content-end">
              <Col lg={3} className="text-end">
                <Button type="submit" color="primary" disabled={isSubmitting} className="large-button">
                  Save & Next
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default connect(null, { setBreadcrumbItems })(BasicInfo);
