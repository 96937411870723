// Add/Update Item Metadata Action
export const AddUpdateItemMetadata = (itempayload) => {
  return {
    type: "ADD_UPDATE_ITEM_METADATA",
    payload:  itempayload ,
  };
};

// Success for Add/Update Item Metadata
export const AddUpdateItemMetadataSuccess = (itempayload) => {
  return {
    type: "ADD_UPDATE_ITEM_METADATA_SUCCESS",
    payload: itempayload,
  };
};

export const setShowItemGetMetadataList = (showItemGetMetadataList) => {
  return {
    type: "SET_SHOW_ITEM_METADATA_LIST",
    payload: showItemGetMetadataList,
  }
};

// Get Item Metadata Action
export const GetItemMetadata = () => {
  return {
    type: "GET_ITEM_METADATA",
  };
};

// Success for Get Item Metadata
export const GetItemMetadataSuccess = (itempayload) => {
  return {
    type: "GET_ITEM_METADATA_SUCCESS",
    payload: itempayload,
  };
};

 // Get Meta data by Id Action
 export const GetMetaDataById = (id) => {
  return {
    type: "GET_META_DATA_ID",
    payload: { id },
  };
};

// Success for Get Item Metadata
export const GetMetadataIdSuccess = (itempayload) => {
  return {
    type: "GET_METADATA_ID_SUCCESS",
    payload: itempayload,
  };
};

// Action for API error
export const ItemMetadataAPIError = (error) => {
  return {
    type: "ITEM_METADATA_API_ERROR",
    payload: error,
  };
};

// Get Item Metadata Action
export const GetMetaDataItems = () => {
  return {
    type: "GET_ALL_METADATA_ITEMS",
  };
};

// Success for Get Item Metadata
export const GetMetaDataItemsSuccess = (metaDataListing) => {
  console.log("from action", metaDataListing);
  return {
    type: "GET_ALL_METADATA_ITEMS_SUCCESS",
    payload: metaDataListing,
  };
};

// // Action for API error
// export const ItemCategoryAPIError = (error) => {
//   return {
//     type: "ITEM_CATEGORY_API_ERROR",
//     payload: error,
//   };
// };
