
export const loginUser = (user, history) => {
  debugger;
  return {
    type: "LOGIN_USER",
    payload: { user, history },
  }
}

export const loginSuccess = (user, token) => {
  return {
    type: "LOGIN_SUCCESS",
    payload: { user, token }, 
  };
};

export const logoutUser = history => {
  return {
    type: "LOGOUT_USER",
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: "LOGOUT_USER_SUCCESS",
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: "API_ERROR",
    payload: error,
  }
}
