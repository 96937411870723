import React, { useEffect ,useMemo} from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { setBreadcrumbItems,setShowCompanyList } from "../../store/actions";
import AllCompanies from "./AllCompanies";
import AddCompany from "./AddCompany";

const CompanyMaster = (props) => {
  document.title = "CompanyMaster | Cognisun - Responsive Bootstrap 5 Admin Dashboard";

  const breadcrumbItems = useMemo(() => [
    { title: "Comfort Zone" },
    { title: "CompanyMaster" },
    { title: props.showCompanyList ? "Organizations" : "Add New Organization" },
  ], [props.showCompanyList]);

  useEffect(() => {
    props.setBreadcrumbItems("CompanyMaster", breadcrumbItems);
  }, [props, breadcrumbItems]);

  return (
    <React.Fragment>
      <Row>
        {!props.showCompanyList && (
          <Col xl={12} className="d-flex justify-content-end mb-3">
            <button
              type="button"
              className="btn btn-info"
              onClick={() => {
                props.setShowCompanyList(true);
              }}
            >
              Show Company List
            </button>
          </Col>
        )}
      </Row>
      <Row>
        <Col xl={12}>
          {props.showCompanyList ? <AllCompanies /> : <AddCompany />}
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  console.log('Redux State:', state);
  return {
    showCompanyList: state.CompnayAPI?.showCompanyList,
  };
};

export default connect(mapStateToProps, { setBreadcrumbItems, setShowCompanyList })(CompanyMaster);
