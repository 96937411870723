import { call, put, takeEvery } from "redux-saga/effects";
import { persistor } from '../../../store';
import { toast } from "react-toastify"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";
import axios from "axios"
import * as url from "../../../helpers/url_helper";

function* loginUser({ payload: { user, history } }) {
  debugger;
  try {
    const response = yield call(axios.post, url.POST_LOGIN, {
      LoginId: user.email,
      Password: user.password,
    });
    if (response.status >= 200 && response.status < 300) {
      localStorage.setItem("authUser", JSON.stringify(response.data.data[0]));
      yield put(loginSuccess(response.data.data[0], response.data.token)); 
      toast.success(`Welcome ${response.data.data[0].FirstName ? `${response.data.data[0].FirstName} ${response.data.data[0].LastName}` : 'User'}`);
      history('/dashboard');
    } else {
      toast.error(response.data.errorMessage);
      throw new Error('Unexpected response status');
    }
  } catch (err) {
    const errorMessage =  err.response.data?.error || err.response.statusText || "An error occurred";
    yield put(apiError(errorMessage));
  }
}


function* logoutUser({ payload: { history } }) {
  try {
      yield put(logoutUserSuccess(true));
      localStorage.removeItem("authUser");
      persistor.purge();
    history('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}



function* authSaga() {
  yield takeEvery("LOGIN_USER", loginUser);
  yield takeEvery("LOGOUT_USER", logoutUser);
}

export default authSaga;
