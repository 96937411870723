const initialState = {
  error: "",
  loading: false,
  data: null,
  dropdownData: [],
  metaDataById: [], 
  // metaData: [], // To store added/updated metadata response
  showItemMetadataList: true, // To control metadata list visibility
  metaDataListing: null,
};

const AddUpdateItemMetadata = (state = initialState, action) => {

  switch (action.type) {
    case "ADD_UPDATE_ITEM_METADATA":
      return {
        ...state,
        loading: true,
      };

    case "ADD_UPDATE_ITEM_METADATA_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };

    case "SET_SHOW_ITEM_METADATA_LIST":
      return {
        loading: true,
        showItemMetadataList: action.payload
      };

    case "ITEM_METADATA_API_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case "GET_METADATA_ID_SUCCESS":
      return {
        ...state,
        loading: false,
        metaDataById: action.payload,
        error: "",
      };


    case "ITEM_API_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "GET_ALL_METADATA_ITEMS_SUCCESS":
      console.log('Updated state with metadata items:', action.payload);
      console.log('State after update:', state);
      return {
        ...state,
        loading: false,
        metaDataListing: action.payload,
        error: "",
      };

    default:
      return state;
  }
};

export default AddUpdateItemMetadata;
