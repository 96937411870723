import React, { useEffect, useMemo } from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { setBreadcrumbItems, setShowItemGetCategoryList } from "../../../store/actions";
import AddItem from "./AddItemCategory";
import ItemList from "./AllItemCategories";

const Itemcategory = (props) => {
  document.title = "ItemCategory | Cognisun - Responsive Bootstrap 5 Admin Dashboard";

  const breadcrumbItems = useMemo(() => [
    { title: "Comfort Zone" },
    { title: "ItemCategory" },
    { title: props.showItemGetCategoryList ? "List" : "Add" },
  ], [props.showItemGetCategoryList]);

  useEffect(() => {
    props.setBreadcrumbItems("ItemCategory", breadcrumbItems);
  }, [props, breadcrumbItems]);

  return (
    <Row>
      <Col xl={12}>
        {props.showItemGetCategoryList ? <ItemList /> : <AddItem />}
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  showItemGetCategoryList: state.ItemCategoryMaster?.showItemGetCategoryList,
});

export default connect(mapStateToProps, { setBreadcrumbItems, setShowItemGetCategoryList })(Itemcategory);
