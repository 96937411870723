import React, { useEffect, useMemo } from "react";
import { Row, Col, Label, FormFeedback, Form, Input } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import { AddUpdateItem, GetItemById } from "../../../store/Master/Item/actions";
import { useParams, useNavigate } from "react-router-dom";
import { setBreadcrumbItems } from "../../../store/actions";
import moment from "moment-timezone"

const ViewItem = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Id } = useParams();
    const itemData = useSelector((state) => state.ItemMaster?.data) || {};

    const breadcrumbItems = useMemo(() => [
        { title: "Comfort Zone" },
        { title: "Item" },
        { title: "View Item" },
    ], []);

    useEffect(() => {
        props.setBreadcrumbItems('View Item', breadcrumbItems);
        if (Id) {
            dispatch(GetItemById(Id));
        }
    }, [Id, dispatch, breadcrumbItems, props]);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            Category: itemData?.data?.[0].ItemCategoryName || "",
            SubCategory: itemData?.data?.[0].SubCategoryName || "",
            ItemType1: itemData?.data?.[0].ItemType1Name || "",
            ItemType2: itemData?.data?.[0].ItemType2Name || "",
            ItemType3: itemData?.data?.[0].ItemType3Name || "",
            SystemItemName: itemData?.data?.[0].SystemItemName || "",
            ItemName: itemData?.data?.[0].ItemName || "",
            ItemCode: itemData?.data?.[0].ItemCode || "",
            Dimentions: itemData?.data?.[0].Dimentions || "",
            HSNCode: itemData?.data?.[0].HSNCode || "",
            Rate: itemData?.data?.[0].Rate || "",
            UnitofMeasurement: itemData?.data?.[0].UnitofMeasurementName || "",
            CreatedByName: itemData?.data?.[0].CreatedByName || "",
            CreatedOn: itemData?.data?.[0].CreatedOn ? moment.tz(itemData?.data?.[0].CreatedOn, "America/New_York").tz("Asia/Kolkata").format("DD MMMM YYYY, hh:mm A") : "",
            UpdatedByName: itemData?.data?.[0].UpdatedByName || "",
            UpdatedOn: itemData?.data?.[0].UpdatedOn ? moment.tz(itemData?.data?.[0].UpdatedOn, "America/New_York").tz("Asia/Kolkata").format("DD MMMM YYYY, hh:mm A") : "",
        },
        validationSchema: Yup.object({
            ItemName: Yup.string().required("Please Enter Item Category Name"),
            Description: Yup.string(),
        }),
        onSubmit: async (values) => {
            dispatch(AddUpdateItem(values));
        }
    });

    return (
        <React.Fragment>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                {itemData?.data?.length > 0 ? (
                    <>
                        <Row>
                            <Col lg={6} className="mb-2">
                                <div className="form-group">
                                    <Label htmlFor="Category" className="col-form-label">
                                        Item Category<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        id="Category"
                                        name="Category"
                                        type="text"
                                        className="form-control validate"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.Category}
                                        disabled
                                        invalid={
                                            validation.touched.Category && validation.errors.Category
                                                ? true
                                                : false
                                        }
                                    />

                                    {validation.touched.Category && validation.errors.Category ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.Category}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6} className="mb-2">
                                <div className="form-group">
                                    <Label htmlFor="SubCategory" className="col-form-label">
                                        Item Sub Category<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        id="SubCategory"
                                        name="SubCategory"
                                        type="text"
                                        className="form-control validate"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.SubCategory}
                                        disabled
                                        invalid={
                                            validation.touched.SubCategory && validation.errors.SubCategory
                                                ? true
                                                : false
                                        }
                                    />

                                    {validation.touched.SubCategory && validation.errors.SubCategory ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.SubCategory}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4} className="mb-2">
                                <div className="form-group">
                                    <Label htmlFor="ItemType1" className="col-form-label">
                                        Item Type 1<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        id="ItemType1"
                                        name="ItemType1"
                                        type="text"
                                        className="form-control validate"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.ItemType1}
                                        disabled
                                        invalid={
                                            validation.touched.ItemType1 && validation.errors.ItemType1
                                                ? true
                                                : false
                                        }
                                    />

                                    {validation.touched.ItemType1 && validation.errors.ItemType1 ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.ItemType1}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={4} className="mb-2">
                                <div className="form-group">
                                    <Label htmlFor="ItemType2" className="col-form-label">
                                        Item Type 2<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        id="ItemType2"
                                        name="ItemType2"
                                        type="text"
                                        className="form-control validate"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.ItemType2}
                                        disabled
                                        invalid={
                                            validation.touched.ItemType2 && validation.errors.ItemType2
                                                ? true
                                                : false
                                        }
                                    />

                                    {validation.touched.ItemType2 && validation.errors.ItemType2 ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.ItemType2}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={4} className="mb-2">
                                <div className="form-group">
                                    <Label htmlFor="ItemType3" className="col-form-label">
                                        Item Type 3<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        id="ItemType3"
                                        name="ItemType3"
                                        type="text"
                                        className="form-control validate"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.ItemType3}
                                        disabled
                                        invalid={
                                            validation.touched.ItemType3 && validation.errors.ItemType3
                                                ? true
                                                : false
                                        }
                                    />

                                    {validation.touched.ItemType3 && validation.errors.ItemType3 ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.ItemType3}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>

                        </Row>
                        <Row>
                            <Col lg={12} className="mb-2 mt-2">
                                <div className="form-group">
                                    <Label htmlFor="SystemItemName" className="col-form-label">
                                        System Item Name: <span className="text-secondary">{validation.values.SystemItemName}</span>
                                    </Label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} className="mb-2">
                                <div className="form-group">
                                    <Label htmlFor="ItemName" className="col-form-label">
                                        Item Name (Unique)<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        id="ItemName"
                                        name="ItemName"
                                        type="text"
                                        className="form-control validate"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.ItemName}
                                        disabled
                                        invalid={
                                            validation.touched.ItemName && validation.errors.ItemName
                                                ? true
                                                : false
                                        } />

                                    {validation.touched.ItemName && validation.errors.ItemName ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.ItemName}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6} className="mb-2">
                                <div className="form-group">
                                    <Label htmlFor="ItemCode" className="col-form-label">
                                        Item Code (Unique)<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        id="ItemCode"
                                        name="ItemCode"
                                        type="text"
                                        className="form-control validate"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.ItemCode}
                                        disabled
                                        invalid={
                                            validation.touched.ItemCode && validation.errors.ItemCode
                                                ? true
                                                : false
                                        } />

                                    {validation.touched.ItemCode && validation.errors.ItemCode ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.ItemCode}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} className="mb-2">
                                <div className="form-group">
                                    <Label htmlFor="Dimentions" className="col-form-label">
                                        Dimensions<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        id="Dimentions"
                                        name="Dimentions"
                                        type="text"
                                        className="form-control validate"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.Dimentions}
                                        disabled
                                        invalid={
                                            validation.touched.Dimentions && validation.errors.Dimentions
                                                ? true
                                                : false
                                        } />

                                    {validation.touched.Dimentions && validation.errors.Dimentions ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.Dimentions}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6} className="mb-2">
                                <div className="form-group">
                                    <Label htmlFor="HSNCode" className="col-form-label">
                                        HSN Code
                                    </Label>
                                    <Input
                                        id="HSNCode"
                                        name="HSNCode"
                                        type="text"
                                        className="form-control validate"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.HSNCode}
                                        disabled
                                        invalid={
                                            validation.touched.HSNCode && validation.errors.HSNCode
                                                ? true
                                                : false
                                        } />

                                    {validation.touched.HSNCode && validation.errors.HSNCode ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.HSNCode}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} className="mb-2">
                                <div className="form-group">
                                    <Label htmlFor="Rate" className="col-form-label">
                                        Rate<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        id="Rate"
                                        name="Rate"
                                        type="text"
                                        className="form-control validate"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.Rate}
                                        disabled
                                        invalid={
                                            validation.touched.Rate && validation.errors.Rate
                                                ? true
                                                : false
                                        } />

                                    {validation.touched.Rate && validation.errors.Rate ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.Rate}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6} className="mb-2">
                                <div className="form-group">
                                    <Label htmlFor="UnitofMeasurement" className="col-form-label">
                                        Unit of Measurement<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        id="UnitofMeasurement"
                                        name="UnitofMeasurement"
                                        type="text"
                                        className="form-control validate"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.UnitofMeasurement}
                                        disabled
                                        invalid={
                                            validation.touched.UnitofMeasurement && validation.errors.UnitofMeasurement
                                                ? true
                                                : false
                                        } />

                                    {validation.touched.UnitofMeasurement && validation.errors.UnitofMeasurement ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.UnitofMeasurement}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <Row>
                        <Col>
                            <p>No data was found.</p>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col lg={10}>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            id="Back"
                            onClick={() => navigate("/Master/Item")}
                        >
                            Back to List
                        </button>
                    </Col>
                </Row>
            </Form>
            <Row className="d-flex justify-content-between flex-column flex-lg-row mt-4">
                <Col lg={6} className="mt-4">
                    <p className="mb-1"><strong>Created By:</strong> {validation.values.CreatedByName}</p>
                    <p className="mb-1"><strong>Created On:</strong> {validation.values.CreatedOn}</p>
                </Col>
                <Col lg={6} className="">
                    {(validation.values.UpdatedByName || validation.values.UpdatedOn) && (
                        <>
                            <p className="mb-1"><strong>Last Updated By:</strong> {validation.values.UpdatedByName}</p>
                            <p className="mb-1"><strong>Last Updated On:</strong> {validation.values.UpdatedOn}</p>
                        </>
                    )}
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default connect(null, { setBreadcrumbItems })(ViewItem);
