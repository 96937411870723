const initialState = {
  error: "",
  loading: false,
  categories: [], 
  data: null,
  showItemGetCategoryList: true,
};

const AddUpdateItemCategory = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_UPDATE_ITEM_CATEGORY":
    case "GET_ALL_ITEM_CATEGORIES":
      return {
        ...state,
        loading: true,
      };

    case "ADD_UPDATE_ITEM_CATEGORY_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload, // Store the add/update category response
        error: "",
      };

    case "GET_ALL_ITEM_CATEGORIES_SUCCESS":
      return {
        ...state,
        loading: false,
        categories: action.payload, // Store the fetched categories
        error: "",
      };
      
    case "GET_ITEM_CATEGORY":
      return {
        ...state,
        loading: false,
        category: action.payload, // Store the fetched categories
        error: "",
      };

    case "GET_ITEM_CATEGORY_SUCCESS": 
    return {
      ...state,
      loading: false,
      category: action.payload, // Store the fetched category
      error: "",
    };
    case "ITEM_CATEGORY_API_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "SET_SHOW_ITEM_CATERGORY_LIST":
      return {
        ...state,
        showItemGetCategoryList: action.payload,
      };
    default:
      return state;
  }
};

export default AddUpdateItemCategory;
