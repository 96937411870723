import React, { useState, useRef,useEffect } from "react";
import { Row, Col, Label, Table, FormFeedback, Form, Input, Button, FormGroup } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { AddUpdateOrganization, UploadFile, RemoveUpload, ClearFilePath } from "store/actions";
import { toast } from "react-toastify";
import { useParams, useNavigate,useLocation } from "react-router-dom";
import axios from 'axios';
const apiUrl = process.env.REACT_APP_URL;
const apiAppUrl = process.env.REACT_APP_API_URL;

const DocumentForm = ({ previous, next, formData, isEditMode, activeTab }) => {
  const dispatch = useDispatch();
  const organization = useSelector((state) => state.CompnayAPI?.data) || [];
  const fileData = useSelector((state) => state.CommonAPI?.fileData) || "";

  const [entries, setEntries] = useState([]);
  const [editIndex, setEditIndex] = useState(null);

  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const fileInputRef = useRef(null);

  const [documents, setDocuments] = useState([]);

  const location = useLocation();
  const isViewMode = location.pathname.includes("/View"); 

  const initialValues = isEditMode && formData ? formData : {
    DocumentType: '',
    DocumentFolder: '',
    DocumentName: '',
    File: null,
    DocumentId: ''
  };

  const { Id } = useParams();
  var company = [];

  useEffect(() => {
    if (activeTab == 5) {
      const payloadTab = {
        "TabName": "Documents",
        "OrganizationId": Id
      }
      axios.post(`${apiAppUrl}/api/master/GetOrgnaizationById`, payloadTab)
        .then(response => {
          if (response.status === 200) {
            console.log('Data Retrieved Successfully:', response.data);
            company = []
            company = response.data.data
            if (company) {
              console.log("DocumentData:", company)
              setEntries(company)
            }
          }
        })
        .catch(error => {
          console.error('Error Setting Up Request:', error);
        });
    }
  }, [Id, activeTab])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      DocumentType: Yup.string().required("Please enter Document Type"),
      DocumentFolder: Yup.string().required("Please enter Document Folder"),
      DocumentName: Yup.string().required("Please enter Document Name"),
      File: Yup.mixed().required("Please upload a file"),
    }),
    onSubmit: (values) => {

      if (!uploadedFile) {
        toast.error("Please upload a file before adding an Document");
        return;
      }

      if (editIndex !== null) {
        const updatedEntries = [...entries];
        updatedEntries[editIndex] = values;
        setEntries(updatedEntries);
        setEditIndex(null);
      } else {
        setEntries([...entries, values]);
      }

      validation.resetForm();
      setFileName("");
    },
  });

  const handleEdit = (index) => {
    const entryToEdit = entries[index];
    setEditIndex(index);
    validation.setValues(entryToEdit);
  };

  const handleDelete = (index) => {
    const entryToDelete = entries[index];

    if (entryToDelete?.UploadedDocument?.name) {
      try {
        dispatch(RemoveUpload(entryToDelete.UploadedDocument.name));
        setEntries(entries.filter((_, i) => i !== index));
        toast.success("Entry and file deleted successfully!");
      } catch (error) {
        toast.error("Failed to delete the file. Please try again.");
      }
    } else {
      toast.success("Entry deleted successfully!");
      setEntries(entries.filter((_, i) => i !== index));
    };
  }

  const handleFinalSubmit = () => {
    const OrganizationId = Id;
    const finalArray = entries.map(item => ({
      ...item,
      OrganizationId: OrganizationId
    }));
    const payload = {
      "Document": finalArray
    };
    const finalPayload = transformDocumentData(payload);
    function transformDocumentData(values) {
      const transformedDocument = values.Document.map((item) => {
        return {
          OrganizationId: item.OrganizationId,
          DocumentType: item.DocumentType,
          DocumentFolder: item.DocumentFolder,
          DocumentName: item.DocumentName,
          URL: item.File || '',
          DocumentId: item.DocumentId || ''
        };
      });
      const payload = {
        DocumentInfo: {
          Document: transformedDocument,
        },
      };
      return payload;
    }
    dispatch(AddUpdateOrganization(finalPayload));
    next(formData);
  };

  const getUniqueFileName = (originalName) => {
    const timestamp = Date.now();
    const fileExtension = originalName.split('.').pop();
    const baseName = originalName.replace(/\.[^/.]+$/, "");
    return `org_document_${timestamp}.${fileExtension}`;
  };

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const uniqueFile = new File([file], getUniqueFileName(file?.name), { type: file.type });
      setUploadedFile(uniqueFile);
    }
  };

  const handleUpload = () => {
    if (uploadedFile) {
      const filePayload = new FormData();
      filePayload.append("file", uploadedFile);
      dispatch(UploadFile(filePayload))

      fileInputRef.current.value = "";
      validation.setFieldValue("File", uploadedFile?.name);
    } else {
      toast.error("Please select a file to upload");
    }
  };

  const selectStyles = (hasError) => ({
    control: (provided) => ({
      ...provided,
      borderColor: hasError ? 'red' : provided.borderColor,
      boxShadow: 'none',
      '&:hover': {
        borderColor: hasError ? 'red' : provided['&:hover'].borderColor,
      },
    }),
  });

  useEffect(() => {
    if (fileData.filename) {
      setFileName(fileData.filename);
      toast.success(`File Path URL: ${fileData.filename}`);
      dispatch(ClearFilePath());
    }
  }, [fileData.filename]);

  return (
    <React.Fragment>
      
      <Form 
       onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
      >
        {!isViewMode && (
          <>
            <div>
              <Row>
                <Col lg={6}>
                  <FormGroup>
                    <Label htmlFor="DocumentType">Document Type<span className="text-danger">*</span></Label>
                    <Input
                      id="DocumentType"
                      name="DocumentType"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.DocumentType}
                      invalid={validation.touched.DocumentType && validation.errors.DocumentType}
                    />
                    {validation.touched.DocumentType && validation.errors.DocumentType && (
                      <FormFeedback>{validation.errors.DocumentType}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label htmlFor="DocumentFolder">Document Folder<span className="text-danger">*</span></Label>
                    <Input
                      id="DocumentFolder"
                      name="DocumentFolder"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.DocumentFolder}
                      invalid={validation.touched.DocumentFolder && validation.errors.DocumentFolder}
                    />
                    {validation.touched.DocumentFolder && validation.errors.DocumentFolder && (
                      <FormFeedback>{validation.errors.DocumentFolder}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <FormGroup>
                    <Label htmlFor="DocumentName">Document Name<span className="text-danger">*</span></Label>
                    <Input
                      id="DocumentName"
                      name="DocumentName"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.DocumentName}
                      invalid={validation.touched.DocumentName && validation.errors.DocumentName}
                    />
                    {validation.touched.DocumentName && validation.errors.DocumentName && (
                      <FormFeedback>{validation.errors.DocumentName}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label htmlFor="File">Upload File<span className="text-danger">*</span></Label>
                    <div className="d-flex align-items-center">
                      <Input
                        id="File"
                        name="File"
                        type="file"
                        innerRef={fileInputRef}
                        onChange={handleFileChange}
                        onBlur={validation.handleBlur}
                        invalid={validation.touched.File && !!validation.errors.File}
                      />
                      <Button className="ms-2" color="primary" onClick={handleUpload}>
                        Upload
                      </Button>
                    </div>
                    {(fileName || editIndex !== null) && (
                      <div className="mt-2">
                        <Label className="text-success">Uploaded File: <a style={{ color: "green" }} href={`${apiUrl}/images/${fileName}`} target="_blank" rel="noopener noreferrer">{fileName}</a></Label>
                      </div>
                    )}
                    <FormFeedback type="invalid" className="d-block">{validation.errors.File}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <Row className="mb-3">
              <Col className="d-flex justify-content-end" lg={12}>
                <button type="submit" className="btn btn-primary">
                  {editIndex !== null ? "Update Document" : "Add Document"}
                </button>
              </Col>
            </Row>
          </>
        )}

        {entries.length > 0 && (
          <Table bordered>
            <thead>
              <tr>
                <th>Document Type</th>
                <th>Document Name</th>
                <th>Document Folder</th>
                <th>Document File</th>
                {!isViewMode && (
                  <th>Actions</th>
                )}
              </tr>
            </thead>
            <tbody>
              {entries.map((document, index) => (
                <tr key={index}>
                  <td>{document.DocumentType}</td>
                  <td>{document.DocumentName}</td>
                  <td>{document.DocumentFolder}</td>
                  <td>{document.URL ? document.URL : document.File}</td>
                  {!isViewMode && (
                    <td className="d-flex justify-content-between">
                      <Button className="mr-2" onClick={() => handleEdit(index)}>
                        Edit
                      </Button>
                      <Button color="danger" onClick={() => handleDelete(index)}>
                        Delete
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {!isViewMode && ( 
        <Row className="justify-content-between mt-4">
          <Col className="d-flex justify-content-start">
            <button type="button" onClick={previous} className="btn btn-secondary large-button">
              Previous
            </button>
          </Col>
          <Col className="d-flex justify-content-end">
            <button type="submit" onClick={handleFinalSubmit} className="btn btn-primary large-button">
              Save & Next
            </button>
          </Col>
        </Row>
        )}
      </Form>
    </React.Fragment>
  );
};

export default DocumentForm;
