// Add/Update Item Action
export const AddUpdateItem = (itemPayload) => {
    return {
      type: "ADD_UPDATE_ITEM",
      payload: { itemPayload },
    };
  };
  
  // Success for Add/Update Item
  export const AddUpdateItemSuccess = (itemPayload) => {
    return {
      type: "ADD_UPDATE_ITEM_SUCCESS",
      payload: itemPayload,
    };
  };

  export const setShowItemGetList = (showItemGetList) => ({
    type: "SET_SHOW_ITEM_LIST",
    payload: showItemGetList,
  });
  
  // Get Items Action
  export const GetItems = () => {
    return {
      type: "GET_ALL_ITEMS",
    };
  };
  
  // Success for Get Items
  export const GetItemsSuccess = (itemPayload) => {
    return {
      type: "GET_ALL_ITEMS_SUCCESS",
      payload: itemPayload,
    };
  };
  
  // Get Item by ID Action
  export const GetItemById = (id) => {
    return {
      type: "GET_ITEM",
      payload: { id },
    };
  };
  
  // Success for Get Item by ID
  export const GetItemSuccess = (itemPayload) => {
    return {
      type: "GET_ITEM_SUCCESS",
      payload: itemPayload,
    };
  };
  
  // API Error
  export const ItemAPIError = (error) => {
    return {
      type: "ITEM_API_ERROR",
      payload: error,
    };
  };
  