import React, { useState, useEffect } from "react";
import { Row, Col, Label, FormFeedback, Form, Input } from "reactstrap";
import Select from 'react-select';
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import { AddUpdateItem, GetExistingFields, GetItemCategoryDropdown, setShowItemGetList } from "../../../store/actions";

const AddItem = (props) => {
    const dispatch = useDispatch();
    const itemCategories = useSelector((state) => state.CommonAPI?.itemCategories?.data) || [];
    const itemSubCategories = useSelector((state) => state.CommonAPI?.itemSubCategories?.data) || [];
    const itemTypeList1 = useSelector((state) => state.CommonAPI?.itemType1?.data) || [];
    const itemTypeList2 = useSelector((state) => state.CommonAPI?.itemType2?.data) || [];
    const itemTypeList3 = useSelector((state) => state.CommonAPI?.itemType3?.data) || [];
    const unitofMesurementList = useSelector((state) => state.CommonAPI?.unitList?.data) || [];
    const existingFields = useSelector((state) => state.CommonAPI?.itemExistingFields?.data) || [];
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [selectedItemType1, setSelectedItemType1] = useState(null);
    const [selectedItemType2, setSelectedItemType2] = useState(null);
    const [selectedItemType3, setSelectedItemType3] = useState(null);
    const [selectedItemUnit, setSelectedItemUnit] = useState(null);
    const [filteredSubCategories, setFilteredSubCategories] = useState([]);
    const [filteredItemType1, setFilteredItemType1] = useState([]);
    const [filteredItemType2, setFilteredItemType2] = useState([]);
    const [filteredItemType3, setFilteredItemType3] = useState([]);
    const [filteredUnit, setFilteredUnit] = useState([]);

    useEffect(() => {
        dispatch(GetItemCategoryDropdown({ "StringMapType": "ItemCategory", "OrderBy": "StringMapName" }));
        dispatch(GetItemCategoryDropdown({ "StringMapType": "ItemSubCategory", "OrderBy": "StringMapName" }));
        dispatch(GetItemCategoryDropdown({ "StringMapType": "ItemType1", "OrderBy": "StringMapName" }));
        dispatch(GetItemCategoryDropdown({ "StringMapType": "ItemType2", "OrderBy": "StringMapName" }));
        dispatch(GetItemCategoryDropdown({ "StringMapType": "ItemType3", "OrderBy": "StringMapName" }));
        dispatch(GetItemCategoryDropdown({ "StringMapType": "UnitofMesurement", "OrderBy": "StringMapName" }));
        dispatch(GetExistingFields({ "pColumns": "ItemName,ItemCode", "pTableName": "Item" }));
    }, [dispatch])

    useEffect(() => {
        if (selectedCategory) {
            const filteredSub = itemSubCategories.filter(
                (sub) => sub.CategoryId === selectedCategory.value
            );
            setFilteredSubCategories(filteredSub);

            const filteredType1 = itemTypeList1.filter(
                (type) => type.CategoryId === selectedCategory.value
            );
            setFilteredItemType1(filteredType1);

            const filteredType2 = itemTypeList2.filter(
                (type) => type.CategoryId === selectedCategory.value
            );
            setFilteredItemType2(filteredType2);

            const filteredType3 = itemTypeList3.filter(
                (type) => type.CategoryId === selectedCategory.value
            );
            setFilteredItemType3(filteredType3);

            const filteredUnit = unitofMesurementList.filter(
                (type) => type.CategoryId === selectedCategory.value
            );
            setFilteredUnit(filteredUnit);
        } else {
            setFilteredSubCategories([]);
            setFilteredItemType1([]);
            setFilteredItemType2([]);
            setFilteredItemType3([]);
            setFilteredUnit([]);
        }
    }, [selectedCategory, itemSubCategories, itemTypeList1, itemTypeList2, itemTypeList3, unitofMesurementList]);

    useEffect(() => {
        const categoryValue = selectedCategory ? selectedCategory.label.replace(/\s+/g, '') : '';
        const subCategoryValue = selectedSubCategory ? selectedSubCategory.label.replace(/\s+/g, '') : '';
        const itemType1Value = selectedItemType1 ? selectedItemType1.label.replace(/\s+/g, '') : '';
        const itemType2Value = selectedItemType2 ? selectedItemType2.label.replace(/\s+/g, '') : '';
        const itemType3Value = selectedItemType3 ? selectedItemType3.label.replace(/\s+/g, '') : '';

        const systemName = [categoryValue, subCategoryValue, itemType1Value, itemType2Value, itemType3Value]
            .filter(Boolean)
            .join('_');

        validation.setFieldValue('SystemItemName', systemName);
    }, [selectedCategory, selectedSubCategory, selectedItemType1, selectedItemType2, selectedItemType3]);


    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            Category: '',
            SubCategory: '',
            ItemType1: '',
            ItemType2: '',
            ItemType3: '',
            SystemItemName: '',
            ItemName: '',
            ItemCode: '',
            Dimentions: '',
            HSNCode: '',
            Rate: '',
            UnitofMeasurement: ''
        },

        validationSchema: Yup.object({
            Category: Yup.string().required("Please Select Item Category"),
            SubCategory: Yup.string().required("Please Select Item Sub Category"),
            ItemType1: Yup.string().required("Please Select Item Type 1"),
            ItemType2: filteredItemType2.length > 0 ? Yup.string().required("Please Select Item Type 2") : Yup.string(),
            ItemType3: filteredItemType3.length > 0 ? Yup.string().required("Please Select Item Type 3") : Yup.string(),
            SystemItemName: Yup.string().required("Please Enter System Item Name"),
            ItemName: Yup.string()
                .required("Please Enter Item Name")
                .test(
                    "unique-ItemName",
                    "This Item Name already taken",
                    function (value) {
                        const existingItemNames = existingFields.map((item) => item.ItemName);
                        return !existingItemNames.includes(value);
                    }
                ),
            ItemCode: Yup.string()
                .required("Please Enter Item Code")
                .test(
                    "unique-ItemCode",
                    "This Item Code already taken",
                    function (value) {
                        const existingItemCodes = existingFields.map((item) => item.ItemCode);
                        return !existingItemCodes.includes(value);
                    }
                ),
            Dimentions: Yup.string().required("Please Enter Dimentions"),
            HSNCode: Yup.string(),
            Rate: Yup.string().required("Please Enter Rate"),
            UnitofMeasurement: Yup.string().required("Please Enter UnitofMeasurement"),
        }),
        onSubmit: async (values) => {
            dispatch(AddUpdateItem(values));
        }
    });

    const handleSelectChange = (selectedOption, field) => {
        switch (selectedOption) {
            case 'Category':
                setSelectedCategory(field);
                validation.setFieldValue('Category', field.value);
                setSelectedSubCategory(null);
                setSelectedItemType1(null);
                setSelectedItemType2(null);
                setSelectedItemType3(null);
                setSelectedItemUnit(null);
                validation.setFieldValue('SubCategory', '');
                validation.setFieldValue('ItemType1', '');
                validation.setFieldValue('ItemType2', '');
                validation.setFieldValue('ItemType3', '');
                validation.setFieldValue('UnitofMeasurement', '');
                break;
            case 'SubCategory':
                setSelectedSubCategory(field);
                validation.setFieldValue('SubCategory', field.value);
                break;
            case 'ItemType1':
                setSelectedItemType1(field);
                validation.setFieldValue('ItemType1', field ? field.value : '');
                break;
            case 'ItemType2':
                setSelectedItemType2(field);
                validation.setFieldValue('ItemType2', field ? field.value : '');
                break;
            case 'ItemType3':
                setSelectedItemType3(field);
                validation.setFieldValue('ItemType3', field ? field.value : '');
                break;
            case 'UnitofMeasurement':
                setSelectedItemUnit(field);
                validation.setFieldValue('UnitofMeasurement', field ? field.value : '');
                break;
            default:
                break;
        }
    };

    const selectStyles = (hasError) => ({
        control: (provided, state) => ({
            ...provided,
            borderColor: hasError ? 'red' : provided.borderColor,
            boxShadow: 'none',
            '&:hover': {
                borderColor: hasError ? 'red' : provided['&:hover'].borderColor,
            },
        }),
        outline: "none",
    });

    return (
        <React.Fragment>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <Row>
                    <Col lg={6} className="mb-2">
                        <div className="form-group">
                            <Label htmlFor="Category" className="col-form-label">
                                Item Category<span className="text-danger">*</span>
                            </Label>
                            <Select
                                id="Category"
                                name="Category"
                                options={itemCategories.map((value) => ({
                                    label: value.StringMapName,
                                    value: value.StringMapId,
                                }))}
                                onChange={(selectedOption) => handleSelectChange('Category', selectedOption)}
                                onBlur={validation.handleBlur}
                                value={selectedCategory}
                                placeholder="Select Category"
                                isSearchable={true}
                                styles={selectStyles(validation.touched.Category && validation.errors.Category)}
                                classNamePrefix="react-select"
                            />

                            {validation.touched.Category && validation.errors.Category ? (
                                <FormFeedback type="invalid" className="d-block">{validation.errors.Category}</FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                    <Col lg={6} className="mb-2">
                        <div className="form-group">
                            <Label htmlFor="SubCategory" className="col-form-label">
                                Item Sub Category<span className="text-danger">*</span>
                            </Label>
                            <Select
                                id="SubCategory"
                                name="SubCategory"
                                options={filteredSubCategories.map((value) => ({
                                    label: value.StringMapName,
                                    value: value.StringMapId,
                                }))}
                                onChange={(selectedOption) => handleSelectChange('SubCategory', selectedOption)}
                                onBlur={validation.handleBlur}
                                value={selectedSubCategory}
                                placeholder="Select Sub Category"
                                isSearchable={true}
                                isDisabled={!selectedCategory}
                                styles={selectStyles(validation.touched.SubCategory && validation.errors.SubCategory)}
                                classNamePrefix="react-select"
                            />

                            {validation.touched.SubCategory && validation.errors.SubCategory ? (
                                <FormFeedback type="invalid" className="d-block">{validation.errors.SubCategory}</FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} className="mb-2">
                        <div className="form-group">
                            <Label htmlFor="ItemType1" className="col-form-label">
                                Item Type 1<span className="text-danger">*</span>
                            </Label>
                            <Select
                                id="ItemType1"
                                name="ItemType1"
                                options={filteredItemType1.map((value) => ({
                                    label: value.StringMapName,
                                    value: value.StringMapId,
                                }))}
                                onChange={(selectedOption) => handleSelectChange('ItemType1', selectedOption)}
                                onBlur={validation.handleBlur}
                                value={selectedItemType1}
                                placeholder="Select Item Type 1"
                                isSearchable={true}
                                isDisabled={!selectedCategory}
                                styles={selectStyles(validation.touched.ItemType1 && validation.errors.ItemType1)}
                                classNamePrefix="react-select"
                            />

                            {validation.touched.ItemType1 && validation.errors.ItemType1 ? (
                                <FormFeedback type="invalid" className="d-block">{validation.errors.ItemType1}</FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                    {filteredItemType2.length > 0 && (
                    <Col lg={4} className="mb-2">
                        <div className="form-group">
                            <Label htmlFor="ItemType2" className="col-form-label">
                                Item Type 2<span className="text-danger">*</span>
                            </Label>
                            <Select
                                id="ItemType2"
                                name="ItemType2"
                                options={filteredItemType2.map((value) => ({
                                    label: value.StringMapName,
                                    value: value.StringMapId,
                                }))}
                                onChange={(selectedOption) => handleSelectChange('ItemType2', selectedOption)}
                                onBlur={validation.handleBlur}
                                value={selectedItemType2}
                                placeholder="Select Item Type 2"
                                isSearchable={true}
                                isDisabled={!selectedCategory}
                                styles={selectStyles(validation.touched.ItemType2 && validation.errors.ItemType2)}
                                classNamePrefix="react-select"
                            />

                            {validation.touched.ItemType2 && validation.errors.ItemType2 ? (
                                <FormFeedback type="invalid" className="d-block">{validation.errors.ItemType2}</FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                    )}
                    {filteredItemType3.length > 0 && (
                    <Col lg={4} className="mb-2">
                        <div className="form-group">
                            <Label htmlFor="ItemType3" className="col-form-label">
                                Item Type 3<span className="text-danger">*</span>
                            </Label>
                            <Select
                                id="ItemType3"
                                name="ItemType3"
                                options={filteredItemType3.map((value) => ({
                                    label: value.StringMapName,
                                    value: value.StringMapId,
                                }))}
                                onChange={(selectedOption) => handleSelectChange('ItemType3', selectedOption)}
                                onBlur={validation.handleBlur}
                                value={selectedItemType3}
                                placeholder="Select Item Type 3"
                                isSearchable={true}
                                isDisabled={!selectedCategory}
                                styles={selectStyles(validation.touched.ItemType3 && validation.errors.ItemType3)}
                                classNamePrefix="react-select"
                            />

                            {validation.touched.ItemType3 && validation.errors.ItemType3 ? (
                                <FormFeedback type="invalid" className="d-block">{validation.errors.ItemType3}</FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                    )}
                </Row>
                <Row>
                    <Col lg={12} className="mb-2 mt-2">
                        <div className="form-group">
                            <Label htmlFor="SystemItemName" className="col-form-label">
                                System Item Name: <span className="text-secondary">{validation.values.SystemItemName}</span>
                            </Label>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} className="mb-2">
                        <div className="form-group">
                            <Label htmlFor="ItemName" className="col-form-label">
                                Item Name (Unique)<span className="text-danger">*</span>
                            </Label>
                            <Input
                                id="ItemName"
                                name="ItemName"
                                type="text"
                                className="form-control validate"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.ItemName}
                                invalid={
                                    validation.touched.ItemName && validation.errors.ItemName
                                        ? true
                                        : false
                                } />

                            {validation.touched.ItemName && validation.errors.ItemName ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.ItemName}
                                </FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                    <Col lg={6} className="mb-2">
                        <div className="form-group">
                            <Label htmlFor="ItemCode" className="col-form-label">
                                Item Code (Unique)<span className="text-danger">*</span>
                            </Label>
                            <Input
                                id="ItemCode"
                                name="ItemCode"
                                type="text"
                                className="form-control validate"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.ItemCode}
                                invalid={
                                    validation.touched.ItemCode && validation.errors.ItemCode
                                        ? true
                                        : false
                                } />

                            {validation.touched.ItemCode && validation.errors.ItemCode ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.ItemCode}
                                </FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} className="mb-2">
                        <div className="form-group">
                            <Label htmlFor="Dimentions" className="col-form-label">
                                Dimensions<span className="text-danger">*</span>
                            </Label>
                            <Input
                                id="Dimentions"
                                name="Dimentions"
                                type="text"
                                className="form-control validate"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.Dimentions}
                                invalid={
                                    validation.touched.Dimentions && validation.errors.Dimentions
                                        ? true
                                        : false
                                } />

                            {validation.touched.Dimentions && validation.errors.Dimentions ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.Dimentions}
                                </FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                    <Col lg={6} className="mb-2">
                        <div className="form-group">
                            <Label htmlFor="HSNCode" className="col-form-label">
                                HSN Code
                            </Label>
                            <Input
                                id="HSNCode"
                                name="HSNCode"
                                type="text"
                                className="form-control validate"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.HSNCode}
                                invalid={
                                    validation.touched.HSNCode && validation.errors.HSNCode
                                        ? true
                                        : false
                                } />

                            {validation.touched.HSNCode && validation.errors.HSNCode ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.HSNCode}
                                </FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} className="mb-2">
                        <div className="form-group">
                            <Label htmlFor="Rate" className="col-form-label">
                                Rate<span className="text-danger">*</span>
                            </Label>
                            <Input
                                id="Rate"
                                name="Rate"
                                type="text"
                                className="form-control validate"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.Rate}
                                invalid={
                                    validation.touched.Rate && validation.errors.Rate
                                        ? true
                                        : false
                                } />

                            {validation.touched.Rate && validation.errors.Rate ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.Rate}
                                </FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                    <Col lg={6} className="mb-2">
                        <div className="form-group">
                            <Label htmlFor="UnitofMeasurement" className="col-form-label">
                                Unit of Measurement<span className="text-danger">*</span>
                            </Label>
                            <Select
                                id="UnitofMeasurement"
                                name="UnitofMeasurement"
                                options={filteredUnit.map((value) => ({
                                    label: value.StringMapName,
                                    value: value.StringMapId,
                                }))}
                                onChange={(selectedOption) => handleSelectChange('UnitofMeasurement', selectedOption)}
                                onBlur={validation.handleBlur}
                                value={selectedItemUnit}
                                placeholder="Select Unit of Measurement"
                                isSearchable={true}
                                isDisabled={!selectedCategory}
                                styles={selectStyles(validation.touched.UnitofMeasurement && validation.errors.UnitofMeasurement)}
                                classNamePrefix="react-select"
                            />

                            {validation.touched.UnitofMeasurement && validation.errors.UnitofMeasurement ? (
                                <FormFeedback type="invalid" className="d-block">{validation.errors.UnitofMeasurement}</FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                </Row>

                <Row className="mt-4 justify-content-end">
                    <Col lg={6} className="d-flex justify-content-end mb-3">
                        <button
                            type="button"
                            className="btn btn-danger large-button me-2"
                            onClick={() => {
                                props.setShowItemGetList(true);
                            }}
                        >
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-success large-button">
                            Submit
                        </button>
                    </Col>
                </Row>

            </Form>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        showItemGetList: state.ItemMaster?.showItemGetList,
    };
};

export default connect(mapStateToProps, { setShowItemGetList })(AddItem);

