import React, { useEffect, useMemo } from "react";
import {
  Row, Col, Label, FormFeedback, Form, Input, Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import { AddUpdateItemCategory, GetItemCategoryById } from "../../../store/Master/ItemCategory/actions";
import { useParams, useNavigate } from "react-router-dom";
import { setBreadcrumbItems,setShowItemGetCategoryList } from "../../../store/actions";
import moment from "moment-timezone"

const ViewItemCategory = (props) => {  // Use props instead of destructuring
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Id } = useParams();
  const itemCategoryData = useSelector((state) => state.ItemCategoryMaster?.category?.data) || {};

  // Memoize breadcrumbItems
  const breadcrumbItems = useMemo(() => [
    { title: "Comfort Zone" },
    { title: "Itemcategory" },
    { title: "View Item Category" },
  ], []);

  // Use props.setBreadcrumbItems directly in useEffect
  useEffect(() => {
    props.setBreadcrumbItems('View Item Category', breadcrumbItems);
    if (Id) {
      dispatch(GetItemCategoryById(Id));
    }
  }, [Id, dispatch, breadcrumbItems, props]); // Include props and breadcrumbItems as dependencies

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      ItemCategoryName: itemCategoryData[0]?.ItemCategoryName || "",
      Description: itemCategoryData[0]?.Description || "",
      AddedBy: itemCategoryData[0]?.AddedBy || "",
      CreatedOn: itemCategoryData[0]?.CreatedOn ? moment.tz(itemCategoryData[0]?.CreatedOn, "America/New_York").tz("Asia/Kolkata").format("DD MMMM YYYY, hh:mm A") : "",
      UpdatedByName: itemCategoryData[0]?.UpdatedByName || "",
      UpdatedOn: itemCategoryData[0]?.UpdatedOn ? moment.tz(itemCategoryData[0]?.UpdatedOn, "America/New_York").tz("Asia/Kolkata").format("DD MMMM YYYY, hh:mm A") : "",
    },
    validationSchema: Yup.object({
      ItemCategoryName: Yup.string().required("Please Enter Item Category Name"),
      Description: Yup.string(),
    }),
    onSubmit: async (values) => {
      dispatch(AddUpdateItemCategory(values));
    }
  });

  return (
    <React.Fragment>
      <Row className="justify-content-center">
        <Col lg={8}>
          <Card>
            <CardHeader>
              <h3 className="text-center"> Item Category</h3>
            </CardHeader>
            <CardBody>
              <Form>
                <Row className="justify-content-center">
                  <Col lg={6} className="mb-4">
                    <div className="form-group">
                      <Label htmlFor="ItemCategoryName" className="col-form-label">
                        Item Category Name<span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="ItemCategoryName"
                        name="ItemCategoryName"
                        type="text"
                        className="form-control validate"
                        maxLength={50}
                        placeholder="Enter Item Category Name..."
                        validate={{ required: { value: true } }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.ItemCategoryName}
                        disabled
                        invalid={validation.touched.ItemCategoryName && validation.errors.ItemCategoryName ? true : false}
                      />
                      {validation.touched.ItemCategoryName && validation.errors.ItemCategoryName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.ItemCategoryName}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Col lg={6} className="mb-4">
                    <div className="form-group">
                      <Label htmlFor="Description" className="col-form-label">
                        Description
                      </Label>
                      <Input
                        id="Description"
                        name="Description"
                        type="textarea"
                        className="form-control"
                        placeholder="Enter Description"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.Description}
                        disabled
                      />
                      {validation.touched.Description && validation.errors.Description ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.Description}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-4 justify-content-center">
                  <Col lg={3} className="d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => {
                        props.setShowItemGetCategoryList(true);
                        navigate("/Master/ItemCategory");
                      }}
                    >
                      Cancel
                    </button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
            <Row className="d-flex justify-content-between flex-column flex-lg-row mt-4">
              <Col lg={6} className="mb-4">
                <p className="mb-1 ms-3"><strong>Created By:</strong> {validation.values.AddedBy}</p>
                <p className="mb-1 ms-3"><strong>Created On:</strong> {validation.values.CreatedOn}</p>
              </Col>
              <Col lg={6} className="mb-4">
                {(validation.values.UpdatedByName || validation.values.UpdatedOn) && (
                  <>
                    <p className="mb-1 ms-3"><strong>Last Updated By:</strong> {validation.values.UpdatedByName}</p>
                    <p className="mb-1 ms-3"><strong>Last Updated On:</strong> {validation.values.UpdatedOn}</p>
                  </>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    showItemGetCategoryList: state.ItemCategoryMaster?.showItemGetCategoryList,
  };
};

export default connect(mapStateToProps, {setBreadcrumbItems,setShowItemGetCategoryList })(ViewItemCategory);
