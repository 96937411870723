import React, { useState, useEffect } from "react";
import DataTable from "../../components/Common/CustomDataTable";
import { Button } from "reactstrap";
import { Collapse, Input, Row, Col, UncontrolledTooltip } from "reactstrap";
import 'primereact/resources/themes/mira/theme.css';
import { useNavigate } from "react-router-dom";
import 'primereact/resources/primereact.min.css';
import { connect, useDispatch, useSelector   } from "react-redux";
import { GetCompany, setShowCompanyList } from "store/Company/actions";
import FilterSection from "../../components/Common/FilterSection";

const AllCompanies = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const companies = useSelector((state) => state.CompnayAPI?.companies?.data) || [];
    const [globalSearch, setGlobalSearch] = useState("");
    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const [filters, setFilters] = useState({
        CompanyName: "",
        Status: "",
        CreatedBy: "",
    });

    useEffect(() => {
        dispatch(GetCompany());
    }, [dispatch]);

    useEffect(() => {
        console.log("Companies from Redux:", companies);
        if (!Array.isArray(companies)) {
            console.error("Items is not an array:", companies);
        }
    }, [companies]);

    const actionBodyTemplate = (rowData) => (
        <div className="actions-column">
            <Button color="info" id={`view-${rowData.OrganizationId}`} className="me-2" onClick={() => navigate(`View/${rowData.OrganizationId}`)}>
                <i className="mdi mdi-eye"></i>
            </Button>
            <UncontrolledTooltip target={`view-${rowData.OrganizationId}`} placement="bottom">
                View Item
            </UncontrolledTooltip>

            <Button color="primary" id={`edit-${rowData.OrganizationId}`} onClick={() => navigate(`Edit/${rowData.OrganizationId}`)}>
                <i className="mdi mdi-pencil"></i>
            </Button>
            <UncontrolledTooltip target={`edit-${rowData.OrganizationId}`} placement="bottom">
                Edit Item
            </UncontrolledTooltip>
        </div>
    );

    const companyColumns = [
        { name: "Company Name", field: "OrganizationName", sortable: true, filter: true },
        { name: "Status", field: "Status", sortable: true, filter: true },
        { name: "Created By", field: "CreatedBy", sortable: true, filter: true },
        { name: "Action", field: "", body: (rowData) => actionBodyTemplate(rowData), ignoreRowClick: true, allowOverflow: true, button: true, width: "150px" },
    ];

    const filteredData = companies.filter((data) =>
        Object.keys(filters).every(
            (key) => !filters[key] || (data[key] && data[key].toString().toLowerCase().includes(filters[key].toLowerCase()))
        )
    );

    const csvReport = {
        filename: "ItemCategories_Report.csv",
        headers: companyColumns.map(col => ({ label: col.name, key: col.field })),
        data: filteredData,
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const handleGlobalSearchChange = (e) => {
        setGlobalSearch(e.target.value);
    };

    return (
        <>
            <Row className="mb-3">
                <Col md={12} className="d-flex justify-content-end align-items-center">
                    <div className="input-container me-2">
                        <i className="mdi mdi-magnify search-icon"></i>
                        <Input
                            type="text"
                            value={globalSearch}
                            onChange={handleGlobalSearchChange}
                            className="search-input"
                        />
                    </div>
                    <Button color="primary" id="filter" onClick={() => setIsOpenFilter(!isOpenFilter)} className="me-2">
                        <i className="mdi mdi-filter"></i>
                    </Button>
                    <UncontrolledTooltip target="filter" placement="bottom">
                        Filter
                    </UncontrolledTooltip>
                    <Button color="success" id="add-new" onClick={() => props.setShowCompanyList(false)} className="me-2">
                        <i className="mdi mdi-plus-thick"></i> ADD
                    </Button>
                    <UncontrolledTooltip target="add-new" placement="bottom">
                        Add New Organization
                    </UncontrolledTooltip>
                </Col>
            </Row>
            <Collapse isOpen={isOpenFilter}>
                <FilterSection
                    isOpenFilter={isOpenFilter}
                    setIsOpenFilter={setIsOpenFilter}
                    filters={filters}
                    Columns={companyColumns}
                    handleFilterChange={handleFilterChange}
                />
            </Collapse>

            <DataTable
                filteredData={filteredData}
                Columns={companyColumns}
                csvReport={csvReport}
                List={GetCompany}
                globalSearch={globalSearch}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    setShowCompanyList: state.CompanyMaster?.setShowCompanyList,
});

export default connect(mapStateToProps, { setShowCompanyList })(AllCompanies);
