import React, { useState, useEffect,useRef } from "react";
import { Row, Col, Label, FormFeedback, Form, Input, FormGroup, Button, Table, Card, CardBody, CardHeader } from "reactstrap";
import Select from "react-select";
import Switch from "react-switch";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector,useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { AddUpdateOrganization, UploadFile, ClearFilePath, RemoveUpload,GetCompanyById } from "store/actions";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
const apiUrl = process.env.REACT_APP_URL;
const apiAppUrl = process.env.REACT_APP_API_URL;

const ContactPerson = ({ previous, next, formData, isEditMode, activeTab }) => {
  const organization = useSelector((state) => state.CompnayAPI?.data) || [];
  const titleList = useSelector((state) => state.CommonAPI?.title?.data?.map((org) => ({
    label: org.StringMapName,
    value: org.StringMapId,
  }))) || [];
  const personType = useSelector((state) => state.CommonAPI?.personType?.data?.map((org) => ({
    label: org.StringMapName,
    value: org.StringMapId,
  }))) || [];
  const designationList = useSelector((state) => state.CommonAPI?.designation?.data?.map((org) => ({
    label: org.StringMapName,
    value: org.StringMapId,
  }))) || [];
  const departmentList = useSelector((state) => state.CommonAPI?.department?.data?.map((org) => ({
    label: org.StringMapName,
    value: org.StringMapId,
  }))) || [];
  const addressType = useSelector((state) => state.CommonAPI?.addressType?.data) || [];
  const phoneType = useSelector((state) => state.CommonAPI?.phoneType?.data) || [];
  const emailType = useSelector((state) => state.CommonAPI?.emailType?.data) || [];
  const dispatch = useDispatch();
  const [entries, setEntries] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);

  const [addressEntries, setAddressEntries] = useState([]);
  const [phoneEntries, setPhoneEntries] = useState([]);
  const [emailEntries, setEmailEntries] = useState([]);
  const [editContactIndex, setEditContactIndex] = useState({ address: null, phone: null, email: null });
  const [selectedAddressType, setSelectedAddressType] = useState(null);
  const [selectedPhoneType, setSelectedPhoneType] = useState(null);
  const [selectedEmailType, setSelectedEmailType] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const fileInputRef = useRef(null);
  const location = useLocation();
  const isViewMode = location.pathname.includes("/View"); 

  const { Id } = useParams();
  var company = [];

  useEffect(() => {
    if (activeTab == 4) {
      const payloadTab = {
        "TabName": "ContactPerson",
        "OrganizationId": Id
      }
      axios.post(`${apiAppUrl}/api/master/GetOrgnaizationById`, payloadTab)
        .then(response => {
          if (response.status === 200) {
            console.log('Data Retrieved Successfully:', response.data);
            company = []
            company = response.data.data

            const companyData = response.data.data.map(person => {
              const parsedPerson = JSON.parse(person.OrganizationContactPerson);

              let Email = [];
              let Phone = [];
              let Address = [];

              parsedPerson.ContactPerson.forEach(contact => {
                Email = Email.concat((contact.EmailList || []).map(email => ({
                  Email: email.Email,
                  EmailId: email.EmailId,
                  EmailType: email.EmailType,
                  EntityType: email.EntityType,
                  IsPreferred: email.IsPreferred
                })));

                Phone = Phone.concat((contact.phoneList || []).map(phone => ({
                  PhoneType: phone.PhoneType,
                  CountryCode: phone.CountryCode,
                  PhoneNumber: phone.PhoneNumber,
                  Ext: phone.Ext,
                  PhoneId: phone.phoneId,
                  IsPreferred: phone.IsPreferred,
                })));

                Address = Address.concat((contact.AddressList || []).map(address => ({
                  AddressType: address.AddressType,
                  AddressLine1: address.AddrLine1,
                  AddressLine2: address.AddrLine2,
                  City: address.City,
                  District: address.District,
                  State: address.State,
                  PostalCode: address.PostalCode,
                  IsPreferred: address.IsPreferred
                })));
              });

              return {
                DOB: parsedPerson.DOB,
                Title: parsedPerson.Title,
                Gender: parsedPerson.Gender,
                LastName: parsedPerson.LastName,
                FirstName: parsedPerson.FirstName,
                Department: parsedPerson.Department,
                MiddleName: parsedPerson.MiddleName,
                PersonCode: parsedPerson.PersonCode,
                PersonType: parsedPerson.PersonType,
                Designation: parsedPerson.Designation,
                PicturePath: parsedPerson.PicturePath,
                IsKeyContact: parsedPerson.IsKeyContact,
                IsOkToContact: parsedPerson.IsOkToContact,
                IsAttentionContact: parsedPerson.IsAttentionContact,
                OrganizationId: parsedPerson.OrganizationId,
                OrganizationPersonId: parsedPerson.OrganizationPersonId,
                Email,
                Phone,
                Address
              };
            });

            console.log("Formatted Data:", companyData);

            if (companyData) {
              setEntries(companyData)
            }
          }
        })
        .catch(error => {
          console.error('Error Setting Up Request:', error);
        });
    }
  }, [Id, activeTab])

  const addressValidation = useFormik({
    initialValues: { AddressType: null, AddressLine1: '', AddressLine2: '', City: '', District: '', State: '', PostalCode: '', IsPreferred: false },
    validationSchema: Yup.object({
      AddressType: Yup.string().required("Please select Address Type"),
      AddressLine1: Yup.string().required("Please enter Address Line 1"),
      AddressLine2: Yup.string().required("Please enter Address Line 1"),
      City: Yup.string().required("Please enter City"),
      District: Yup.string().required("Please enter District"),
      State: Yup.string().required("Please enter State"),
      PostalCode: Yup.string().required("Please enter Postal Code"),
    }),
    onSubmit: (values) => {
      if (
        values.IsPreferred &&
        addressEntries.some(
          (entry, idx) => entry.IsPreferred && idx !== editContactIndex.address
        )
      ) {
        toast.error("Only one address can be marked as preferred.");
        return;
      }

      const updatedEntries = editContactIndex.address !== null
        ? addressEntries.map((entry, index) => (index === editContactIndex.address ? values : entry))
        : [...addressEntries, values];
      setAddressEntries(updatedEntries);
      addressValidation.resetForm();
      setEditContactIndex((prev) => ({ ...prev, address: null }));
      setSelectedAddressType(null);
    },
  });

  const phoneValidation = useFormik({
    initialValues: { PhoneType: '', CountryCode: '', PhoneNumber: '', Ext: '', IsPreferred: false },
    validationSchema: Yup.object({
      PhoneType: Yup.string().required("Please select Phone Type"),
      PhoneNumber: Yup.string().required("Please enter Phone Number"),
    }),
    onSubmit: (values) => {
      if (
        values.IsPreferred &&
        phoneEntries.some(
          (entry, idx) => entry.IsPreferred && idx !== editContactIndex.phone
        )
      ) {
        toast.error("Only one phone can be marked as preferred.");
        return;
      }

      const updatedEntries = editContactIndex.phone !== null
        ? phoneEntries.map((entry, index) => (index === editContactIndex.phone ? values : entry))
        : [...phoneEntries, values];
      setPhoneEntries(updatedEntries);
      phoneValidation.resetForm();
      setEditContactIndex((prev) => ({ ...prev, phone: null }));
      setSelectedPhoneType(null);
    },
  });

  const emailValidation = useFormik({
    initialValues: { EmailType: '', Email: '', IsPreferred: false },
    validationSchema: Yup.object({
      EmailType: Yup.string().required("Please select Email Type"),
      Email: Yup.string().email("Invalid email").required("Please enter Email"),
    }),
    onSubmit: (values) => {
      if (
        values.IsPreferred &&
        emailEntries.some(
          (entry, idx) => entry.IsPreferred && idx !== editContactIndex.email
        )
      ) {
        toast.error("Only one phone can be marked as preferred.");
        return;
      }

      const updatedEntries = editContactIndex.email !== null
        ? emailEntries.map((entry, index) => (index === editContactIndex.email ? values : entry))
        : [...emailEntries, values];
      setEmailEntries(updatedEntries);
      emailValidation.resetForm();
      setEditContactIndex((prev) => ({ ...prev, email: null }));
      setSelectedEmailType(null);
    },
  });

  const initialValues = isEditMode && formData ? formData : {
    Title: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    PersonCode: "",
    PersonType: "",
    Gender: "",
    DOB: "",
    PicturePath: null,
    Designation: "",
    Department: "",
    IsKeyContact: 0,
    IsAttentionContact: 0,
    IsOkToContact: 0,
  };

  const validation = useFormik({
    initialValues: {
      ...initialValues,
      Phone: [],
      Email: [],
      Address: [],
    },
    validationSchema: Yup.object({
      Title: Yup.string().required("Please select Title"),
      FirstName: Yup.string().required("Please enter First Name"),
      LastName: Yup.string().required("Please enter Last Name"),
      PersonCode: Yup.string().required("Please enter Person Code").matches(/^\d+$/, "Code must be a number"),
      PersonType: Yup.string().required("Please select Person Type"),
      Gender: Yup.string().required("Please select Gender"),
      DOB: Yup.date().required("Please enter Date of Birth"),
      Designation: Yup.string().required("Please enter Designation"),
      Department: Yup.string().required("Please enter Department"),
      // Phone: Yup.array().min(1, "At least one phone number is required."),
      // Email: Yup.array().min(1, "At least one email is required."),
      // Address: Yup.array().min(1, "At least one address is required."),
    }),

    onSubmit: (values) => {
      console.log("chekkkkkkkkkk")
      debugger
      if (phoneEntries.length === 0) {
        toast.error("At least one phone number is required.");
        return;
      }

      if (emailEntries.length === 0) {
        toast.error("At least one email number is required.");
        return;
      }

      if (addressEntries.length === 0) {
        toast.error("At least one address number is required.");
        return;
      }

      const updatedValues = {
        ...values,
        Phone: phoneEntries,
        Email: emailEntries,
        Address: addressEntries,
      };

      if (isEditing) {
        const updatedEntries = [...entries];
        updatedEntries[editIndex] = updatedValues;
        setEntries(updatedEntries);
        setIsEditing(false);
        setEditIndex(null);
      } else {
        setEntries([...entries, updatedValues]);
      }
      resetFormToPlaceholder();
      setFileName("");
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }

      setPhoneEntries([]);
      setEmailEntries([]);
      setAddressEntries([]);
    },
  });

  const resetFormToPlaceholder = () => {
    setIsEditing(false);
    setEditIndex(null);
    setPhoneEntries([]);
    setEmailEntries([]);
    setAddressEntries([]);
    validation.resetForm({ values: initialValues });
  };

  const getUniqueFileName = (originalName) => {
    const timestamp = Date.now();
    const fileExtension = originalName.split('.').pop();
    const baseName = originalName.replace(/\.[^/.]+$/, "");
    return `orgprofile_${timestamp}.${fileExtension}`;
  };

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const uniqueFile = new File([file], getUniqueFileName(file?.name), { type: file.type });
      setUploadedFile(uniqueFile);
    }
  };

  const handleUpload = () => {
    if (uploadedFile) {
      const filePayload = new FormData();
      filePayload.append("file", uploadedFile);
      dispatch(UploadFile(filePayload))
      setFileName(uploadedFile.name);
      validation.setFieldValue('PicturePath', uploadedFile.name);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } else {
      toast.error("Please select a file to upload");
    }
  };

  const handleEdit = (index) => {
    const entryToEdit = entries[index];
    validation.setValues(entryToEdit);

    setPhoneEntries(entryToEdit.Phone || []);
    setEmailEntries(entryToEdit.Email || []);
    setAddressEntries(entryToEdit.Address || []);

    setIsEditing(true);
    setEditIndex(index);
  };

  const handleFinalSubmit = () => {
    debugger;
    console.log("entries:", entries);
    const OrganizationId = Id ? Id : organization?.data[0]?.OrganizationId;
    const finalArray = entries.map(item => ({
      ...item,
      OrganizationId: OrganizationId
    }));
    const payload = {
      "ContactPerson": finalArray
    };
    const finalPayload = transformContactPersonData(payload);
    function transformContactPersonData(values) {
      const transformedContactPerson = values.ContactPerson.map((item) => {
        return {
          OrganizationId: item.OrganizationId,
          ...item
        };
      });
      const payload = {
        PersonInfo: {
          Person: transformedContactPerson,
        },
      };
      return payload;
    }
    dispatch(AddUpdateOrganization(finalPayload));
    next(formData);
  };

  const selectStyles = (hasError) => ({
    control: (provided) => ({
      ...provided,
      borderColor: hasError ? 'red' : provided.borderColor,
      boxShadow: 'none',
      '&:hover': {
        borderColor: hasError ? 'red' : provided['&:hover'].borderColor,
      },
    }),
  });

  useEffect(() => {
    if (addressValidation.values.AddressType) {
      const initialSelected = addressType.find(
        cat => cat.StringMapId === addressValidation.values.AddressType
      );
      setSelectedAddressType(initialSelected ? { label: initialSelected.StringMapName, value: initialSelected.StringMapId } : null);
    }
  }, [addressType, addressValidation.values.AddressType]);

  const handleAddressTypeChange = (option) => {
    setSelectedAddressType(option);
    addressValidation.setFieldValue('AddressType', option?.value);
  };

  useEffect(() => {
    if (phoneValidation.values.PhoneType) {
      const initialSelected = phoneType.find(
        cat => cat.StringMapId === phoneValidation.values.PhoneType
      );
      setSelectedPhoneType(initialSelected ? { label: initialSelected.StringMapName, value: initialSelected.StringMapId } : null);
    }
  }, [phoneType, phoneValidation.values.PhoneType]);

  const handlePhoneTypeChange = (option) => {
    setSelectedPhoneType(option);
    phoneValidation.setFieldValue('PhoneType', option?.value);
  };

  useEffect(() => {
    if (emailValidation.values.EmailType) {
      const initialSelected = emailType.find(
        cat => cat.StringMapId === emailValidation.values.EmailType
      );
      setSelectedEmailType(initialSelected ? { label: initialSelected.StringMapName, value: initialSelected.StringMapId } : null);
    }
  }, [emailType, emailValidation.values.EmailType]);

  const handleEmailTypeChange = (option) => {
    setSelectedEmailType(option);
    emailValidation.setFieldValue('EmailType', option?.value);
  };

  return (
    <React.Fragment>
      {!isViewMode && (
        <Form onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}>
          <Row>
            <Col lg={4}>
              <FormGroup>
                <Label htmlFor="Title">Title<span className="text-danger">*</span></Label>
                <Select
                  id="Title"
                  name="Title"
                  options={titleList}
                  onChange={(option) => validation.setFieldValue("Title", option ? option.value : "")}
                  onBlur={validation.handleBlur}
                  placeholder="Select Title"
                  value={titleList.find(option => option.value === validation.values.Title) || null}
                  styles={selectStyles(
                    validation.touched.Title && validation.errors.Title
                  )}
                  className={validation.touched.Title && validation.errors.Title ? 'is-invalid' : ''}
                />
                {validation.touched.Title && validation.errors.Title && (
                  <FormFeedback>{validation.errors.Title}</FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col lg={4}>
              <FormGroup>
                <Label htmlFor="PersonCode">Person Code<span className="text-danger">*</span></Label>
                <Input
                  id="PersonCode"
                  name="PersonCode"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.PersonCode}
                  invalid={validation.touched.PersonCode && validation.errors.PersonCode}
                />
                {validation.touched.PersonCode && validation.errors.PersonCode && (
                  <FormFeedback>{validation.errors.PersonCode}</FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col lg={4}>
              <FormGroup>
                <Label htmlFor="PersonType">Person Type<span className="text-danger">*</span></Label>
                <Select
                  id="PersonType"
                  name="PersonType"
                  options={personType}
                  onChange={(option) => validation.setFieldValue("PersonType", option ? option.value : "")}
                  onBlur={validation.handleBlur}
                  placeholder="Select Person Type"
                  value={personType.find(option => option.value === validation.values.PersonType) || null}
                  styles={selectStyles(
                    validation.touched.PersonType && validation.errors.PersonType
                  )}
                  className={validation.touched.PersonType && validation.errors.PersonType ? 'is-invalid' : ''}
                />
                {validation.touched.PersonType && validation.errors.PersonType && (
                  <FormFeedback>{validation.errors.PersonType}</FormFeedback>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <FormGroup>
                <Label htmlFor="FirstName">First Name<span className="text-danger">*</span></Label>
                <Input
                  id="FirstName"
                  name="FirstName"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.FirstName}
                  invalid={validation.touched.FirstName && validation.errors.FirstName}
                />
                {validation.touched.FirstName && validation.errors.FirstName && (
                  <FormFeedback>{validation.errors.FirstName}</FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col lg={4}>
              <FormGroup>
                <Label htmlFor="MiddleName">Middle Name</Label>
                <Input
                  id="MiddleName"
                  name="MiddleName"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.MiddleName}
                />
              </FormGroup>
            </Col>
            <Col lg={4}>
              <FormGroup>
                <Label htmlFor="LastName">Last Name<span className="text-danger">*</span></Label>
                <Input
                  id="LastName"
                  name="LastName"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.LastName}
                  invalid={validation.touched.LastName && validation.errors.LastName}
                />
                {validation.touched.LastName && validation.errors.LastName && (
                  <FormFeedback>{validation.errors.LastName}</FormFeedback>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <FormGroup>
                <Label htmlFor="Gender">Gender<span className="text-danger">*</span></Label>
                <Select
                  id="Gender"
                  name="Gender"
                  options={[
                    { label: "Male", value: "Male" },
                    { label: "Female", value: "Female" },
                    { label: "Other", value: "Other" },
                  ]}
                  onChange={(option) => validation.setFieldValue("Gender", option ? option.value : "")}
                  onBlur={validation.handleBlur}
                  placeholder="Select Gender"
                  value={
                    [{ label: "Male", value: "Male" }, { label: "Female", value: "Female" }, { label: "Other", value: "Other" }]
                      .find(option => option.value === validation.values.Gender) || null
                  }
                  styles={selectStyles(
                    validation.touched.Gender && validation.errors.Gender
                  )}
                  className={validation.touched.Gender && validation.errors.Gender ? 'is-invalid' : ''}
                />
                {validation.touched.Gender && validation.errors.Gender && (
                  <FormFeedback>{validation.errors.Gender}</FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col lg={4}>
              <FormGroup>
                <Label htmlFor="DOB">Date of Birth<span className="text-danger">*</span></Label>
                <Input
                  id="DOB"
                  name="DOB"
                  type="date"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.DOB}
                  invalid={validation.touched.DOB && validation.errors.DOB}
                />
                {validation.touched.DOB && validation.errors.DOB && (
                  <FormFeedback>{validation.errors.DOB}</FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col lg={4}>
              <FormGroup>
                <Label htmlFor="PicturePath">Profile Picture</Label>
                <div className="d-flex align-items-center">
                  <Input
                    id="PicturePath"
                    name="PicturePath"
                    type="file"
                    onChange={handleFileChange}
                  />
                  <Button className="ms-2" color="primary" onClick={handleUpload}>
                    Upload
                  </Button>
                </div>
                {(fileName || editIndex !== null) && (
                  <div className="mt-2">
                    {/* <Label className="text-success">Uploaded File: {fileName ? fileName : validation.values.PicturePath}</Label> */}

                    <Label className="text-success">Uploaded File: <a href={`${apiUrl}/images/${fileName ? fileName : validation.values.PicturePath}`} style={{ color: "green" }} target="_blank" rel="noopener noreferrer">{fileName ? fileName : validation.values.PicturePath}</a></Label>

                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>

            <Col lg={4}>
              <FormGroup>
                <Label htmlFor="Designation">Designation<span className="text-danger">*</span></Label>
                <Select
                  id="Designation"
                  name="Designation"
                  options={designationList}
                  onChange={(option) => validation.setFieldValue("Designation", option ? option.value : "")}
                  onBlur={validation.handleBlur}
                  placeholder="Select Designation"
                  value={designationList.find(option => option.value === validation.values.Designation) || null}
                  styles={selectStyles(
                    validation.touched.Designation && validation.errors.Designation
                  )}
                  className={validation.touched.Designation && validation.errors.Designation ? 'is-invalid' : ''}
                />
                {validation.touched.Designation && validation.errors.Designation && (
                  <FormFeedback>{validation.errors.Designation}</FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col lg={4}>
              <FormGroup>
                <Label htmlFor="Department">Department<span className="text-danger">*</span></Label>
                <Select
                  id="Department"
                  name="Department"
                  options={departmentList}
                  onChange={(option) => validation.setFieldValue("Department", option ? option.value : "")}
                  onBlur={validation.handleBlur}
                  placeholder="Select Department"
                  value={departmentList.find(option => option.value === validation.values.Department) || null}
                  styles={selectStyles(
                    validation.touched.Department && validation.errors.Department
                  )}
                  className={validation.touched.Department && validation.errors.Department ? 'is-invalid' : ''}
                />
                {validation.touched.Department && validation.errors.Department && (
                  <FormFeedback>{validation.errors.Department}</FormFeedback>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row className="mt-2 mb-4">
            <Col className="d-flex align-items-center" lg={4}>
              <Label className="mb-0 me-3" htmlFor="IsKeyContact">Is Key Contact</Label>
              <Switch
                checked={!!validation.values.IsKeyContact}
                onChange={(checked) => validation.setFieldValue('IsKeyContact', checked ? 1 : 0)}
                onColor="#7A6FBE"
                uncheckedIcon={false}
                checkedIcon={false}
              />
            </Col>
            <Col className="d-flex align-items-center" lg={4}>
              <Label className="mb-0 me-3" htmlFor="IsAttentionContact">Is Attention Contact</Label>
              <Switch
                checked={!!validation.values.IsAttentionContact}
                onChange={(checked) => validation.setFieldValue('IsAttentionContact', checked ? 1 : 0)}
                onColor="#7A6FBE"
                uncheckedIcon={false}
                checkedIcon={false}
              />
            </Col>
            <Col className="d-flex align-items-center" lg={4}>
              <Label className="mb-0 me-3" htmlFor="IsOkToContact">Is Ok To Contact</Label>
              <Switch
                checked={!!validation.values.IsOkToContact}
                onChange={(checked) => validation.setFieldValue('IsOkToContact', checked ? 1 : 0)}
                onColor="#7A6FBE"
                uncheckedIcon={false}
                checkedIcon={false}
              />
            </Col>
          </Row>

          {/* Address Section */}
          <Card>
            <CardHeader>Address</CardHeader>
            <CardBody>
              <Row>
                <Col lg={4}>
                  <FormGroup>
                    <Label htmlFor="AddressType">Address Type<span className="text-danger">*</span></Label>
                    <Select
                      id="AddressType"
                      options={addressType.map(cat => ({ label: cat.StringMapName, value: cat.StringMapId }))}
                      onChange={handleAddressTypeChange}
                      onBlur={addressValidation.handleBlur}
                      value={selectedAddressType}
                      placeholder="Select Address Type"
                    />
                    {addressValidation.touched.AddressType && addressValidation.errors.AddressType && (
                      <FormFeedback type="invalid" className="d-block">{addressValidation.errors.AddressType}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col lg={4}>
                  <FormGroup>
                    <Label htmlFor="AddressLine1">Address Line 1<span className="text-danger">*</span></Label>
                    <Input
                      id="AddressLine1"
                      name="AddressLine1"
                      type="text"
                      onChange={addressValidation.handleChange}
                      onBlur={addressValidation.handleBlur}
                      value={addressValidation.values.AddressLine1}
                    />
                    {addressValidation.touched.AddressLine1 && addressValidation.errors.AddressLine1 && (
                      <FormFeedback type="invalid" className="d-block">{addressValidation.errors.AddressLine1}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col lg={4}>

                  <FormGroup>
                    <Label htmlFor="AddressLine2">Address Line 2<span className="text-danger">*</span></Label>
                    <Input
                      id="AddressLine2"
                      name="AddressLine2"
                      type="text"
                      onChange={addressValidation.handleChange}
                      onBlur={addressValidation.handleBlur}
                      value={addressValidation.values.AddressLine2}
                    />
                    {addressValidation.touched.AddressLine2 && addressValidation.errors.AddressLine2 && (
                      <FormFeedback type="invalid" className="d-block">{addressValidation.errors.AddressLine2}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <FormGroup>
                    <Label htmlFor="City">City<span className="text-danger">*</span></Label>
                    <Input
                      id="City"
                      name="City"
                      type="text"
                      onChange={addressValidation.handleChange}
                      onBlur={addressValidation.handleBlur}
                      value={addressValidation.values.City}
                    />
                    {addressValidation.touched.City && addressValidation.errors.City && (
                      <FormFeedback type="invalid" className="d-block">{addressValidation.errors.City}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col lg={4}>
                  <FormGroup>
                    <Label htmlFor="District">District<span className="text-danger">*</span></Label>
                    <Input
                      id="District"
                      name="District"
                      type="text"
                      onChange={addressValidation.handleChange}
                      onBlur={addressValidation.handleBlur}
                      value={addressValidation.values.District}
                    />
                    {addressValidation.touched.District && addressValidation.errors.District && (
                      <FormFeedback type="invalid" className="d-block">{addressValidation.errors.District}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col lg={4}>
                  <FormGroup>
                    <Label htmlFor="State">State<span className="text-danger">*</span></Label>
                    <Input
                      id="State"
                      name="State"
                      type="text"
                      onChange={addressValidation.handleChange}
                      onBlur={addressValidation.handleBlur}
                      value={addressValidation.values.State}
                    />
                    {addressValidation.touched.State && addressValidation.errors.State && (
                      <FormFeedback type="invalid" className="d-block">{addressValidation.errors.State}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>

              </Row>
              <Row className="d-flex justify-content-between">
                <Col lg={4}>
                  <Label htmlFor="PostalCode">Postal Code<span className="text-danger">*</span></Label>
                  <Input
                    id="PostalCode"
                    name="PostalCode"
                    type="text"
                    onChange={addressValidation.handleChange}
                    onBlur={addressValidation.handleBlur}
                    value={addressValidation.values.PostalCode}
                  />
                  {addressValidation.touched.PostalCode && addressValidation.errors.PostalCode && (
                    <FormFeedback type="invalid" className="d-block">{addressValidation.errors.PostalCode}</FormFeedback>
                  )}
                </Col>
                <Col className="d-flex align-items-center" lg={4}>
                  <Label className="mb-0 me-3">Is Preferred</Label>
                  <Switch
                    checked={addressValidation.values.IsPreferred}
                    onChange={(checked) => addressValidation.setFieldValue('IsPreferred', checked ? 1 : 0)}
                    onColor="#7A6FBE"
                    uncheckedIcon={false}
                    checkedIcon={false}
                  />
                </Col>
                <Col className="d-flex justify-content-end align-items-center" lg={4}>
                  <button type="button" onClick={addressValidation.handleSubmit} className="btn btn-primary">
                    {editContactIndex.address !== null ? "Update Address" : "Add Address"}
                  </button>
                </Col>
              </Row>
              {/* Address Table */}
              {addressEntries.length > 0 && (
                <Table bordered className="mt-3">
                  <thead>
                    <tr>
                      <th>Address Type</th>
                      <th>Address Line 1</th>
                      <th>City</th>
                      <th>District</th>
                      <th>State</th>
                      <th>Postal Code</th>
                      <th>Is Preferred</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {addressEntries.map((entry, index) => (
                      <tr key={index}>
                        <td>{addressType.find(cat => cat.StringMapId === entry.AddressType)?.StringMapName}</td>
                        <td>{entry.AddressLine1}</td>
                        <td>{entry.City}</td>
                        <td>{entry.District}</td>
                        <td>{entry.State}</td>
                        <td>{entry.PostalCode}</td>
                        <td>{entry.IsPreferred ? 'Yes' : 'No'}</td>
                        <td className="d-flex justify-content-between">
                          <Button onClick={() => { setEditContactIndex((prev) => ({ ...prev, address: index })); addressValidation.setValues(entry); }}>Edit</Button>
                          <Button color="danger" onClick={() => setAddressEntries(entries => entries.filter((_, i) => i !== index))}>Delete</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </CardBody>
          </Card>

          {/* Phone Section */}
          <Card>
            <CardHeader>Phone</CardHeader>
            <CardBody>
              <Row>
                <Col lg={4}>
                  <FormGroup>
                    <Label htmlFor="PhoneType">Phone Type<span className="text-danger">*</span></Label>
                    <Select
                      id="PhoneType"
                      options={phoneType.map(cat => ({ label: cat.StringMapName, value: cat.StringMapId }))}
                      onChange={handlePhoneTypeChange}
                      onBlur={phoneValidation.handleBlur}
                      value={selectedPhoneType}
                      placeholder="Select Phone Type"
                    />
                    {phoneValidation.touched.PhoneType && phoneValidation.errors.PhoneType && (
                      <FormFeedback type="invalid" className="d-block">{phoneValidation.errors.PhoneType}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col lg={4}>
                  <FormGroup>
                    <Label htmlFor="CountryCode">Country Code<span className="text-danger">*</span></Label>
                    <Input
                      id="CountryCode"
                      name="CountryCode"
                      type="text"
                      onChange={phoneValidation.handleChange}
                      onBlur={phoneValidation.handleBlur}
                      value={phoneValidation.values.CountryCode}
                    />
                    {phoneValidation.touched.CountryCode && phoneValidation.errors.CountryCode && (
                      <FormFeedback type="invalid" className="d-block">{phoneValidation.errors.CountryCode}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col lg={4}>
                  <FormGroup>
                    <Label htmlFor="PhoneNumber">Phone Number<span className="text-danger">*</span></Label>
                    <Input
                      id="PhoneNumber"
                      name="PhoneNumber"
                      type="text"
                      onChange={phoneValidation.handleChange}
                      onBlur={phoneValidation.handleBlur}
                      value={phoneValidation.values.PhoneNumber}
                    />
                    {phoneValidation.touched.PhoneNumber && phoneValidation.errors.PhoneNumber && (
                      <FormFeedback type="invalid" className="d-block">{phoneValidation.errors.PhoneNumber}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <FormGroup>
                    <Label htmlFor="Ext">Extension</Label>
                    <Input
                      id="Ext"
                      name="Ext"
                      type="text"
                      onChange={phoneValidation.handleChange}
                      onBlur={phoneValidation.handleBlur}
                      value={phoneValidation.values.Ext}
                    />
                  </FormGroup>
                </Col>
                <Col className="d-flex align-items-center" lg={4}>
                  <Label className="mb-0 me-3">Is Preferred</Label>
                  <Switch
                    checked={phoneValidation.values.IsPreferred}
                    onChange={(checked) => phoneValidation.setFieldValue('IsPreferred', checked ? 1 : 0)}
                    onColor="#7A6FBE"
                    uncheckedIcon={false}
                    checkedIcon={false}
                  />
                </Col>
                <Col className="d-flex justify-content-end align-items-center" lg={4}>
                  <button type="button" onClick={phoneValidation.handleSubmit} className="btn btn-primary">
                    {editContactIndex.phone !== null ? "Update Phone" : "Add Phone"}
                  </button>
                </Col>
              </Row>
              {/* Phone Table */}
              {phoneEntries.length > 0 && (
                <Table bordered className="mt-3">
                  <thead>
                    <tr>
                      <th>Phone Type</th>
                      <th>Country Code</th>
                      <th>Phone Number</th>
                      <th>Extension</th>
                      <th>Is Preferred</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {phoneEntries.map((entry, index) => (
                      <tr key={index}>
                        <td>{phoneType.find(cat => cat.StringMapId === entry.PhoneType)?.StringMapName}</td>
                        <td>{entry.CountryCode}</td>
                        <td>{entry.PhoneNumber}</td>
                        <td>{entry.Ext}</td>
                        <td>{entry.IsPreferred ? 'Yes' : 'No'}</td>
                        <td className="d-flex justify-content-between">
                          <Button onClick={() => { setEditContactIndex((prev) => ({ ...prev, phone: index })); phoneValidation.setValues(entry); }}>Edit</Button>
                          <Button color="danger" onClick={() => setPhoneEntries(entries => entries.filter((_, i) => i !== index))}>Delete</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </CardBody>
          </Card>

          {/* Email Section */}
          <Card>
            <CardHeader>Email</CardHeader>
            <CardBody>
              <Row>
                <Col lg={4}>
                  <FormGroup>
                    <Label htmlFor="EmailType">Email Type<span className="text-danger">*</span></Label>
                    <Select
                      id="EmailType"
                      options={emailType.map(cat => ({ label: cat.StringMapName, value: cat.StringMapId }))}
                      onChange={handleEmailTypeChange}
                      onBlur={emailValidation.handleBlur}
                      value={selectedEmailType}
                      placeholder="Select Email Type"
                    />
                    {emailValidation.touched.EmailType && emailValidation.errors.EmailType && (
                      <FormFeedback type="invalid" className="d-block">{emailValidation.errors.EmailType}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col lg={4}>
                  <FormGroup>
                    <Label htmlFor="Email">Email<span className="text-danger">*</span></Label>
                    <Input
                      id="Email"
                      name="Email"
                      type="email"
                      onChange={emailValidation.handleChange}
                      onBlur={emailValidation.handleBlur}
                      value={emailValidation.values.Email}
                    />
                    {emailValidation.touched.Email && emailValidation.errors.Email && (
                      <FormFeedback type="invalid" className="d-block">{emailValidation.errors.Email}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col className="d-flex align-items-center" lg={4}>
                  <Label className="mb-0 me-3">Is Preferred</Label>
                  <Switch
                    checked={emailValidation.values.IsPreferred}
                    onChange={(checked) => emailValidation.setFieldValue('IsPreferred', checked ? 1 : 0)}
                    onColor="#7A6FBE"
                    uncheckedIcon={false}
                    checkedIcon={false}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-end align-items-center" lg={12}>
                  <button type="button" onClick={emailValidation.handleSubmit} className="btn btn-primary">
                    {editContactIndex.email !== null ? "Update Email" : "Add Email"}
                  </button>
                </Col>
              </Row>
              {/* Email Table */}
              {emailEntries.length > 0 && (
                <Table bordered className="mt-3">
                  <thead>
                    <tr>
                      <th>Email Type</th>
                      <th>Email</th>
                      <th>Is Preferred</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {emailEntries.map((entry, index) => (
                      <tr key={index}>
                        <td>{emailType.find(cat => cat.StringMapId === entry.EmailType)?.StringMapName}</td>
                        <td>{entry.Email}</td>
                        <td>{entry.IsPreferred ? 'Yes' : 'No'}</td>
                        <td className="d-flex justify-content-between">
                          <Button onClick={() => { setEditContactIndex((prev) => ({ ...prev, email: index })); emailValidation.setValues(entry); }}>Edit</Button>
                          <Button color="danger" onClick={() => setEmailEntries(entries => entries.filter((_, i) => i !== index))}>Delete</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </CardBody>
          </Card>

          <Row className="justify-content-end mt-4">
            <Col lg={12} className="d-flex justify-content-end">
              <Button type="button" className="btn btn-danger me-4" onClick={resetFormToPlaceholder}>
                Reset
              </Button>
              <Button type="submit" className="btn btn-primary">
                {isEditing ? "Update Contact Person" : "Add Contact Person"}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
      {entries.length > 0 && (
        <Row className="mt-4">
          <Col>
            <Label className="col-form-label">Contact Persons</Label>
            <Table bordered>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Person Code</th>
                  {!isViewMode && (
                    <th>Actions</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {entries.map((entry, index) => (
                  <tr key={index}>
                    <td>{titleList.find((item) => item.value === entry.Title)?.label}</td>
                    <td>{entry.FirstName}</td>
                    <td>{entry.LastName}</td>
                    <td>{entry.PersonCode}</td>
                    {!isViewMode && (
                      <td className="d-flex justify-content-between">
                        <Button color="primary" onClick={() => handleEdit(index)} className="me-2">
                          Edit
                        </Button>
                        <Button color="danger" onClick={() => {
                          if (index === editIndex) {
                            setIsEditing(false);
                            setEditIndex(null);
                            resetFormToPlaceholder();
                          }
                          setEntries(entries.filter((_, i) => i !== index))
                        }}>
                          Remove
                        </Button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
      {!isViewMode && (
        <Row className="mt-4 justify-content-between">
          <Col className="d-flex justify-content-start">
            <button type="button" onClick={previous} className="btn btn-secondary large-button">
              Previous
            </button>
          </Col>
          <Col className="d-flex justify-content-end">
            <button type="button" onClick={handleFinalSubmit} className="btn btn-primary large-button">
              Save & Next
            </button>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default ContactPerson;
