import React, { useEffect, useState, useMemo } from "react";
import { Row, Col, Label, FormFeedback, Form, Input } from "reactstrap";
import Select from 'react-select';
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import { AddUpdateItem, GetItemById, GetItemCategoryDropdown, GetExistingFields } from "../../store/actions";
import { useParams, useNavigate } from "react-router-dom";
import { setBreadcrumbItems } from "../../store/actions"
import moment from "moment"

const EditQuotation = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Id } = useParams();
    const itemData = useSelector((state) => state.ItemMaster?.data) || {};
    const itemCategories = useSelector((state) => state.CommonAPI?.itemCategories?.data) || [];
    const itemSubCategories = useSelector((state) => state.CommonAPI?.itemSubCategories?.data) || [];
    const itemTypeList1 = useSelector((state) => state.CommonAPI?.itemType1?.data) || [];
    const itemTypeList2 = useSelector((state) => state.CommonAPI?.itemType2?.data) || [];
    const itemTypeList3 = useSelector((state) => state.CommonAPI?.itemType3?.data) || [];
    const unitofMesurementList = useSelector((state) => state.CommonAPI?.unitList?.data) || [];
    const existingFields = useSelector((state) => state.CommonAPI?.itemExistingFields?.data) || [];
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [selectedItemType1, setSelectedItemType1] = useState(null);
    const [selectedItemType2, setSelectedItemType2] = useState(null);
    const [selectedItemType3, setSelectedItemType3] = useState(null);
    const [selectedItemUnit, setSelectedItemUnit] = useState(null);
    const [filteredSubCategories, setFilteredSubCategories] = useState([]);
    const [filteredItemType1, setFilteredItemType1] = useState([]);
    const [filteredItemType2, setFilteredItemType2] = useState([]);
    const [filteredItemType3, setFilteredItemType3] = useState([]);
    const [filteredUnit, setFilteredUnit] = useState([]);

    const breadcrumbItems = useMemo(() => [
        { title: "Comfort" },
        { title: "Quotation" },
        { title: "Edit Quotation" },
    ], []);

    const [isFormDirty, setIsFormDirty] = useState(false);

    useEffect(() => {
        props.setBreadcrumbItems('Edit Quotation', breadcrumbItems);
        if (Id) {
            dispatch(GetItemById(Id));
        }
        dispatch(GetItemCategoryDropdown({ "StringMapType": "ItemCategory", "OrderBy": "StringMapName" }));
        dispatch(GetItemCategoryDropdown({ "StringMapType": "ItemSubCategory", "OrderBy": "StringMapName" }));
        dispatch(GetItemCategoryDropdown({ "StringMapType": "ItemType1", "OrderBy": "StringMapName" }));
        dispatch(GetItemCategoryDropdown({ "StringMapType": "ItemType2", "OrderBy": "StringMapName" }));
        dispatch(GetItemCategoryDropdown({ "StringMapType": "ItemType3", "OrderBy": "StringMapName" }));
        dispatch(GetItemCategoryDropdown({ "StringMapType": "UnitofMesurement", "OrderBy": "StringMapName" }));
        dispatch(GetExistingFields({ "pColumns": "ItemName,ItemCode,ItemId", "pTableName": "Item" }));
    }, [Id, dispatch, breadcrumbItems, props]);

    useEffect(() => {
        console.log("item dropdown 1", itemCategories);
        console.log("item dropdown 2", itemSubCategories);
        console.log("item dropdown 3", itemTypeList1);
        console.log("item dropdown 4", itemTypeList2);
        console.log("item dropdown 5", itemTypeList3);
        console.log("item dropdown 6", unitofMesurementList);
        console.log("item fields", existingFields);
    }, [itemCategories, itemSubCategories, itemTypeList1, itemTypeList2, itemTypeList3, unitofMesurementList, existingFields]);

    useEffect(() => {
        if (selectedCategory) {
            const filteredSub = itemSubCategories.filter(
                (sub) => sub.CategoryId === selectedCategory.value
            );
            setFilteredSubCategories(filteredSub);

            const filteredType1 = itemTypeList1.filter(
                (type) => type.CategoryId === selectedCategory.value
            );
            setFilteredItemType1(filteredType1);

            const filteredType2 = itemTypeList2.filter(
                (type) => type.CategoryId === selectedCategory.value
            );
            setFilteredItemType2(filteredType2);

            const filteredType3 = itemTypeList3.filter(
                (type) => type.CategoryId === selectedCategory.value
            );
            setFilteredItemType3(filteredType3);

            const filteredUnit = unitofMesurementList.filter(
                (type) => type.CategoryId === selectedCategory.value
            );
            setFilteredUnit(filteredUnit);
        } else {
            setFilteredSubCategories([]);
            setFilteredItemType1([]);
            setFilteredItemType2([]);
            setFilteredItemType3([]);
            setFilteredUnit([]);
        }
    }, [selectedCategory, itemSubCategories, itemTypeList1, itemTypeList2, itemTypeList3]);


    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            Category: itemData?.data?.[0].Category || "",
            SubCategory: itemData?.data?.[0].SubCategory || "",
            ItemType1: itemData?.data?.[0].ItemType1 || "",
            ItemType2: itemData?.data?.[0].ItemType2 || "",
            ItemType3: itemData?.data?.[0].ItemType3 || "",
            SystemItemName: itemData?.data?.[0].SystemItemName || "",
            ItemName: itemData?.data?.[0].ItemName || "",
            ItemCode: itemData?.data?.[0].ItemCode || "",
            Dimentions: itemData?.data?.[0].Dimentions || "",
            HSNCode: itemData?.data?.[0].HSNCode || "",
            Rate: itemData?.data?.[0].Rate || "",
            UnitofMeasurement: itemData?.data?.[0].UnitofMeasurement || "",
            CreatedByName: itemData?.data?.[0].CreatedByName || "",
            CreatedOn: itemData?.data?.[0].CreatedOn ? moment(itemData?.data?.[0].CreatedOn).format("DD MMMM YYYY, hh:mm A") : "",
            UpdatedByName: itemData?.data?.[0].UpdatedByName || "",
            UpdatedOn: itemData?.data?.[0].UpdatedOn ? moment(itemData?.data?.[0].UpdatedOn).format("DD MMMM YYYY, hh:mm A") : "",
        },

        validationSchema: Yup.object({
            Category: Yup.string().required("Please Select Item Category"),
            SubCategory: Yup.string().required("Please Select Item Sub Category"),
            ItemType1: Yup.string().required("Please Select Item Type 1"),
            ItemType2: filteredItemType2.length > 0 ? Yup.string().required("Please Select Item Type 2") : Yup.string(),
            ItemType3: filteredItemType3.length > 0 ? Yup.string().required("Please Select Item Type 3") : Yup.string(),
            SystemItemName: Yup.string().required("Please Enter System Item Name"),
            ItemName: Yup.string()
                .required("Please Enter Item Name")
                .test(
                    "unique-ItemName",
                    "This Item Name already taken",
                    function (value) {
                        const currentId = Id;
                        const existingItemNames = existingFields
                            .filter(item => item.ItemId !== currentId)
                            .map(item => item.ItemName);
                        return !existingItemNames.includes(value);
                    }
                ),
            ItemCode: Yup.string()
                .required("Please Enter Item Code")
                .test(
                    "unique-ItemCode",
                    "This Item Code already taken",
                    function (value) {
                        const currentId = Id;
                        const existingItemCodes = existingFields
                            .filter(item => item.ItemId !== currentId)
                            .map(item => item.ItemCode);
                        return !existingItemCodes.includes(value);
                    }
                ),
            Dimentions: Yup.string().required("Please Enter Dimensions"),
            HSNCode: Yup.string(),
            Rate: Yup.string().required("Please Enter Rate"),
            UnitofMeasurement: Yup.string().required("Please Enter Unit of Measurement"),
        }),
        onSubmit: async (values) => {
            const { CreatedByName, CreatedOn, UpdatedByName, UpdatedOn, ...payload } = values;
            
            payload.ItemId = Id;

            dispatch(AddUpdateItem(payload));
            setTimeout(() => {
                navigate("/Master/Item");
            }, 500);
        }
    });

    useEffect(() => {
        const formDirty = JSON.stringify(validation.initialValues) !== JSON.stringify(validation.values);
        setIsFormDirty(formDirty);
    }, [validation.values, validation.initialValues]);

    useEffect(() => {
        if (itemData.data?.length > 0 && itemCategories.length > 0) {
            const selectedCategoryObj = itemCategories.find(category => category.StringMapId === itemData.data[0].Category);
            if (selectedCategoryObj) {
                setSelectedCategory({ value: selectedCategoryObj.StringMapId, label: selectedCategoryObj.StringMapName });
                validation.setFieldValue('Category', selectedCategoryObj.StringMapId);
            }
        }
    }, [itemCategories]);

    useEffect(() => {
        if (itemData.data?.length > 0 && itemSubCategories.length > 0) {
            const selectedSubCategoryObj = itemSubCategories.find(sub => sub.StringMapId === itemData.data[0].SubCategory);
            if (selectedSubCategoryObj) {
                setSelectedSubCategory({ value: selectedSubCategoryObj.StringMapId, label: selectedSubCategoryObj.StringMapName });
                validation.setFieldValue('SubCategory', selectedSubCategoryObj.StringMapId);
            }
        }
    }, [itemSubCategories]);

    useEffect(() => {
        if (itemData.data?.length > 0 && itemTypeList1.length > 0) {
            const selectedItemType1Obj = itemTypeList1.find(type => type.StringMapId === itemData.data[0].ItemType1);
            if (selectedItemType1Obj) {
                setSelectedItemType1({ value: selectedItemType1Obj.StringMapId, label: selectedItemType1Obj.StringMapName });
                validation.setFieldValue('ItemType1', selectedItemType1Obj.StringMapId);
            }
        }
    }, [itemTypeList1]);

    useEffect(() => {
        if (itemData.data?.length > 0 && itemTypeList2.length > 0) {
            const selectedItemType2Obj = itemTypeList2.find(type => type.StringMapId === itemData.data[0].ItemType2);
            if (selectedItemType2Obj) {
                setSelectedItemType2({ value: selectedItemType2Obj.StringMapId, label: selectedItemType2Obj.StringMapName });
                validation.setFieldValue('ItemType2', selectedItemType2Obj.StringMapId);
            }
        }
    }, [itemTypeList2]);

    useEffect(() => {
        if (itemData.data?.length > 0 && itemTypeList3.length > 0) {
            const selectedItemType3Obj = itemTypeList3.find(type => type.StringMapId === itemData.data[0].ItemType3);
            if (selectedItemType3Obj) {
                setSelectedItemType3({ value: selectedItemType3Obj.StringMapId, label: selectedItemType3Obj.StringMapName });
                validation.setFieldValue('ItemType3', selectedItemType3Obj.StringMapId);
            }
        }
    }, [itemTypeList3]);

    useEffect(() => {
        if (itemData.data?.length > 0 && itemTypeList3.length > 0) {
            const selectedItemType3Obj = itemTypeList3.find(type => type.StringMapId === itemData.data[0].ItemType3);
            if (selectedItemType3Obj) {
                setSelectedItemType3({ value: selectedItemType3Obj.StringMapId, label: selectedItemType3Obj.StringMapName });
                validation.setFieldValue('ItemType3', selectedItemType3Obj.StringMapId);
            }
        }
    }, [itemTypeList3]);

    useEffect(() => {
        if (itemData.data?.length > 0 && unitofMesurementList.length > 0) {
            const selectedUnitObj = unitofMesurementList.find(unit => unit.StringMapId === itemData.data[0].UnitofMeasurement);
            if (selectedUnitObj) {
                setSelectedItemUnit({ value: selectedUnitObj.StringMapId, label: selectedUnitObj.StringMapName });
                validation.setFieldValue('UnitofMeasurement', selectedUnitObj.StringMapId);
            }
        }
    }, [itemData, unitofMesurementList]);

    const handleCategoryChange = (selectedOption) => {
        setSelectedCategory(selectedOption);
        setSelectedSubCategory();
        setSelectedItemType1();
        setSelectedItemType2();
        setSelectedItemType3();
        setSelectedItemUnit();
        validation.setFieldValue('Category', selectedOption ? selectedOption.value : '');
    };

    const handleSubCategoryChange = (selectedOption) => {
        setSelectedSubCategory(selectedOption);
        validation.setFieldValue('SubCategory', selectedOption ? selectedOption.value : '');
    }

    const handleItemType1Change = (selectedOption) => {
        setSelectedItemType1(selectedOption);
        validation.setFieldValue('ItemType1', selectedOption ? selectedOption.value : '');
    };

    const handleItemType2Change = (selectedOption) => {
        setSelectedItemType2(selectedOption);
        validation.setFieldValue('ItemType2', selectedOption ? selectedOption.value : '');
    };

    const handleItemType3Change = (selectedOption) => {
        setSelectedItemType3(selectedOption);
        validation.setFieldValue('ItemType3', selectedOption ? selectedOption.value : '');
    };

    const handleUnitChange = (selectedOption) => {
        setSelectedItemUnit(selectedOption);
        validation.setFieldValue('UnitofMeasurement', selectedOption ? selectedOption.value : '');
    };

    return (
        <React.Fragment>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                {itemData?.data?.length > 0 ? (
                    <>
                        <Row>
                            <Col lg={6} className="mb-2">
                                <div className="form-group">
                                    <Label htmlFor="Category" className="col-form-label">
                                        Item Category<span className="text-danger">*</span>
                                    </Label>
                                    <Select
                                        id="Category"
                                        name="Category"
                                        options={itemCategories.map((value) => ({
                                            label: value.StringMapName,
                                            value: value.StringMapId,
                                        }))}
                                        onChange={handleCategoryChange}
                                        onBlur={validation.handleBlur}
                                        value={selectedCategory}
                                        placeholder="Select Category"
                                        isSearchable={true}
                                    />

                                    {validation.touched.Category && validation.errors.Category ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.Category}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6} className="mb-2">
                                <div className="form-group">
                                    <Label htmlFor="SubCategory" className="col-form-label">
                                        Item Sub Category<span className="text-danger">*</span>
                                    </Label>
                                    <Select
                                        id="SubCategory"
                                        name="SubCategory"
                                        options={filteredSubCategories.map((value) => ({
                                            label: value.StringMapName,
                                            value: value.StringMapId,
                                        }))}
                                        onChange={handleSubCategoryChange}
                                        onBlur={validation.handleBlur}
                                        value={selectedSubCategory}
                                        placeholder="Select Sub Category"
                                        isSearchable={true}
                                    />

                                    {validation.touched.SubCategory && validation.errors.SubCategory ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.SubCategory}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4} className="mb-2">
                                <div className="form-group">
                                    <Label htmlFor="ItemType1" className="col-form-label">
                                        Item Type 1<span className="text-danger">*</span>
                                    </Label>
                                    <Select
                                        id="ItemType1"
                                        name="ItemType1"
                                        options={filteredItemType1.map((value) => ({
                                            label: value.StringMapName,
                                            value: value.StringMapId,
                                        }))}
                                        onChange={handleItemType1Change}
                                        onBlur={validation.handleBlur}
                                        value={selectedItemType1}
                                        placeholder="Select Item Type 1"
                                        isSearchable={true}
                                    />

                                    {validation.touched.ItemType1 && validation.errors.ItemType1 ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.ItemType1}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            {filteredItemType2.length > 0 && (
                            <Col lg={4} className="mb-2">
                                <div className="form-group">
                                    <Label htmlFor="ItemType2" className="col-form-label">
                                        Item Type 2<span className="text-danger">*</span>
                                    </Label>
                                    <Select
                                        id="ItemType2"
                                        name="ItemType2"
                                        options={filteredItemType2.map((value) => ({
                                            label: value.StringMapName,
                                            value: value.StringMapId,
                                        }))}
                                        onChange={handleItemType2Change}
                                        onBlur={validation.handleBlur}
                                        value={selectedItemType2}
                                        placeholder="Select Item Type 2"
                                        isSearchable={true}
                                    />

                                    {validation.touched.ItemType2 && validation.errors.ItemType2 ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.ItemType2}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            )}
                            {filteredItemType3.length > 0 && (
                            <Col lg={4} className="mb-2">
                                <div className="form-group">
                                    <Label htmlFor="ItemType3" className="col-form-label">
                                        Item Type 3<span className="text-danger">*</span>
                                    </Label>
                                    <Select
                                        id="ItemType3"
                                        name="ItemType3"
                                        options={filteredItemType3.map((value) => ({
                                            label: value.StringMapName,
                                            value: value.StringMapId,
                                        }))}
                                        onChange={handleItemType3Change}
                                        onBlur={validation.handleBlur}
                                        value={selectedItemType3}
                                        placeholder="Select Item Type 3"
                                        isSearchable={true}
                                    />

                                    {validation.touched.ItemType3 && validation.errors.ItemType3 ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.ItemType3}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            )}
                        </Row>
                        <Row>
                            <Col lg={6} className="mb-2">
                                <div className="form-group">
                                    <Label htmlFor="SystemItemName" className="col-form-label">
                                        System Item Name<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        id="SystemItemName"
                                        name="SystemItemName"
                                        type="text"
                                        className="form-control validate"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.SystemItemName}
                                        invalid={
                                            validation.touched.SystemItemName && validation.errors.SystemItemName
                                                ? true
                                                : false
                                        } />

                                    {validation.touched.SystemItemName && validation.errors.SystemItemName ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.SystemItemName}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} className="mb-2">
                                <div className="form-group">
                                    <Label htmlFor="ItemName" className="col-form-label">
                                        Item Name (Unique)<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        id="ItemName"
                                        name="ItemName"
                                        type="text"
                                        className="form-control validate"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.ItemName}
                                        invalid={
                                            validation.touched.ItemName && validation.errors.ItemName
                                                ? true
                                                : false
                                        } />

                                    {validation.touched.ItemName && validation.errors.ItemName ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.ItemName}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6} className="mb-2">
                                <div className="form-group">
                                    <Label htmlFor="ItemCode" className="col-form-label">
                                        Item Code (Unique)<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        id="ItemCode"
                                        name="ItemCode"
                                        type="text"
                                        className="form-control validate"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.ItemCode}
                                        invalid={
                                            validation.touched.ItemCode && validation.errors.ItemCode
                                                ? true
                                                : false
                                        } />

                                    {validation.touched.ItemCode && validation.errors.ItemCode ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.ItemCode}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} className="mb-2">
                                <div className="form-group">
                                    <Label htmlFor="Dimentions" className="col-form-label">
                                        Dimensions<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        id="Dimentions"
                                        name="Dimentions"
                                        type="text"
                                        className="form-control validate"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.Dimentions}
                                        invalid={
                                            validation.touched.Dimentions && validation.errors.Dimentions
                                                ? true
                                                : false
                                        } />

                                    {validation.touched.Dimentions && validation.errors.Dimentions ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.Dimentions}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6} className="mb-2">
                                <div className="form-group">
                                    <Label htmlFor="HSNCode" className="col-form-label">
                                        HSN Code
                                    </Label>
                                    <Input
                                        id="HSNCode"
                                        name="HSNCode"
                                        type="text"
                                        className="form-control validate"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.HSNCode}
                                        invalid={
                                            validation.touched.HSNCode && validation.errors.HSNCode
                                                ? true
                                                : false
                                        } />

                                    {validation.touched.HSNCode && validation.errors.HSNCode ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.HSNCode}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} className="mb-2">
                                <div className="form-group">
                                    <Label htmlFor="Rate" className="col-form-label">
                                        Rate<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        id="Rate"
                                        name="Rate"
                                        type="text"
                                        className="form-control validate"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.Rate}
                                        invalid={
                                            validation.touched.Rate && validation.errors.Rate
                                                ? true
                                                : false
                                        } />

                                    {validation.touched.Rate && validation.errors.Rate ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.Rate}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6} className="mb-2">
                                <div className="form-group">
                                    <Label htmlFor="UnitofMeasurement" className="col-form-label">
                                        Unit of Measurement<span className="text-danger">*</span>
                                    </Label>
                                    <Select
                                        id="UnitofMeasurement"
                                        name="UnitofMeasurement"
                                        options={filteredUnit.map((value) => ({
                                            label: value.StringMapName,
                                            value: value.StringMapId,
                                        }))}
                                        onChange={handleUnitChange}
                                        onBlur={validation.handleBlur}
                                        value={selectedItemUnit}
                                        placeholder="Select Unit of Measurement"
                                        isSearchable={true}
                                        isDisabled={!selectedCategory}
                                    />

                                    {validation.touched.UnitofMeasurement && validation.errors.UnitofMeasurement ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.UnitofMeasurement}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <Row>
                        <Col>
                            <p>No data was found.</p>
                        </Col>
                    </Row>
                )}
                <Row className="mt-4">
                    <Col lg={6} className="d-flex justify-content-start">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            id="Back"
                            onClick={() => navigate("/Master/Item")}
                        >
                            Back to List
                        </button>
                    </Col>
                    {itemData?.data?.length > 0 ? (
                        <>
                            <Col lg={6} className="d-flex justify-content-end">
                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    id="updatetaskdetail"
                                    disabled={!isFormDirty}
                                >
                                    Update Item
                                </button>
                            </Col>
                        </>
                    ) : (
                        null
                    )}
                </Row>
            </Form>
            <Row className="d-flex justify-content-between flex-column flex-lg-row mt-4">
                <Col lg={6} className="mt-4">
                    <p className="mb-1"><strong>Created By:</strong> {validation.values.CreatedByName}</p>
                    <p className="mb-1"><strong>Created On:</strong> {validation.values.CreatedOn}</p>
                </Col>
                <Col lg={6} className="">
                    {(validation.values.UpdatedByName || validation.values.UpdatedOn) && (
                        <>
                            <p className="mb-1"><strong>Last Updated By:</strong> {validation.values.UpdatedByName}</p>
                            <p className="mb-1"><strong>Last Updated On:</strong> {validation.values.UpdatedOn}</p>
                        </>
                    )}
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default connect(null, { setBreadcrumbItems })(EditQuotation);
