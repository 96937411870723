const initialState = {
  error: "",
  loading: false,
  companies: [], 
  company:[],
  companyDetails:[],
  companyContact :[],
  companyIdentifier:[],
  companyContactPerson:[],
  companyDocuments:[],
  companyNotes:[],
  data: null,
  showCompanyList: true,
};

const AddUpdateOrganization = (state = initialState, action) => {
  
  switch (action.type) {
    case "ADD_UPDATE_COMPANY":
   //` case "GET_ALL_COMPANY":
      return {
        ...state,
        loading: true,
      };

    case "ADD_UPDATE_COMPANY_SUCCESS":
     
      return {
        ...state,
        loading: false,
        data: action.payload, // Store the add/update category response
        error: "",
      };

    case "GET_ALL_COMPANY_SUCCESS":
      return {
        ...state,
        loading: false,
        companies: action.payload, // Store the fetched companies
        error: "",
      };
    case "GET_COMPANY":
       
      return {
        ...state,
        loading: false,
        company: action.payload, // Store the fetched companies
        error: "",
      };

    case "GET_COMPANY_SUCCESS": 
    debugger
     if(action.payload.type === "BasicInfo"){
    return {
      ...state,
      loading: false,
      companyDetails: action?.payload.data[0], // Store the fetched category
      error: "",
    };
  }else if(action.payload.type === "Contacts"){
    return {
      ...state,
      loading: false,
      companyContact: action?.payload.data, // Store the fetched category
      error: "",
    };
  }else if(action.payload.type === "Identifier"){
    debugger
    return {
      ...state,
      loading: false,
      companyIdentifier: action?.payload.data, // Store the fetched category
      error: "",
    };
  }else if(action.payload.type === "ContactPerson"){
    return {
      ...state,
      loading: false,
      companyContactPerson: action?.payload.data, // Store the fetched category
      error: "",
    };
  }else if(action.payload.type === "Documents"){
    return {
      ...state,
      loading: false,
      companyDocuments: action?.payload.data, // Store the fetched category
      error: "",
    };
  }else if(action.payload.type === "Notes"){
    return {
      ...state,
      loading: false,
      companyNotes: action?.payload.data, // Store the fetched category
      error: "",
    };
  }
    case "CLEAR_COMPANY" :
      debugger
      if(action.tab === 'BasicInfo'){
      return {
        ...state,
        loading: false,
       
        companyContact:[],
      
        error: "",
      };
    }else if(action.tab === 'Contacts'){
      return {
        ...state,
        loading: false,
       
        // companyIdentifier:[],
        
        error: "",
      };
    }else if(action.tab === 'Identifier'){
      return {
        ...state,
        loading: false,
        // // companyDetails: [],
        // // companyContact:[],
        // companyIdentifier:[],
        companyContactPerson:[],
        // companyDocuments:[],
        // companyNotes:[],
        error: "",
      };
    }
    case "COMPANY_API_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "SET_SHOW_COMPANY_LIST":
      return {
        ...state,
        showCompanyList: action.payload,
      };
    default:
      return state;
  }
};

export default AddUpdateOrganization;
