import React, { useEffect, useMemo } from "react";
import { Row, Col, Button, CardBody, Card } from "reactstrap";
import { connect } from "react-redux";
import { setBreadcrumbItems, setShowItemGetList } from "../../../store/actions";
import AddItem from "../ItemMaster/AddItem";
import ItemList from "../ItemMaster/AllItem";

const ItemMaster = (props) => {
    document.title = "Item | Cognisun - Responsive Bootstrap 5 Admin Dashboard";

    const breadcrumbItems = useMemo(() => [
        { title: "Comfort Zone" },
        { title: "Item" },
        { title: props.showItemGetList ? "List" : "Add" },
    ], [props.showItemGetList]);

    useEffect(() => {
        props.setBreadcrumbItems("Items", breadcrumbItems);
    }, [props, breadcrumbItems]);

    return (
        <React.Fragment>
            <Row>
                <Col xl={12}>
                    {props.showItemGetList ? <ItemList /> : <AddItem />}
                </Col>
            </Row>

        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    console.log('Redux State:', state);
    return {
        showItemGetList: state.ItemMaster?.showItemGetList,
    };
};

export default connect(mapStateToProps, { setBreadcrumbItems, setShowItemGetList })(ItemMaster);
