import React, { useEffect, useMemo } from "react";
import { Row, Col, Button, CardBody, Card } from "reactstrap";
import { connect } from "react-redux";
import { setBreadcrumbItems, setShowItemGetList } from "../../store/actions";
import AddQuotation from "./AddQuotation";
import QuotationList from "./AllQuotation";

const QuotationMaster = (props) => {
    document.title = "Quotation | Cognisun - Responsive Bootstrap 5 Admin Dashboard";

    const breadcrumbItems = useMemo(() => [
        { title: "Comfort" },
        { title: "Quotation" },
        { title: props.showItemGetList ? "List" : "Add" },
    ], [props.showItemGetList]);

    useEffect(() => {
        props.setBreadcrumbItems("Quotation", breadcrumbItems);
    }, [props, breadcrumbItems]);

    return (
        <React.Fragment>
            <Row>
                <Col xl={12}>
                    {props.showItemGetList ? <QuotationList /> : <AddQuotation />}
                </Col>
            </Row>

        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    console.log('Redux State:', state);
    return {
        showItemGetList: state.ItemMaster?.showItemGetList,
    };
};

export default connect(mapStateToProps, { setBreadcrumbItems, setShowItemGetList })(QuotationMaster);
