export const AddUpdateOrganization = (companyPayload) => {
  return {
    type: "ADD_UPDATE_COMPANY",
    payload: { companyPayload },
  };
};


// Add/Update Item Category Action
export const AddUpdateCompany = (companyPayload) => {
  return {
    type: "ADD_UPDATE_COMPANY",
    payload: { companyPayload },
  };
};

// Success for Add/Update Item Category
export const AddUpdateCompanySuccess = (companyPayload) => {
  return {
    type: "ADD_UPDATE_COMPANY_SUCCESS",
    payload: companyPayload,
  };
};

export const setShowCompanyList = (showCompanyList) => ({
  type: "SET_SHOW_COMPANY_LIST",
  payload: showCompanyList,
});


// Get Item Categories Action
export const GetCompany = () => {
  return {
    type: "GET_ALL_COMPANY",
  };
};

// Success for Get Item Categories
export const GetCompaniesSuccess = (companyPayload) => {
  return {
    type: "GET_ALL_COMPANY_SUCCESS",
    payload: companyPayload,
  };
};


export const GetCompanySuccess = (companyPayload) => {
  debugger
  return {
    type: "GET_COMPANY_SUCCESS",
    payload: companyPayload,
  };
};

// Action for API error
export const CompanyAPIError = (error) => {
  return {
    type: "COMPANY_API_ERROR",
    payload: error,
  };
};

export const GetCompanyById = (id ) => {
  debugger
  return {
    type: "GET_COMPANY",
    payload:id,
  };
};
export const ClearCompany = (input) => {
  debugger
  return {
    type: "CLEAR_COMPANY",
    tab:input
  };
};
export const GetOrganizationById = (id ) => {
   ;
  return {
    type: "GET_ORGANIZATION",
    payload: { id },
  };
};