import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Label, Table, FormFeedback, Form, Input, FormGroup, Button } from "reactstrap";
import Select from 'react-select';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { GetItemCategoryDropdown, AddUpdateOrganization, UploadFile,ClearFilePath,GetCompanyById, RemoveUpload } from "store/actions";
import { toast } from "react-toastify";
import { useParams, useNavigate,useLocation } from "react-router-dom";
import axios from 'axios';
const apiUrl = process.env.REACT_APP_URL;
const apiAppUrl = process.env.REACT_APP_API_URL;

const Identifier = ({ previous, next, formData, isEditMode, activeTab }) => {
  const initialValues = isEditMode && formData ? formData : { IdentiferType: '' };
  const dispatch = useDispatch();
  const identifierType = useSelector((state) => state.CommonAPI?.identifierType?.data) || [];
  const fileData = useSelector((state) => state.CommonAPI?.fileData) || "";
  const organization = useSelector((state) => state.CompnayAPI?.data) || [];
  const [selectedIdentiferType, setSelectedIdentiferType] = useState(null);

  const [entries, setEntries] = useState([]);
  const [editIndex, setEditIndex] = useState(null);

  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const location = useLocation();
  const isViewMode = location.pathname.includes("/View"); 

  const fileInputRef = useRef(null);

  const { Id } = useParams();
  var company = [];

  useEffect(() => {
    if (activeTab == 3) {
      const payloadTab = {
        "TabName": "Identifier",
        "OrganizationId": Id
      }

      axios.post(`${apiAppUrl}/api/master/GetOrgnaizationById`, payloadTab)
        .then(response => {
          if (response.status === 200) {
            console.log('Data Retrieved Successfully:', response.data);
            company = []
            company = response.data.data
            if (company) {
              console.log("comppppIdentifier", company)
              setEntries(company)
            }
          }
        })
        .catch(error => {
          console.error('Error Setting Up Request:', error);
        });
    }
  }, [Id, activeTab])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      IdentiferType: '',
      IdentifierNumber: '',
      UploadedDocument: null,
      OrganizationIdentifierId: ''
    },
    validationSchema: Yup.object({
      IdentiferType: Yup.string().required("Please enter Identifier Type"),
      IdentifierNumber: Yup.string().required("Please enter Identifier Number"),
      UploadedDocument: Yup.mixed().required("Please upload document"),
    }),
    onSubmit: (values) => {
      if (!uploadedFile) {
        toast.error("Please upload a file before adding an Identifier");
        return;
      }

      if (editIndex !== null) {
        const updatedEntries = [...entries];
        updatedEntries[editIndex] = values;
        setEntries(updatedEntries);
        setEditIndex(null);
      } else {
        setEntries([...entries, values]);
      }

      validation.resetForm();
      setSelectedIdentiferType(null);
      setFileName("");
    },
  });

  const handleIdentifierTypeChange = (selectedOption, name) => {
    setSelectedIdentiferType(selectedOption);
    validation.setFieldValue(name, selectedOption ? selectedOption.value : "");
  };

  const selectStyles = (hasError) => ({
    control: (provided) => ({
      ...provided,
      borderColor: hasError ? 'red' : provided.borderColor,
      boxShadow: 'none',
      '&:hover': {
        borderColor: hasError ? 'red' : provided['&:hover'].borderColor,
      },
    }),
  });

  const handleEdit = (index) => {
    const entryToEdit = entries[index];
    setEditIndex(index);
    validation.setValues(entryToEdit);
    const selectedOption = identifierType.find(cat => cat.StringMapId === entryToEdit.IdentiferType);
    setSelectedIdentiferType(selectedOption ? { label: selectedOption.StringMapName, value: selectedOption.StringMapId } : null);
  };

  const handleDelete = (index) => {
    const entryToDelete = entries[index];

    if (entryToDelete?.UploadedDocument?.name) {
      try {
        dispatch(RemoveUpload(entryToDelete.UploadedDocument.name));
        setEntries(entries.filter((_, i) => i !== index));
        toast.success("Entry and file deleted successfully!");
      } catch (error) {
        toast.error("Failed to delete the file. Please try again.");
      }
    } else {
      toast.success("Entry deleted successfully!");
      setEntries(entries.filter((_, i) => i !== index));
    };
  }

  const handleFinalSubmit = () => {
    debugger;
    const OrganizationId = Id ? Id : organization?.data[0]?.OrganizationId
    const finalArray = entries.map(item => ({
      ...item,
      OrganizationId: OrganizationId
    }));
    const payload = {
      "Identifier": finalArray
    };
    const finalPayload = transformIdentifierData(payload);
    function transformIdentifierData(values) {
      const transformedIdentifier = values.Identifier.map((item) => {
        return {
          OrganizationId: item.OrganizationId,
          IdentifierType: item.IdentiferType,
          IdentifierValue: item.IdentifierNumber,
          URL: item.UploadedDocument?.name ? item.UploadedDocument?.name : item.UploadedDocument || '',
          OrganizationIdentifierId: item.OrganizationIdentifierId || ''
        };
      });
      const payload = {
        IdentifierInfo: {
          Identifier: transformedIdentifier,
        },
      };

      return payload;
    }
    dispatch(AddUpdateOrganization(finalPayload));
    // entries.forEach(entry => {
    //   dispatch(AddUpdateItemCategory(entry));
    // });
    next(formData);
    // dispatch(ClearCompany('Identifier'));
  //   const payloadTab ={
  //     "TabName":"ContactPerson",
  //     "OrganizationId":Id
  // }
  // dispatch(GetCompanyById(payloadTab));
  };

  const getUniqueFileName = (originalName) => {
    const timestamp = Date.now();
    const fileExtension = originalName.split('.').pop();
    const baseName = originalName.replace(/\.[^/.]+$/, "");
    return `identifier_${timestamp}.${fileExtension}`;
  };

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const uniqueFile = new File([file], getUniqueFileName(file.name), { type: file.type });
      setUploadedFile(uniqueFile);
    }
  };

  const handleUpload = () => {
    if (uploadedFile) {
      const filePayload = new FormData();
      filePayload.append("file", uploadedFile);
      dispatch(UploadFile(filePayload))

      fileInputRef.current.value = "";
      validation.setFieldValue("UploadedDocument", uploadedFile);
    } else {
      toast.error("Please select a file to upload");
    }
  };

  useEffect(() => {
    if (fileData.filename) {
      setFileName(fileData.filename);
      toast.success(`File Path URL: ${fileData.filename}`);
      dispatch(ClearFilePath());
    }
  }, [fileData.filename, dispatch]);

  return (
    <React.Fragment>
         {!isViewMode && (  
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <Row className="justify-content-center">
          <Col lg={6} className="mb-3">
            <FormGroup>
              <Label htmlFor="IdentiferType" className="col-form-label">
                Identifier Type<span className="text-danger">*</span>
              </Label>
              <Select
                id="IdentiferType"
                name="IdentiferType"
                options={identifierType.map((value) => ({
                  label: value.StringMapName,
                  value: value.StringMapId,
                }))}
                onChange={(option) => handleIdentifierTypeChange(option, "IdentiferType")}
                onBlur={validation.handleBlur}
                value={selectedIdentiferType}
                placeholder="Select Identifier Type"
                isSearchable={true}
                styles={selectStyles(
                  validation.touched.IdentiferType && validation.errors.IdentiferType
                )}
                classNamePrefix="react-select"
              />
              {validation.touched.IdentiferType && validation.errors.IdentiferType ? (
                <FormFeedback type="invalid" className="d-block">
                  {validation.errors.IdentiferType}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </Col>

          <Col lg={6} className="mb-3">
            <div className="form-group">
              <Label htmlFor="IdentifierNumber" className="col-form-label">
                Identifier Number<span className="text-danger">*</span>
              </Label>
              <Input
                id="IdentifierNumber"
                name="IdentifierNumber"
                type="text"
                className="form-control"
                placeholder="Enter Identifier Number"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.IdentifierNumber}
                invalid={validation.touched.IdentifierNumber && validation.errors.IdentifierNumber}
              />
              {validation.touched.IdentifierNumber && validation.errors.IdentifierNumber ? (
                <FormFeedback type="invalid" className="d-block">
                  {validation.errors.IdentifierNumber}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col lg={6} className="mb-4">
            <FormGroup>
              <Label htmlFor="UploadedDocument">
                Upload Document<span className="text-danger">*</span>
              </Label>
              <div className="d-flex align-items-center">
                <Input
                  id="UploadedDocument"
                  name="UploadedDocument"
                  type="file"
                  innerRef={fileInputRef}
                  onChange={handleFileChange}
                  onBlur={validation.handleBlur}
                  invalid={validation.touched.UploadedDocument && !!validation.errors.UploadedDocument}
                />
                <Button className="ms-2" color="primary" onClick={handleUpload}>
                  Upload
                </Button>
              </div>
              {(fileName|| editIndex !== null) && (
                <div className="mt-2">
                  <Label className="text-success">Uploaded File: <a style={{ color: "green" }} href={`${apiUrl}/images/${fileName}`} target="_blank" rel="noopener noreferrer">{fileName}</a></Label>
                </div>
              )}
              <FormFeedback type="invalid" className="d-block">{validation.errors.UploadedDocument}</FormFeedback>
            </FormGroup>
          </Col>

          <Col lg={6} className="mb-4">
            <Row className="mt-4 justify-content-end">
              <Col lg={6} className="d-flex justify-content-end">
                <button type="submit" className="btn btn-primary">
                  {editIndex !== null ? "Update Itentifier" : "Add Itentifier"}
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
         )}
      {entries.length > 0 && (
        <Row className="justify-content-center">
          <Col>
            <Label htmlFor="IdentifierTable" className="col-form-label">
              Identifier Details
            </Label>
            <Table bordered className="mt-3">
              <thead>
                <tr>
                  <th style={{ width: '20%' }}>Identifier Type</th>
                  <th style={{ width: '20%' }}>Identifier Number</th>
                  <th style={{ width: '20%' }}>Uploaded Document</th>
                  {!isViewMode && (
                    <th style={{ width: '20%' }}>Actions</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {entries.map((entry, index) => (
                  <tr key={index}>
                    <td>{identifierType.find(cat => cat.StringMapId === entry.IdentiferType)?.StringMapName}</td>
                    <td>{entry.IdentifierNumber}</td>
                    <td>{entry.UploadedDocument?.name ?entry.UploadedDocument?.name : entry.UploadedDocument }</td>
                    {!isViewMode && (
                      <td className="d-flex justify-content-between">
                        <Button className="mr-2" onClick={() => handleEdit(index)}>
                          Edit
                        </Button>
                        <Button color="danger" onClick={() => handleDelete(index)}>
                          Delete
                        </Button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>

      )}
      {/* {entries.length > 0 && (
        <Row className="mt-4 justify-content-center">
          <Col lg={3} className="d-flex justify-content-center">
            <Button color="primary" onClick={handleFinalSubmit}>
              Submit All
            </Button>
          </Col>
        </Row>
      )} */}
         {!isViewMode && (  
      <Row className="justify-content-between">
        <Col className="d-flex justify-content-start">
          <button type="button" className="btn btn-secondary large-button" onClick={previous}>
            Previous
          </button>
        </Col>
        <Col className="d-flex justify-content-end">
          <button type="button" className="btn btn-primary large-button" disabled={false} onClick={handleFinalSubmit}>
            Save & Next
          </button>
        </Col>
      </Row>
         )}
    </React.Fragment>
  );
};

export default Identifier;
