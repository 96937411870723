import React, { useEffect, useMemo } from "react";
import { Row, Col, Label, FormFeedback, Form, Input } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import { GetCompanyById } from "../../store/Company/actions";
import { useParams, useNavigate } from "react-router-dom";
import { setBreadcrumbItems } from "../../store/actions";


const ViewCompany = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Id } = useParams();
  const companyData = useSelector((state) => state.CompnayAPI?.companyDetail) || {};

  // Memoize breadcrumbItems
  const breadcrumbItems = useMemo(() => [
    { title: "Comfort Zone" },
    { title: "Company" },
    { title: "View Company" },
  ], []);

  useEffect(() => {
    props.setBreadcrumbItems('View Company', breadcrumbItems);
    if (Id) {
      dispatch(GetCompanyById(Id)); // Fetch company data by ID
    }
  }, [Id, dispatch, breadcrumbItems, props]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      CompanyName: companyData.CompanyName || "",
      LandlinePhone: companyData.LandlinePhone || "",
      MobilePhone: companyData.PhoneNumber || "",
      Email: companyData.Email || "",
      Website: companyData.Website || "",
      PAN: companyData.PAN || "",
      GST: companyData.GST || "",
      TAN: companyData.TAN || "",
      AddrLine1: companyData.AddrLine1 || "",
      AddrLine2: companyData.AddrLine2 || "",
      City: companyData.City || "",
      State: companyData.State || "",
      PostalCode: companyData.PostalCode || "",
    },
    validationSchema: Yup.object({
      CompanyName: Yup.string().required("Please Enter Company Name"),
      // Other validation rules can be added here as needed
    }),
    onSubmit: async (values) => {
      // Handle form submission (if needed)
    },
  });

  return (
    <React.Fragment>
      <Form>
        <Row>
          <Col lg={6} className="mb-6">
            <div className="form-group">
              <Label htmlFor="CompanyName" className="col-form-label">
                Company Name<span className="text-danger">*</span>
              </Label>
              <Input
                id="CompanyName"
                name="CompanyName"
                type="text"
                className="form-control validate"
                placeholder="Enter Company Name..."
                value={validation.values.CompanyName}
                disabled
              />
              {validation.touched.CompanyName && validation.errors.CompanyName ? (
                <FormFeedback type="invalid">
                  {validation.errors.CompanyName}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6} className="mb-6">
            <div className="form-group">
              <Label htmlFor="LandlinePhone" className="col-form-label">
                Landline Phone
              </Label>
              <Input
                id="LandlinePhone"
                name="LandlinePhone"
                type="text"
                className="form-control"
                placeholder="Enter Landline Phone"
                value={validation.values.LandlinePhone}
                disabled
              />
              {validation.touched.LandlinePhone && validation.errors.LandlinePhone ? (
                <FormFeedback type="invalid">
                  {validation.errors.LandlinePhone}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} className="mb-6">
            <div className="form-group">
              <Label htmlFor="MobilePhone" className="col-form-label">
                Mobile Phone<span className="text-danger">*</span>
              </Label>
              <Input
                id="MobilePhone"
                name="MobilePhone"
                type="text"
                className="form-control"
                placeholder="Enter Mobile Phone..."
                value={validation.values.MobilePhone}
                disabled
              />
              {validation.touched.MobilePhone && validation.errors.MobilePhone ? (
                <FormFeedback type="invalid">
                  {validation.errors.MobilePhone}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6} className="mb-6">
            <div className="form-group">
              <Label htmlFor="Email" className="col-form-label">
                Email<span className="text-danger">*</span>
              </Label>
              <Input
                id="Email"
                name="Email"
                type="email"
                className="form-control"
                placeholder="Enter Email"
                value={validation.values.Email}
                disabled
              />
              {validation.touched.Email && validation.errors.Email ? (
                <FormFeedback type="invalid">
                  {validation.errors.Email}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} className="mb-6">
            <div className="form-group">
              <Label htmlFor="PAN" className="col-form-label">
                PAN<span className="text-danger">*</span>
              </Label>
              <Input
                id="PAN"
                name="PAN"
                type="text"
                className="form-control"
                placeholder="Enter PAN"
                value={validation.values.PAN}
                disabled
              />
              {validation.touched.PAN && validation.errors.PAN ? (
                <FormFeedback type="invalid">
                  {validation.errors.PAN}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6} className="mb-6">
            <div className="form-group">
              <Label htmlFor="GST" className="col-form-label">
                GST
              </Label>
              <Input
                id="GST"
                name="GST"
                type="text"
                className="form-control"
                placeholder="Enter GST"
                value={validation.values.GST}
                disabled
              />
              {validation.touched.GST && validation.errors.GST ? (
                <FormFeedback type="invalid">
                  {validation.errors.GST}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} className="mb-6">
            <div className="form-group">
              <Label htmlFor="TAN" className="col-form-label">
                TAN
              </Label>
              <Input
                id="TAN"
                name="TAN"
                type="text"
                className="form-control"
                placeholder="Enter TAN"
                value={validation.values.TAN}
                disabled
              />
              {validation.touched.TAN && validation.errors.TAN ? (
                <FormFeedback type="invalid">
                  {validation.errors.TAN}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6} className="mb-6">
            <div className="form-group">
              <Label htmlFor="AddrLine1" className="col-form-label">
                Address Line 1<span className="text-danger">*</span>
              </Label>
              <Input
                id="AddrLine1"
                name="AddrLine1"
                type="text"
                className="form-control"
                placeholder="Enter Address Line 1"
                value={validation.values.AddrLine1}
                disabled
              />
              {validation.touched.AddrLine1 && validation.errors.AddrLine1 ? (
                <FormFeedback type="invalid">
                  {validation.errors.AddrLine1}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} className="mb-6">
            <div className="form-group">
              <Label htmlFor="AddrLine2" className="col-form-label">
                Address Line 2
              </Label>
              <Input
                id="AddrLine2"
                name="AddrLine2"
                type="text"
                className="form-control"
                placeholder="Enter Address Line 2"
                value={validation.values.AddrLine2}
                disabled
              />
            </div>
          </Col>
          <Col lg={6} className="mb-6">
            <div className="form-group">
              <Label htmlFor="State" className="col-form-label">
                State<span className="text-danger">*</span>
              </Label>
              <Input
                id="State"
                name="State"
                type="text"
                className="form-control"
                placeholder="Enter State"
                value={validation.values.State}
                disabled
              />
              {validation.touched.State && validation.errors.State ? (
                <FormFeedback type="invalid">
                  {validation.errors.State}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} className="mb-6">
            <div className="form-group">
              <Label htmlFor="City" className="col-form-label">
                City<span className="text-danger">*</span>
              </Label>
              <Input
                id="City"
                name="City"
                type="text"
                className="form-control"
                placeholder="Enter City"
                value={validation.values.City}
                disabled
              />
              {validation.touched.City && validation.errors.City ? (
                <FormFeedback type="invalid">
                  {validation.errors.City}
                </FormFeedback>
              ) : null}
            </div>
          </Col>

          <Col lg={6} className="mb-6">
            <div className="form-group">
              <Label htmlFor="PostalCode" className="col-form-label">
                PostalCode<span className="text-danger">*</span>
              </Label>
              <Input
                id="PostalCode"
                name="PostalCode"
                type="text"
                className="form-control"
                placeholder="Enter PostalCode"
                value={validation.values.PostalCode}
                disabled
              />
              {validation.touched.PostalCode && validation.errors.PostalCode ? (
                <FormFeedback type="invalid">
                  {validation.errors.PostalCode}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="text-left mt-4">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => navigate("/Company")} // Adjust navigation path accordingly
            >
              Back to List
            </button>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default connect(null, { setBreadcrumbItems })(ViewCompany);
